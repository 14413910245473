import * as XLSX from 'xlsx';
export const config={
    endPoint:'https://backend-siscaja-baum.onrender.com/',    
    // endPoint:'http://192.168.10.15:8080/',     
    
    ApiKeyAmazon:'',
    storageName:'siscaja-webtoken',
    storageDateName:'siscaja-datetime-program',
    storageFilterTrabajo:'siscaja-filter-work',
    MessageErrorServer:'Error en el Servidor,ERROR_CONNECTION ',
    aplicationVersion:'1.0 B'
}
export var ValidarUsuario = () => {
    let usuarioLocalstorage = ObtenerUsuario()
    //console.log(usuarioLocalstorage);
    if (usuarioLocalstorage != null) {
        usuarioLocalstorage = JSON.parse(usuarioLocalstorage);
        let ahora = Date.now() / 1000;
        // console.log(usuarioLocalstorage);
        if (usuarioLocalstorage.token !== null) {
            let userDetails = ObtenerDetalleToken(usuarioLocalstorage.token);
            //console.log(JSON.parse(userDetails));
            if (userDetails) {
                if (JSON.parse(userDetails).exp > ahora) {
                    return true;
                } else {
                    localStorage.removeItem(config.storageName);
                }
            }
        } else {
            if (usuarioLocalstorage.exp > ahora) {
                return true;
            } else {
                localStorage.removeItem(config.storageName);
            }
        }
    }
    return false;
}
export var ObtenerUsuario = () => {
    let usuarioLocalstorage = localStorage.getItem(config.storageName)
    if (usuarioLocalstorage) {
        return usuarioLocalstorage
    } else {
        return null
    }
}
export var ObtenerDetalleToken = (token) => {
    if (token) {
        let centro = token.split(".")[1];
        return window.atob(centro);
    }
    return null;
}
export var GuardarEnStorage = (storageName, data) => {
    localStorage.setItem(storageName, JSON.stringify(data));
}
export var BorrarStorage = (storageName) => {
    // console.log('nombreStorage '+storageName);
    localStorage.removeItem(storageName)
    return storageName;
}
export var CreateJsonToExcel = (data, nombreArchivo, nombrehoja) => {    
    var ws = XLSX.utils.json_to_sheet(data);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, nombrehoja);
    XLSX.writeFile(wb, `${nombreArchivo}.xls`);
}
export var listarPermisos = () => {
    let usuarioLocalstorage = localStorage.getItem(config.storageName)
    usuarioLocalstorage = JSON.parse(usuarioLocalstorage);
    
    return new Promise((resolve, reject) => {  
        try {
            let url = `${config.endPoint}api/listarpermiso/${usuarioLocalstorage.id}`;
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };
            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then((data) => {                
                if (data.content) {                    
                    resolve(data.content);
                }
                else {
                    resolve(null);
                }
            }).catch(() => {
                reject(null);
            });        
            
        } catch (error) {
            reject(null);
        }

        // }{
        //     console.log("False");
        //     resolve(null);
        // }
    });
}
export var ValidarPermisos = (VISTA, permisos) => {
    let _permisoEncontrado=permisos.find(x=>x.codpantalla===VISTA);
    if(_permisoEncontrado){        
        return true;
    }else{        
        return false;
    }    
}

export const LIST_SCREENS = {
    ACCESS_SCREEN_HOME: 'DASHBOARD',
    ACCESS_SCREEN_CAJACHICA: 'CAJA_CHICA',
    ACCESS_SCREEN_BANCOS: 'BANCOS',    
    ACCESS_SCREEN_CONFIGURACION: 'CONFIGURACION',
    ACCESS_SCREEN_CONFIG_PROYECTOS: 'CONFIGURACION-PROYECTOS',
    ACCESS_SCREEN_CONFIG_PROVEEDORES: 'CONFIGURACION-PROVEEDORES',
    ACCESS_SCREEN_CONFIG_DESCRIPCIONGASTOS: 'CONFIGURACION-DESCRIPCIONGASTOS',
    ACCESS_SCREEN_CONFIG_AREAS: 'CONFIGURACION-AREAS',
    ACCESS_SCREEN_CONFIG_CENTROCOSTO: 'CONFIGURACION-CENTROCOSTO',    
    ACCESS_SCREEN_CONFIG_CODIGOS_GASTO: 'CONFIGURACION-CODIGOS',            
    ACCESS_SCREEN_PERMISOS: 'PERMISOS',    
    ACCESS_SCREEN_PERMISOS_TRABAJADOR: 'PERMISOS-TRABAJADOR',
    ACCESS_SCREEN_PERMISOS_VISTAS: 'PERMISOS-VISTAS',    
}