import React from 'react'
import { Backdrop,CircularProgress } from '@mui/material';
export default function PaginaCarga(props) {
    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: '1000' }}
            open={props.loadcarga}
        >
            <CircularProgress color="inherit" />
        </Backdrop>

    );
}