import React, { Fragment, useEffect, useRef, useState } from 'react';
import Header from './../dashboard/header'

// Importaciones Bootstrap
import { Container, Row, Col, Form, Modal, Stack } from 'react-bootstrap';

// Importaciones MaterialUI
import { Table, TableContainer, TableHead, TableCell, TableBody, TableRow, TablePagination, IconButton, Button, Snackbar, Alert, AlertTitle, Switch } from '@mui/material';


import StackMU from '@mui/material/Stack'
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { ValidarUsuario, ObtenerUsuario, config,listarPermisos,ValidarPermisos,LIST_SCREENS } from '../utils/utils';
import PaginaCarga from '../utils/cargapagina'

import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PersonIcon from '@mui/icons-material/Person';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import GradingIcon from '@mui/icons-material/Grading';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import SyncLockIcon from '@mui/icons-material/SyncLock';


export default function Trabajador() {

    const [mostrarmodalagregar, setmostrarmodalagregar] = useState(false);
    const [mostarmodalusuario, setmostarmodalusuario] = useState(false);
    const [mostrarmodalpermisos, setmostrarmodalpermisos] = useState(false);

    const navigate = useNavigate();

    //Estados
    const [mostrarModalDetalla, setmostrarModalDetalla] = useState(false);


    const [abrirToast, setabrirToast] = useState(false);
    const [titulotoast, settitulotoast] = useState("");
    const [mensajetoast, setmensajetoast] = useState("");
    const [tipotoast, settipotoast] = useState("error");

    const [listatrabajadores, setlistatrabajadores] = useState([]);
    const [listaareasactivas, setlistaareasactivas] = useState([]);
    const [listapsntallasactivas, setlistapsntallasactivas] = useState([]);
    const [listapermisostrabajador, setlistapermisostrabajador] = useState([]);
    const [accionactual, setaccionactual] = useState("");
    const [controlesreadonly, setcontrolesreadonly] = useState(true);
    const [controlesusuarioreadonly, setcontrolesusuarioreadonly] = useState(true);
    const [mostrarcontra, setmostrarcontra] = useState(false);
    const [trabajadorActual, settrabajadorActual] = useState({});
    const [habilitarBotonmodificar, sethabilitarBotonmodificar] = useState(true);
    const [habilitarBotonguardar, sethabilitarBotonguardar] = useState(true);

    const [load, setload] = useState(false);
    const [loadcarga, setloadcarga] = useState(true);

    const [cantidadElementos, setcantidadElementos] = useState(0);
    const [filasporpagina, setfilasporpagina] = useState(10);
    const [pagina, setpagina] = useState(1);

    //Referencias controles
    const nombrestrabajadorRef = useRef();
    const areatrabajadorRef = useRef();
    const estadotrabajadorRef = useRef();
    const filtrotrabajadorRef = useRef();
    //Referencias Modal Usuario
    const usuariotrabajadorRef = useRef();
    const contratrabajadorRef = useRef();
    //Referencias Modal Permisos
    const nombretrabajadorpermisoRef = useRef();
    const usuariopermisoRef = useRef();
    const permisopantallaRef = useRef();

    //Evento Inicio
    useEffect(() => {
        ValidarAccesos();
    }, []);
    const ValidarAccesos = () => {
        if (ValidarUsuario()) {
            listarPermisos().then((permisos) => {                
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_PERMISOS_TRABAJADOR, permisos)) {                                        
                    BuscarListastrabajadores(filasporpagina, pagina);     
                } else {                    
                    settitulotoast("Alerta");
                    settipotoast("warning");
                    setmensajetoast("No tiene permisos para acceder a este Recurso.");
                    setabrirToast(true);
                    setTimeout(() => {
                        navigate('/');
                    }, 1000);
                }
            });
        } else {
            navigate('/login');
        }
    }
    //Procedimientos
    const BuscarListastrabajadores = (filas, paginaactual) => {
        if (ValidarUsuario()) {
            let url = "";
            if (filtrotrabajadorRef.current.value.trim() === "") {
                url = `${config.endPoint}api/trabajador/listar/${filas}/${paginaactual}`;
            }
            else {
                url = `${config.endPoint}api/trabajador/listar/${filas}/${paginaactual}/${filtrotrabajadorRef.current.value}`;
            }
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data) {
                    setlistatrabajadores(data.content);
                    setcantidadElementos(data.cantidad)
                    setload(true);
                    setloadcarga(false);
                }
            }).catch((error) => {

                settitulotoast("Alerta");
                settipotoast("warning");
                setmensajetoast(config.MessageErrorServer);
                setabrirToast(true);
            });
        } else {
            navigate("/login");
        }
    }
    const BuscarListaAreasActivas = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/area/listaractivo`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        setlistaareasactivas(data.content);
                        resolve(true);
                    }
                    else {
                        setlistaareasactivas([]);
                        settitulotoast("Alerta");
                        settipotoast("warning");
                        setmensajetoast(data.message);
                        setabrirToast(true);
                        resolve(false);
                    }
                }).catch(() => {
                    setlistaareasactivas([]);
                    settitulotoast("Error");
                    settipotoast("error");
                    setmensajetoast(config.MessageErrorServer);
                    setabrirToast(true);
                    resolve(false);
                });
            }
            else {
                navigate("/login")
                reject(true)
            }
        });
    }
    const BuscarListaVistas = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/vista/listaractivos`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {

                    if (data.content) {
                        setlistapsntallasactivas(data.content);
                        resolve(true);
                    }
                    else {
                        setlistapsntallasactivas([]);
                        settitulotoast("Alerta");
                        settipotoast("warning");
                        setmensajetoast(data.message);
                        setabrirToast(true);
                        resolve(false);
                    }
                }).catch(() => {
                    setlistapsntallasactivas([]);
                    settitulotoast("Error");
                    settipotoast("error");
                    setmensajetoast(config.MessageErrorServer);
                    setabrirToast(true);
                    resolve(false);
                });
            }
            else {
                navigate("/login")
                reject(true)
            }
        });
    }
    const BuscarListaPermisos = (registro) => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/permiso/${registro.id}`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                                        if (data.content) {
                        setlistapermisostrabajador(data.content);
                        resolve(true);
                    }
                    else {
                        setlistapermisostrabajador([]);
                        settitulotoast("Alerta");
                        settipotoast("warning");
                        setmensajetoast(data.message);
                        setabrirToast(true);
                        resolve(false);
                    }
                }).catch(() => {
                    setlistapermisostrabajador([]);
                    settitulotoast("Error");
                    settipotoast("error");
                    setmensajetoast(config.MessageErrorServer);
                    setabrirToast(true);
                    resolve(false);
                });
            }
            else {
                navigate("/login")
                reject(true)
            }
        });

    }
    const AbrirModaltrabajador = async (accion, fila) => {
        await setmostrarModalDetalla(true);
        await BuscarListaAreasActivas().then();

        if (accion === "VER") {
            setcontrolesreadonly(true);
            settrabajadorActual(fila);
            nombrestrabajadorRef.current.value = fila.nombres;
            areatrabajadorRef.current.value = fila.idarea;
            estadotrabajadorRef.current.value = fila.estado;
        }
        else {
            setcontrolesreadonly(false);
            nombrestrabajadorRef.current.value = "";
            areatrabajadorRef.current.value = "";
            estadotrabajadorRef.current.value = "";
        }
        setaccionactual(accion);

    }
    const ValidarInformacionEnviar = () => {
        let error = 0;
        if (nombrestrabajadorRef.current.value.trim() === "") {
            nombrestrabajadorRef.current.classList.add("is-invalid")
            error++;
        } else {
            nombrestrabajadorRef.current.classList.remove("is-invalid")
        }
        if (areatrabajadorRef.current.value.trim() === "") {
            areatrabajadorRef.current.classList.add("is-invalid");
            error++;
        } else {
            areatrabajadorRef.current.classList.remove("is-invalid");
        }
        // if (usuariotrabajadorRef.current.value.trim() === "") {
        //     usuariotrabajadorRef.current.classList.add("is-invalid");
        //     error++;
        // } else {
        //     usuariotrabajadorRef.current.classList.remove("is-invalid");
        // }

        if (estadotrabajadorRef.current.value.trim() === "") {
            estadotrabajadorRef.current.classList.add("is-invalid");
            error++;
        }
        else {
            estadotrabajadorRef.current.classList.remove("is-invalid");
        }

        if (error > 0) {
            return false;
        } else {
            return true;
        }

    }
    const Guardartrabajador = () => {
        if (ValidarUsuario()) {
            if (ValidarInformacionEnviar()) {
                if (accionactual === "NUEVO") {
                    CrearNuevotrabajador();
                } else {
                    Modificartrabajador();
                }
            }
        }
        else {
            navigate("/login");
        }
    }
    const CrearNuevotrabajador = () => {
        let url = `${config.endPoint}api/trabajador/agregar`;
        let UsuarioStorage = ObtenerUsuario();
        UsuarioStorage = JSON.parse(UsuarioStorage);
        //buscar Area
        console.log(areatrabajadorRef.current.value);
        let filtroarea = listaareasactivas.find(x => x.id === parseInt(areatrabajadorRef.current.value));
        let nuevocentro = {
            nombres: nombrestrabajadorRef.current.value.toUpperCase(),
            idarea: areatrabajadorRef.current.value.toUpperCase(),
            area: filtroarea.nombrearea,
            estado: estadotrabajadorRef.current.value.toUpperCase()
        }

        let headers = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + UsuarioStorage.token,
            },
            body: JSON.stringify(nuevocentro)
        };

        fetch(url, headers).then((respuesta) => {
            return respuesta.json()
        }).then((data) => {
            if (data.content) {
                //Buscar Lista Centros de Costo
                BuscarListastrabajadores(filasporpagina, pagina);
                settitulotoast("Correcto");
                settipotoast("success");
                setmensajetoast(data.message);
                setabrirToast(true);
                setmostrarModalDetalla(false);
            }
            else {
                settitulotoast("Alerta");
                settipotoast("warning");
                setmensajetoast(data.message);
                setabrirToast(true);
            }

        }).catch((error) => {
            settitulotoast("Error");
            settipotoast("error");
            setmensajetoast(config.MessageErrorServer);
            setabrirToast(true);
        });

    }
    const Modificartrabajador = () => {
        let url = `${config.endPoint}api/trabajador/modificar`;
        let UsuarioStorage = ObtenerUsuario();
        UsuarioStorage = JSON.parse(UsuarioStorage);
        //buscar Area
        let filtroarea = listaareasactivas.find(x => x.id === parseInt(areatrabajadorRef.current.value));
        let nuevocentro = {
            idtrabajador: trabajadorActual.id,
            nombres: nombrestrabajadorRef.current.value.toUpperCase(),
            idarea: areatrabajadorRef.current.value.toUpperCase(),
            area: filtroarea.nombrearea,
            estado: estadotrabajadorRef.current.value.toUpperCase()
        }

        let headers = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + UsuarioStorage.token,
            },
            body: JSON.stringify(nuevocentro)
        };

        fetch(url, headers).then((respuesta) => {
            return respuesta.json()
        }).then((data) => {
            if (data.content) {
                BuscarListastrabajadores(filasporpagina, pagina);
                settitulotoast("Correcto");
                settipotoast("success");
                setmensajetoast("Se actualizo Trabajador.");
                setabrirToast(true);
                setmostrarModalDetalla(false);
            }
            else {
                settitulotoast("Alerta");
                settipotoast("warning");
                setmensajetoast(data.message);
                setabrirToast(true);
            }
        }).catch((error) => {
            settitulotoast("Error");
            settipotoast("error");
            setmensajetoast(config.MessageErrorServer);
            setabrirToast(true);
        });
    }
    const Eliminartrabajador = (fila) => {

        if (ValidarUsuario()) {
            if (window.confirm(`¿Estas Seguro de eliminar el trabajador ${fila.nombres}?`)) {

                let url = `${config.endPoint}api/trabajador/eliminar`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let body_permisos = {
                    idtrabajador: fila.id,
                }

                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify(body_permisos)
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        BuscarListastrabajadores(filasporpagina, pagina);
                        settitulotoast("Correcto");
                        settipotoast("success");
                        setmensajetoast("Trabajador fue eliminado correctamente.");
                        setabrirToast(true);
                    }
                    else {
                        settitulotoast("Alerta");
                        settipotoast("warning");
                        setmensajetoast(data.message);
                        setabrirToast(true);
                    }

                }).catch((error) => {

                    settitulotoast("Error");
                    settipotoast("error");
                    setmensajetoast(config.MessageErrorServer);
                    setabrirToast(true);
                });
            }
        }
        else {
            navigate("/login");
        }
    }
    const AbrirModalUsuario = async (registro) => {
        await setmostarmodalusuario(true)
        settrabajadorActual(registro);
        setmostrarcontra(false);

        if (registro.usuario) {
            //tiene usuario
            setaccionactual("VER");
            setcontrolesusuarioreadonly(true);
            sethabilitarBotonguardar(false);
            sethabilitarBotonmodificar(true);
            usuariotrabajadorRef.current.value = registro.usuario;
            contratrabajadorRef.current.value = "**************"
            estadotrabajadorRef.current.value = registro.estado;
        } else {
            setaccionactual("NUEVO");
            setcontrolesusuarioreadonly(false);
            sethabilitarBotonguardar(true);
            sethabilitarBotonmodificar(false);
            usuariotrabajadorRef.current.value = "";
            contratrabajadorRef.current.value = ""
            estadotrabajadorRef.current.value = registro.estado;
        }
    }
    const ValidarInformacionUsuario = () => {
        let error = 0;
        if (usuariotrabajadorRef.current.value.trim() === "") {
            usuariotrabajadorRef.current.classList.add("is-invalid")
            error++;
        } else {
            usuariotrabajadorRef.current.classList.remove("is-invalid")
        }
        if (contratrabajadorRef.current.value.trim() === "") {
            contratrabajadorRef.current.classList.add("is-invalid");
            error++;
        } else {
            contratrabajadorRef.current.classList.remove("is-invalid");
        }

        if (estadotrabajadorRef.current.value.trim() === "") {
            estadotrabajadorRef.current.classList.add("is-invalid");
            error++;
        }
        else {
            estadotrabajadorRef.current.classList.remove("is-invalid");
        }

        if (error > 0) {
            return false;
        } else {
            return true;
        }

    }
    const GuardarUsuario = () => {
        if (ValidarUsuario()) {
            if (ValidarInformacionUsuario()) {
                switch (accionactual) {
                    case 'NUEVO':
                        CrearNuevoUsuario();
                        break;
                    case 'VER':
                        ModifEstadoUsuario();
                        break;
                    case 'MODIFICAR_CONTRA':
                        ModificarContra();
                        break;
                    case 'MODIFICAR_USUARIO':
                        ModificarUsuario();
                        break;
                    case 'MODIFICAR_ESTADO':
                        ModifEstadoUsuario();
                        break;
                }
            }
        }
        else {
            navigate("/login");
        }
    }
    const CrearNuevoUsuario = () => {
        if (ValidarUsuario()) {
            let url = `${config.endPoint}api/usuario/agregar`
            let nuevoUsuario = {
                idtrabajador: trabajadorActual.id,
                usuario: usuariotrabajadorRef.current.value.toLowerCase(),
                password: contratrabajadorRef.current.value,
                estado: estadotrabajadorRef.current.value.toUpperCase(),
            }
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage)
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify(nuevoUsuario)
            };

            fetch(url, headers).then((resp2) => {
                return resp2.json();
            }).then((data) => {
                if (data.content) {
                    BuscarListastrabajadores(filasporpagina, pagina);
                    settitulotoast("Correcto");
                    settipotoast("success");
                    setmensajetoast(data.message);
                    setabrirToast(true);
                    setmostarmodalusuario(false);
                }
                else {
                    settitulotoast("Alerta");
                    settipotoast("warning");
                    setmensajetoast(data.message);
                    setabrirToast(true);

                }
            }).catch(() => {
                settitulotoast("Error");
                settipotoast("error");
                setmensajetoast(config.MessageErrorServer);
                setabrirToast(true);
            });
        }
        else {
            navigate('/login');
        }
    }
    const ModificarContra = () => {
        if (contratrabajadorRef.current.value.trim() !== "") {
            contratrabajadorRef.current.classList.remove("is-invalid");
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/usuario/modificarcontra`
                let nuevoUsuario = {
                    idtrabajador: trabajadorActual.id,
                    new_password: contratrabajadorRef.current.value
                }
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage)
                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify(nuevoUsuario)
                };
                fetch(url, headers).then((resp2) => {
                    return resp2.json();
                }).then((data) => {
                    if (data.content) {
                        // BuscarListastrabajadores(filasporpagina, pagina);
                        settitulotoast("Correcto");
                        settipotoast("success");
                        setmensajetoast("Contraseña cambiada Correctamente");
                        setabrirToast(true);
                        setmostarmodalusuario(false);
                    }
                    else {
                        settitulotoast("Alerta");
                        settipotoast("warning");
                        setmensajetoast(data.message);
                        setabrirToast(true);
                    }
                }).catch(() => {

                    settitulotoast("Error");
                    settipotoast("error");
                    setmensajetoast(config.MessageErrorServer);
                    setabrirToast(true);
                });
            }
            else {
                navigate('/login');
            }

        } else {
            contratrabajadorRef.current.classList.add("is-invalid");
        }
    }
    const ModificarUsuario = () => {
        if (usuariotrabajadorRef.current.value.trim() !== "") {
            usuariotrabajadorRef.current.classList.remove("is-invalid");

            let url = `${config.endPoint}api/usuario/modificar`;
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage)

            //buscar Area        
            let modifusuario = {
                idtrabajador: trabajadorActual.id,
                usuario: usuariotrabajadorRef.current.value.toLowerCase(),
            }
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify(modifusuario)
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json()
            }).then((data) => {
                if (data.content) {
                    BuscarListastrabajadores(filasporpagina, pagina);
                    settitulotoast("Correcto");
                    settipotoast("success");
                    setmensajetoast("Se actualizo Usuario.");
                    setabrirToast(true);
                    setmostarmodalusuario(false);
                }
                else {
                    settitulotoast("Alerta");
                    settipotoast("warning");
                    setmensajetoast(data.message);
                    setabrirToast(true);
                }
            }).catch((error) => {
                settitulotoast("Error");
                settipotoast("error");
                setmensajetoast(config.MessageErrorServer);
                setabrirToast(true);
            });

        } else {
            contratrabajadorRef.current.classList.add("is-invalid");
        }
    }
    const ModifEstadoUsuario = () => {
        let url = `${config.endPoint}api/trabajador/modificar`;
        let UsuarioStorage = ObtenerUsuario();
        UsuarioStorage = JSON.parse(UsuarioStorage);
        //buscar Area
        let filtroarea = listaareasactivas.find(x => x.id === parseInt(areatrabajadorRef.current.value));
        let nuevocentro = {
            idtrabajador: trabajadorActual.id,
            estado: estadotrabajadorRef.current.value.toUpperCase()
        }

        let headers = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + UsuarioStorage.token,
            },
            body: JSON.stringify(nuevocentro)
        };

        fetch(url, headers).then((respuesta) => {
            return respuesta.json()
        }).then((data) => {
            if (data.content) {
                BuscarListastrabajadores(filasporpagina, pagina);
                settitulotoast("Correcto");
                settipotoast("success");
                setmensajetoast("Se actualizo Usuario.");
                setabrirToast(true);
                setmostarmodalusuario(false);
            }
            else {
                settitulotoast("Alerta");
                settipotoast("warning");
                setmensajetoast(data.message);
                setabrirToast(true);
            }
        }).catch((error) => {
            settitulotoast("Error");
            settipotoast("error");
            setmensajetoast(config.MessageErrorServer);
            setabrirToast(true);
        });
    }
    const HabilitarModificarContra = () => {
        contratrabajadorRef.current.disabled = false;
        contratrabajadorRef.current.value = '';
        setaccionactual("MODIFICAR_CONTRA");
        sethabilitarBotonguardar(true);
        sethabilitarBotonmodificar(false);
    }
    const HabilitarModifcarUsuario = () => {
        usuariotrabajadorRef.current.disabled = false;
        usuariotrabajadorRef.current.value = '';
        // contratrabajadorRef.current.disabled = false;
        // contratrabajadorRef.current.value = '';
        setaccionactual("MODIFICAR_USUARIO");
        sethabilitarBotonguardar(true);
        sethabilitarBotonmodificar(false);
    }
    const HabilitarModificarEstado = () => {
        usuariotrabajadorRef.current.disabled = true;
        contratrabajadorRef.current.disabled = true;
        estadotrabajadorRef.current.disabled = false
        setaccionactual("MODIFICAR_ESTADO");
        sethabilitarBotonguardar(true);
        sethabilitarBotonmodificar(false);
    }
    const AbrirModalPermisos = async (registro) => {
        await setmostrarmodalpermisos(true)
        setloadcarga(true);
        settrabajadorActual(registro);
        //Buscar Lista de Vistas
        await BuscarListaVistas().then()
        //Buscar lista Permisos trabajador
        await BuscarListaPermisos(registro).then()
        //Llenar Referencias
        setloadcarga(false);
        nombretrabajadorpermisoRef.current.value = registro.nombres;
        usuariopermisoRef.current.value = registro.usuario;
    }
    const CerrarModalPermisos=()=>{
        setlistapermisostrabajador([]);
        setmostrarmodalpermisos(false);
    }
    const CrearNuevoPermiso = () => {
        if (ValidarUsuario()) {
            let url = `${config.endPoint}api/permiso/agregar`
            let nuevopermiso = {
                idpantalla: permisopantallaRef.current.value,
                idtrabajador: trabajadorActual.id,
            }
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage)
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify(nuevopermiso)
            };

            fetch(url, headers).then((resp2) => {
                return resp2.json();
            }).then((data) => {
                if (data.content) {
                    BuscarListaPermisos(trabajadorActual).then();
                    settitulotoast("Correcto");
                    settipotoast("success");
                    setmensajetoast(data.message);
                    setabrirToast(true);
                    setmostarmodalusuario(false);
                }
                else {
                    settitulotoast("Alerta");
                    settipotoast("warning");
                    setmensajetoast(data.message);
                    setabrirToast(true);

                }
            }).catch(() => {
                settitulotoast("Error");
                settipotoast("error");
                setmensajetoast(config.MessageErrorServer);
                setabrirToast(true);
            });
        }
        else {
            navigate('/login');
        }
    }
    const EliminarPermiso = (fila) => {
        
        if (ValidarUsuario()) {
            if (window.confirm(`¿Estas Seguro de eliminar el permiso ${fila.ta_vistum.codpantalla}?`)) {

                let url = `${config.endPoint}api/permiso/eliminar`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let body_permisos = {
                    idpermiso: fila.id,
                }

                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify(body_permisos)
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        BuscarListaPermisos(trabajadorActual);
                        settitulotoast("Correcto");
                        settipotoast("success");
                        setmensajetoast("Permiso fue eliminado correctamente.");
                        setabrirToast(true);
                    }
                    else {
                        settitulotoast("Alerta");
                        settipotoast("warning");
                        setmensajetoast(data.message);
                        setabrirToast(true);
                    }

                }).catch((error) => {
                    settitulotoast("Error");
                    settipotoast("error");
                    setmensajetoast(config.MessageErrorServer);
                    setabrirToast(true);
                });
            }
        }
        else {
            navigate("/login");
        }
    }
    const ModificarEstadoPermiso = (idpermiso, estado) => {
        if (ValidarUsuario()) {
            let url = `${config.endPoint}api/permiso/modificar`;

            let body_permisos = {
                idpermiso: idpermiso,
                estado: estado
            }
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify(body_permisos)
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json()
            }).then((data) => {
                if (data.content) {
                    BuscarListaPermisos(trabajadorActual);
                    settitulotoast("Correcto");
                    settipotoast("success");
                    setmensajetoast("Se actualizo Permiso.");
                    setabrirToast(true);
                    setmostarmodalusuario(false);
                }
                else {
                    settitulotoast("Alerta");
                    settipotoast("warning");
                    setmensajetoast(data.message);
                    setabrirToast(true);
                }
            }).catch((error) => {
                settitulotoast("Error");
                settipotoast("error");
                setmensajetoast(config.MessageErrorServer);
                setabrirToast(true);
            });
        } else {
            navigate("/login");
        }
    }
    const OnChangeFiltrotrabajador = () => {
        if (filtrotrabajadorRef.current.value.trim() === '') {
            BuscarListastrabajadores(filasporpagina, pagina);
        }
    }
    const cambiarPagina = async (event, npagina) => {
        await setpagina(parseInt(npagina) + 1);
        BuscarListastrabajadores(filasporpagina, parseInt(npagina) + 1)
    }
    const cambiarfilasporpagina = async (ev) => {
        await setfilasporpagina(parseInt(ev.target.value));
        await setpagina(1);
        BuscarListastrabajadores(parseInt(ev.target.value), 1)
    }

    return (
        <Fragment>
            <PaginaCarga loadcarga={loadcarga} />

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={abrirToast} autoHideDuration={4000} onClose={() => { setabrirToast(false) }}>
                <Alert onClose={() => { setabrirToast(false) }} severity={tipotoast}>
                    <AlertTitle>{titulotoast}</AlertTitle>
                    {mensajetoast}
                </Alert>
            </Snackbar>

            <Header />
            {/* titulo */}
            <div className="container mt-1" style={{ backgroundColor: 'rgb(25,118,210)' }}>
                <div className="container  ">
                    <div className="row py-1">
                        <div className="col-6 col-sm-6 col-md-8 ">
                            <i title="Regresar" className="fas fa-tag  iconotitulo " style={{ color: 'aliceblue' }}></i>
                            <span className="mx-1" style={{ color: 'aliceblue' }}>TRABAJADOR / USUARIO</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container border rounded mt-1'>
                <Container fluid className='mt-1'>
                    <Row>
                        {/* <Col sx={12} md={3}>
                                <div className=" mt-md-0">
                                    <select onChange={() => { }} className="form-select form-select-sm" >
                                        <option value="Todos">Todos</option>
                                        <option value="Trabajador">Nombre</option>
                                        <option value="Supervisor">Estado</option>                                    
                                    </select>
                                </div>
                            </Col> */}

                        <Col sx={12} md={3} >
                            <Form.Control onChange={() => { OnChangeFiltrotrabajador() }} ref={filtrotrabajadorRef} type="text" placeholder="Valor Buscar" size="sm" />

                        </Col>
                        <Col sx={12} md={3} className="mt-md-0 mt-1">
                            <Button onClick={() => { BuscarListastrabajadores(filasporpagina, pagina) }} fullWidth size='small' variant='outlined'>Buscar</Button>
                        </Col>
                        <Col sx={12} md={6} className="text-end mt-md-0 mt-1 ms-auto">
                            <Button onClick={() => {
                                AbrirModaltrabajador("NUEVO", null);
                            }} variant="outlined">
                                <AddIcon></AddIcon>
                                Agregar
                            </Button>
                        </Col>
                        {/* <Col sx={12} md={3} className="text-end ">

                            <div className="dropdown">
                                <button className="btn btn-sm dropdown-toggle" type="button" id="ListaMenuAcciones" data-bs-toggle="dropdown" aria-expanded="false">
                                    Opciones
                                </button>
                                <ul className="dropdown-menu dropdown-menu-light" aria-labelledby="ListaMenuAcciones">
                                    <li onClick={() => { }}><small className="dropdown-item" ><i className="fas fa-calendar-check mx-2"></i>Reporte</small></li>
                                    <li onClick={() => { }}><small className="dropdown-item" ><i className="fas fa-calendar-check mx-2"></i>Ver Ejecutados</small></li>
                                    <li onClick={() => { }}><small className="dropdown-item" ><i className="fas fa-calendar-check mx-2"></i>Ver Faltantes</small></li>
                                </ul>
                            </div>

                        </Col> */}
                    </Row>
                </Container>

                {/* Tabla de datos */}
                <div className="row p-1">

                    <TableContainer style={{ maxHeight: window.outerHeight < 760 ? 430 : 650 }}>
                        <Table size='small' stickyHeader>
                            <TableHead>
                                <TableRow >
                                    <TableCell>Usuario</TableCell>
                                    <TableCell>Acciones</TableCell>
                                    <TableCell>Nombres</TableCell>
                                    <TableCell>Area</TableCell>
                                    <TableCell>Estado</TableCell>
                                    <TableCell>Permisos</TableCell>
                                    {/* <TableCell>Fecha Registro</TableCell>
                                    <TableCell>Usuario Crea</TableCell> */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    load ? listatrabajadores.map((row, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    {
                                                        row.usuario ?
                                                            <>
                                                                <IconButton onClick={() => { AbrirModalUsuario(row) }} size='small'>
                                                                    <PersonIcon color='primary' fontSize='inherit' />
                                                                </IconButton>
                                                                <span>{row.usuario}</span>
                                                            </> :
                                                            <IconButton onClick={() => { AbrirModalUsuario(row) }} size='small'>
                                                                <PersonOffIcon color='error' fontSize='inherit' />
                                                            </IconButton>
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton onClick={() => { AbrirModaltrabajador('VER', row) }} size="small">
                                                        <EditIcon fontSize="inherit" />
                                                    </IconButton>
                                                    <IconButton disabled onClick={() => { Eliminartrabajador(row) }} size="small">
                                                        <DeleteIcon fontSize="inherit" />
                                                    </IconButton>
                                                </TableCell>
                                                <TableCell >{row.nombres}</TableCell>
                                                <TableCell>{row.area}</TableCell>
                                                <TableCell >
                                                    {
                                                        row.estado === "ACTIVO" ?
                                                            <span className='text-primary'>
                                                                {row.estado}
                                                            </span> :
                                                            <span className='text-danger'>
                                                                {row.estado}
                                                            </span>
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton onClick={() => { AbrirModalPermisos(row) }} size='small'>
                                                        <GradingIcon color='success' fontSize='inherit' />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                        : <></>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 15, 25, 50]}
                        component="div"
                        count={cantidadElementos}
                        rowsPerPage={filasporpagina}
                        page={pagina - 1}
                        onPageChange={(ev, npagina) => { cambiarPagina(ev, npagina) }}
                        onRowsPerPageChange={(ev) => { cambiarfilasporpagina(ev) }}
                        labelRowsPerPage={"Filas Por Pagina"}
                    />
                </div>
            </div>
            {/* Modales  Trabajador*/}
            <Modal
                show={mostrarModalDetalla}
                onHide={() => { setmostrarModalDetalla(false) }}
                keyboard={false}
                size="md"
                centered >
                <Modal.Header>
                    <Modal.Title>
                        {`Trabajador [${accionactual}]`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Container>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Nombres Trabajador</small>
                                    <Form.Control disabled={controlesreadonly} ref={nombrestrabajadorRef} type="text" placeholder='Ingrese Nombre'></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Area</small>
                                    <select disabled={controlesreadonly} ref={areatrabajadorRef} className="form-select" defaultValue="">
                                        <option value="" disabled>Seleccione el Area</option>
                                        {
                                            listaareasactivas.map((row, index) => {
                                                return (
                                                    <option key={index} value={row.id}>{row.nombrearea}</option>
                                                );
                                            })
                                        }
                                    </select>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Estado</small>
                                    <select disabled={controlesreadonly} ref={estadotrabajadorRef} className="form-select" defaultValue="">
                                        <option value="" disabled>Seleccione el Estado</option>
                                        <option value="ACTIVO">ACTIVO</option>
                                        <option value="INACTIVO">INACTIVO</option>
                                    </select>
                                </Form.Group>

                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <StackMU spacing={'auto'} direction='row' justifyContent={'end'} sx={{ mt: 2 }}>
                            <Button variant="outlined" onClick={() => { setmostrarModalDetalla(false) }}>Cancelar</Button>
                            {
                                controlesreadonly ?
                                    <Button variant="outlined" onClick={() => { setcontrolesreadonly(!controlesreadonly) }}>Modificar</Button>
                                    : <></>
                            }
                            {
                                !controlesreadonly ?
                                    <Button onClick={() => { Guardartrabajador() }} variant="outlined">Guardar</Button>
                                    : <></>
                            }
                        </StackMU>
                    </Container>
                </Modal.Footer>
            </Modal>
            {/* Modal Usuario */}
            <Modal
                show={mostarmodalusuario}
                onHide={() => { setmostarmodalusuario(false) }}

                keyboard={false}
            >
                <Modal.Header >
                    <Modal.Title>
                        Usuario
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col>
                                <span>{trabajadorActual.nombres}</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <small className="text-primary">Usuario</small>
                                <Stack gap={2} direction='horizontal'>
                                    <Form.Control disabled={controlesusuarioreadonly} ref={usuariotrabajadorRef} type='text' placeholder='Ingrese Usuario'></Form.Control>
                                    <IconButton onClick={() => { HabilitarModifcarUsuario() }} disabled={!controlesusuarioreadonly} title='Cambiar Usuario'>
                                        <DriveFileRenameOutlineIcon />
                                    </IconButton>
                                </Stack>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <small className="text-primary">contraseña</small>
                                <Stack gap={2} direction='horizontal'>
                                    <Form.Control disabled={controlesusuarioreadonly} ref={contratrabajadorRef} type={mostrarcontra ? 'text' : 'password'} placeholder='Ingrese Contraseña'></Form.Control>
                                    <IconButton onClick={() => setmostrarcontra(!mostrarcontra)} title="Mostrar/Ocultar Contraseña">
                                        {
                                            mostrarcontra ?
                                                <VisibilityOffIcon />
                                                :
                                                <VisibilityIcon />
                                        }
                                    </IconButton>
                                    {
                                        controlesusuarioreadonly ?
                                            <IconButton onClick={() => { HabilitarModificarContra() }} title='Cambiar Contraseña'>
                                                <SyncLockIcon />
                                            </IconButton>
                                            : <></>
                                    }
                                </Stack>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Estado</small>
                                    <select disabled={controlesusuarioreadonly} ref={estadotrabajadorRef} className="form-select" defaultValue="">
                                        <option value="" disabled>Elija el Estado</option>
                                        <option value="ACTIVO">ACTIVO</option>
                                        <option value="INACTIVO">INACTIVO</option>
                                    </select>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Container>

                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <StackMU spacing={'auto'} direction='row' justifyContent={'end'} sx={{ mt: 2 }}>
                            <Button onClick={() => { setmostarmodalusuario(false) }} variant="outlined">Cancelar</Button>
                            {
                                habilitarBotonmodificar ?
                                    <Button onClick={() => { HabilitarModificarEstado() }} variant="outlined">Modificar</Button>
                                    : <></>
                            }
                            {
                                habilitarBotonguardar ?
                                    <Button onClick={() => { GuardarUsuario() }} variant="outlined">Guardar</Button>
                                    : <></>
                            }
                        </StackMU>
                    </Container>
                </Modal.Footer>
            </Modal>

            {/* Modal Permisos */}
            <Modal
                show={mostrarmodalpermisos}
                onHide={() => { CerrarModalPermisos() }}
                centered>
                <Modal.Header>
                    <Modal.Title>Permisos</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col>
                                <small className="text-primary">Trabajador</small>
                                <Form.Control ref={nombretrabajadorpermisoRef} disabled type="text" ></Form.Control>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <small className="text-primary">Usuario</small>
                                <Form.Control ref={usuariopermisoRef} disabled type="text" ></Form.Control>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <small className="text-primary">Vista / Pantalla / Menu</small>
                                <Stack gap={2} direction='horizontal'>
                                    <select ref={permisopantallaRef} className="form-select " defaultValue="">
                                        <option value="" disabled>Seleccione Acceso</option>
                                        {
                                            listapsntallasactivas.map((row, index) => {
                                                return (
                                                    <option key={index} value={row.id}>{row.codpantalla}</option>
                                                );
                                            })
                                        }
                                    </select>
                                    <Button onClick={() => { CrearNuevoPermiso() }} variant='outlined'>Agregar</Button>
                                </Stack>
                            </Col>
                        </Row>
                    </Container>

                    <TableContainer style={{ maxHeight: '300px' }}>
                        <Table size='small' stickyHeader>
                            <TableHead>
                                <TableRow >
                                    <TableCell>Acciones</TableCell>
                                    <TableCell>Pantalla / Acceso</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {
                                    listapermisostrabajador.map((row, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell >
                                                    <Container fluid>
                                                        <Row>
                                                            <Col>
                                                                <Stack gap={4} direction='horizontal'>
                                                                    <IconButton onClick={() => { EliminarPermiso(row) }}>
                                                                        <DeleteForeverIcon />
                                                                    </IconButton>
                                                                    <div className=" form-check form-switch " style={{ width: '15px' }}>
                                                                        {
                                                                            (row.estado === 'ACTIVO') ?
                                                                                <input onClick={() => { ModificarEstadoPermiso(row.id, "INACTIVO") }} className="form-check-input" type="checkbox" defaultChecked /> 
                                                                                :
                                                                                <input onClick={() => { ModificarEstadoPermiso(row.id, "ACTIVO") }} className="form-check-input" type="checkbox" />
                                                                        }
                                                                    </div>
                                                                </Stack>
                                                            </Col>
                                                        </Row>
                                                    </Container>
                                                </TableCell>
                                                <TableCell>
                                                    {row.ta_vistum.codpantalla}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                }

                            </TableBody>
                        </Table>
                    </TableContainer>

                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <StackMU spacing={'auto'} direction='row' justifyContent={'end'} sx={{ mt: 2 }}>
                            <Button onClick={()=>{CerrarModalPermisos()}} variant="outlined">Cerrar</Button>
                        </StackMU>
                    </Container>
                </Modal.Footer>
            </Modal>

        </Fragment>
    );
}