import React, { Fragment, useEffect, useRef, useState } from 'react';
import Header from './../dashboard/header'

// Importaciones Bootstrap
import { Container, Row, Col, Form, Modal } from 'react-bootstrap';

// Importaciones MaterialUI
import { Table, TableContainer, TableHead, TableCell, TableBody, TableRow, TablePagination, IconButton, Button, Snackbar, Alert, AlertTitle } from '@mui/material';


import StackMU from '@mui/material/Stack'
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { ValidarUsuario, ObtenerUsuario, config,ValidarPermisos,listarPermisos,LIST_SCREENS } from '../utils/utils';
import PaginaCarga from '../utils/cargapagina'

import { useNavigate } from 'react-router-dom';
import moment from 'moment';

export default function CentroCosto() {

    const navigate = useNavigate();
    //Estados
    const [mostrarModalDetalla, setmostrarModalDetalla] = useState(false);

    const [abrirToast, setabrirToast] = useState(false);
    const [titulotoast, settitulotoast] = useState("");
    const [mensajetoast, setmensajetoast] = useState("");
    const [tipotoast, settipotoast] = useState("error");

    const [listacentroscorto, setlistacentroscorto] = useState([]);
    const [accionactual, setaccionactual] = useState("");
    const [controlesreadonly, setcontrolesreadonly] = useState(true);
    const [centrocostoActual, setcentrocostoActual] = useState({});

    const [load, setload] = useState(false);
    const [loadcarga, setloadcarga] = useState(true);

    const [cantidadElementos, setcantidadElementos] = useState(0);
    const [filasporpagina, setfilasporpagina] = useState(10);
    const [pagina, setpagina] = useState(1);

    //Referencias controles
    let nombrecentrocostoRef = useRef();
    let descripcioncentrocostoRef = useRef();
    let codigocentrocostoRef = useRef();
    let estadocentrocostoRef = useRef();
    let filtrocentrocostoRef = useRef();

    //Evento Inicio
    useEffect(() => {
        ValidarAccesos();
    }, []);
    const ValidarAccesos = () => {
        if (ValidarUsuario()) {
            listarPermisos().then((permisos) => {                
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CONFIG_CENTROCOSTO, permisos)) {                                 
                    BuscarListasCentrosCosto(filasporpagina, pagina);
                } else {                    
                    settitulotoast("Alerta");
                    settipotoast("warning");
                    setmensajetoast("No tiene permisos para acceder a este Recurso.");
                    setabrirToast(true);
                    setTimeout(() => {
                        navigate('/');
                    }, 1000);
                }
            });
        } else {
            navigate('/login');
        }
    }
    //Procedimientos
    const BuscarListasCentrosCosto = (filas, paginaactual) => {
        if (ValidarUsuario()) {
            let url = "";
            if (filtrocentrocostoRef.current.value.trim() === "") {
                url = `${config.endPoint}api/centrocosto/listar/${filas}/${paginaactual}`;
            }
            else {
                url = `${config.endPoint}api/centrocosto/listar/${filas}/${paginaactual}/${filtrocentrocostoRef.current.value}`;
            }            
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data) {
                    setlistacentroscorto(data.content);
                    setcantidadElementos(data.cantidad)
                    setload(true);
                    setloadcarga(false);
                }
            }).catch((error) => {
                console.log(error);
                settitulotoast("Alerta");
                settipotoast("warning");
                setmensajetoast(config.MessageErrorServer);
                setabrirToast(true);
            });
        } else {
            navigate("/login");
        }
    }
    const cambiarPagina = async (event, npagina) => {
        await setpagina(parseInt(npagina) + 1);
        BuscarListasCentrosCosto(filasporpagina, parseInt(npagina) + 1)
    }
    const cambiarfilasporpagina = async (ev) => {
        await setfilasporpagina(parseInt(ev.target.value));
        await setpagina(1);
        BuscarListasCentrosCosto(parseInt(ev.target.value), 1)
    }
    const AbrirModalCentrocosto = async (accion, fila) => {
        await setmostrarModalDetalla(true);
        if (accion === "VER") {
            setcontrolesreadonly(true);
            setcentrocostoActual(fila);
            nombrecentrocostoRef.current.value = fila.nombrecentrocosto;
            descripcioncentrocostoRef.current.value = fila.descripcion;
            codigocentrocostoRef.current.value = fila.codigo;
            estadocentrocostoRef.current.value = fila.estado;
        }
        else {
            nombrecentrocostoRef.current.value = "";
            descripcioncentrocostoRef.current.value = "";
            codigocentrocostoRef.current.value = "";
            estadocentrocostoRef.current.value = "";
            setcontrolesreadonly(false);
        }
        setaccionactual(accion);

    }
    const ValidarInformacionEnviar = () => {
        let error = 0;
        if (nombrecentrocostoRef.current.value.trim() === "") {
            nombrecentrocostoRef.current.classList.add("is-invalid")
            error++;
        } else {
            nombrecentrocostoRef.current.classList.remove("is-invalid")
        }
        if (descripcioncentrocostoRef.current.value.trim() === "") {
            descripcioncentrocostoRef.current.classList.add("is-invalid");
            error++;
        } else {
            descripcioncentrocostoRef.current.classList.remove("is-invalid");
        }
        if (codigocentrocostoRef.current.value.trim() === "") {
            codigocentrocostoRef.current.classList.add("is-invalid");
            error++;
        } else {
            codigocentrocostoRef.current.classList.remove("is-invalid");
        }

        if (estadocentrocostoRef.current.value.trim() === "") {
            estadocentrocostoRef.current.classList.add("is-invalid");
            error++;
        }
        else {
            estadocentrocostoRef.current.classList.remove("is-invalid");
        }

        if (error > 0) {
            return false;
        } else {
            return true;
        }

    }
    const GuardarCentroCosto = () => {
        if (ValidarUsuario()) {
            if (ValidarInformacionEnviar()) {
                if (accionactual === "NUEVO") {
                    // console.log("nuevo registro");
                    CrearNuevoCentroCostro();
                } else {
                    // console.log("modificar registro");
                    ModificarCentroCosto();
                }
            }
        }
        else {
            navigate("/login");
        }
    }
    const CrearNuevoCentroCostro = () => {
        let url = `${config.endPoint}api/centrocosto/agregar`;
        let UsuarioStorage = ObtenerUsuario();
        UsuarioStorage = JSON.parse(UsuarioStorage);

        let nuevocentro = {
            nombrecentrocosto: nombrecentrocostoRef.current.value.toUpperCase(),
            descripcion: descripcioncentrocostoRef.current.value.toUpperCase(),
            codigo: codigocentrocostoRef.current.value.toUpperCase(),
            estado: estadocentrocostoRef.current.value.toUpperCase(),
            usuariocrea: UsuarioStorage.id
        }

        let headers = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + UsuarioStorage.token,
            },
            body: JSON.stringify(nuevocentro)
        };

        fetch(url, headers).then((respuesta) => {
            return respuesta.json()
        }).then((data) => {
            // console.log(data);
            if (data.content) {
                //Buscar Lista Centros de Costo
                BuscarListasCentrosCosto(filasporpagina, pagina);
                settitulotoast("Correcto");
                settipotoast("success");
                setmensajetoast(data.message);
                setabrirToast(true);
                setmostrarModalDetalla(false);
            }
            else {
                settitulotoast("Error");
                settipotoast("warning");
                setmensajetoast(data.message);
                setabrirToast(true);
            }

        }).catch((error) => {            
            settitulotoast("Error");
            settipotoast("error");
            setmensajetoast(config.MessageErrorServer);
            setabrirToast(true);
        });

    }
    const ModificarCentroCosto = () => {        

        let url = `${config.endPoint}api/centrocosto/modificar`;
        let UsuarioStorage = ObtenerUsuario();
        UsuarioStorage = JSON.parse(UsuarioStorage);

        let nuevocentro = {
            idCentroCosto: centrocostoActual.id,
            nombrecentrocosto: nombrecentrocostoRef.current.value.toUpperCase(),
            descripcion: descripcioncentrocostoRef.current.value.toUpperCase(),
            codigo: codigocentrocostoRef.current.value.toUpperCase(),
            estado: estadocentrocostoRef.current.value.toUpperCase(),
            usuariomodifica: UsuarioStorage.id
        }

        let headers = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + UsuarioStorage.token,
            },
            body: JSON.stringify(nuevocentro)
        };

        fetch(url, headers).then((respuesta) => {
            return respuesta.json()
        }).then((data) => {
            if (data.content) {
                BuscarListasCentrosCosto(filasporpagina, pagina);
                settitulotoast("Correcto");
                settipotoast("success");
                setmensajetoast("Se actualizo Centro de Costo.");
                setabrirToast(true);
                setmostrarModalDetalla(false);
            }
            else {
                settitulotoast("Alerta");
                settipotoast("warning");
                setmensajetoast(data.message);
                setabrirToast(true);
            }
        }).catch((error) => {
            settitulotoast("Error");
            settipotoast("error");
            setmensajetoast(config.MessageErrorServer);
            setabrirToast(true);
        });

    }
    const EliminarcentroCosto = (fila) => {
        console.log(fila);
        if (ValidarUsuario()) {
            if (window.confirm(`¿Estas Seguro de eliminar el centro de costo ${fila.nombrecentrocosto}?`)) {

                let url = `${config.endPoint}api/centrocosto/eliminar`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let body_permisos = {
                    idCentroCosto: fila.id,
                }

                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify(body_permisos)
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    console.log(data);
                    if (data.content) {
                        BuscarListasCentrosCosto(filasporpagina, pagina);
                        settitulotoast("Correcto");
                        settipotoast("success");
                        setmensajetoast("Centro de costo fue eliminado correctamente.");
                        setabrirToast(true);
                    }
                    else {
                        settitulotoast("Alerta");
                        settipotoast("warning");
                        setmensajetoast(data.message);
                        setabrirToast(true);
                    }

                }).catch((error) => {
                    console.log(error);
                    settitulotoast("Error");
                    settipotoast("error");
                    setmensajetoast(config.MessageErrorServer);
                    setabrirToast(true);
                });
            }
        }
        else {
            navigate("/login");
        }
    }
    const OnChangeFiltroCentroCosto = () => {
        if (filtrocentrocostoRef.current.value.trim() === '') {
            BuscarListasCentrosCosto(filasporpagina, pagina);
        }
    }
    return (
        <Fragment>
            <PaginaCarga loadcarga={loadcarga} />

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={abrirToast} autoHideDuration={4000} onClose={() => { setabrirToast(false) }}>
                <Alert onClose={() => { setabrirToast(false) }} severity={tipotoast}>
                    <AlertTitle>{titulotoast}</AlertTitle>
                    {mensajetoast}
                </Alert>
            </Snackbar>

            <Header />
            {/* titulo */}
            <div className="container mt-1" style={{ backgroundColor: 'rgb(25,118,210)' }}>
                <div className="container  ">
                    <div className="row py-1">
                        <div className="col-6 col-sm-6 col-md-8 ">
                            <i title="Regresar" className="fas fa-tag  iconotitulo " style={{ color: 'aliceblue' }}></i>
                            <span className="mx-1" style={{ color: 'aliceblue' }}>CENTROS DE COSTO</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container border rounded mt-1'>
                <Container fluid className='mt-1'>
                    <Row>
                        {/* <Col sx={12} md={3}>
                            <div className=" mt-md-0">
                                <select onChange={() => { }} className="form-select form-select-sm" >
                                    <option value="Todos">Todos</option>
                                    <option value="Trabajador">Nombre</option>
                                    <option value="Supervisor">Estado</option>                                    
                                </select>
                            </div>
                        </Col> */}

                        <Col sx={12} md={3} >
                            <Form.Control onChange={() => { OnChangeFiltroCentroCosto() }} ref={filtrocentrocostoRef} type="text" placeholder="Valor Buscar" size="sm" />

                        </Col>
                        <Col sx={12} md={3} className="mt-md-0 mt-1">
                            <Button onClick={() => { BuscarListasCentrosCosto(filasporpagina, pagina, filtrocentrocostoRef.current.value) }} fullWidth size='small' variant='outlined'>Buscar</Button>
                        </Col>
                        <Col sx={12} md={6} className="text-end mt-md-0 mt-1 ms-auto">
                            <Button onClick={() => {
                                AbrirModalCentrocosto("NUEVO", null);
                            }} variant="outlined">
                                <AddIcon></AddIcon>
                                Agregar
                            </Button>
                        </Col>
                        {/* <Col sx={12} md={3} className="text-end ">

                            <div className="dropdown">
                                <button className="btn btn-sm dropdown-toggle" type="button" id="ListaMenuAcciones" data-bs-toggle="dropdown" aria-expanded="false">
                                    Opciones
                                </button>
                                <ul className="dropdown-menu dropdown-menu-light" aria-labelledby="ListaMenuAcciones">
                                    <li onClick={() => { }}><small className="dropdown-item" ><i className="fas fa-calendar-check mx-2"></i>Reporte</small></li>
                                    <li onClick={() => { }}><small className="dropdown-item" ><i className="fas fa-calendar-check mx-2"></i>Ver Ejecutados</small></li>
                                    <li onClick={() => { }}><small className="dropdown-item" ><i className="fas fa-calendar-check mx-2"></i>Ver Faltantes</small></li>
                                </ul>
                            </div>

                        </Col> */}
                    </Row>
                </Container>

                {/* Tabla de datos */}
                <div className="row p-1">

                    <TableContainer style={{ maxHeight: window.outerHeight < 760 ? 430 : 650 }}>
                        <Table size='small' stickyHeader>
                            <TableHead>
                                <TableRow >
                                    <TableCell>Acciones</TableCell>
                                    <TableCell>Nombre Centro de Costo</TableCell>
                                    <TableCell>Estado</TableCell>
                                    <TableCell>Fecha Registro</TableCell>
                                    <TableCell>Usuario crea</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    load ? listacentroscorto.map((row, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    <IconButton onClick={() => { AbrirModalCentrocosto('VER', row) }} size="small">
                                                        <EditIcon fontSize="inherit" />
                                                    </IconButton>
                                                    <IconButton disabled onClick={() => { EliminarcentroCosto(row) }} size="small">
                                                        <DeleteIcon fontSize="inherit" />
                                                    </IconButton>
                                                </TableCell>
                                                <TableCell >{row.nombrecentrocosto}</TableCell>
                                                <TableCell >{row.estado}</TableCell>
                                                <TableCell >{moment(row.fechacreacion).format('yyy-MM-DD hh:mm:ss')}</TableCell>                                                
                                                <TableCell >{row.usuariocrea}</TableCell>
                                                {/* <TableCell >{row.fechamodifica?moment(row.fechamodifica).format('yyy-MM-DD hh:mm:ss'):''}</TableCell>
                                                <TableCell >{row.usuariomodifica}</TableCell> */}
                                            </TableRow>
                                        );
                                    })
                                        : <></>
                                }

                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 15, 25, 50]}
                        component="div"
                        count={cantidadElementos}
                        rowsPerPage={filasporpagina}
                        page={pagina - 1}
                        onPageChange={(ev, npagina) => { cambiarPagina(ev, npagina) }}
                        onRowsPerPageChange={(ev) => { cambiarfilasporpagina(ev) }}
                        labelRowsPerPage={"Filas Por Pagina"}
                    />
                </div>

            </div>

            {/* Modales  */}

            <Modal
                show={mostrarModalDetalla}
                onHide={() => { setmostrarModalDetalla(false) }}
                keyboard={false}
                size="md"
                centered >
                <Modal.Header>
                    <Modal.Title>
                        {`Centro de Costo [${accionactual}]`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Container>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Nombre Centro de Costo</small>
                                    <Form.Control disabled={controlesreadonly} ref={nombrecentrocostoRef} type="text" placeholder='Ingrese Nombre'></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Descripción</small>
                                    <Form.Control disabled={controlesreadonly} ref={descripcioncentrocostoRef} type="text" placeholder='Ingrese Descripción'></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Codigo</small>
                                    <Form.Control disabled={controlesreadonly} ref={codigocentrocostoRef} type="text" placeholder='Ingrese Codigo'></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Estado</small>
                                    <select disabled={controlesreadonly} ref={estadocentrocostoRef} className="form-select" defaultValue="">
                                        <option value="" disabled>Seleccione el Estado</option>
                                        <option value="ACTIVO">ACTIVO</option>
                                        <option value="INACTIVO">INACTIVO</option>
                                    </select>
                                </Form.Group>

                            </Col>
                        </Row>
                        {/* <Row>
                            <Col md={6}>
                                <small className="text-primary">Usuario Crea</small>
                                <Form.Control disabled value="Admin"></Form.Control>
                            </Col>
                            <Col md={6}>
                                <small className="text-primary">Fecha Crea</small>
                                <Form.Control disabled type="date"></Form.Control>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <small className="text-primary">Usuario Modifica</small>
                                <Form.Control disabled value="Admin"></Form.Control>
                            </Col>
                            <Col md={6}>
                                <small className="text-primary">Fecha Modifica</small>
                                <Form.Control disabled type="date"></Form.Control>
                            </Col>
                        </Row> */}
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <StackMU spacing={'auto'} direction='row' justifyContent={'end'} sx={{ mt: 2 }}>
                            <Button variant="outlined" onClick={() => { setmostrarModalDetalla(false) }}>Cancelar</Button>
                            {
                                controlesreadonly ?
                                    <Button variant="outlined" onClick={() => { setcontrolesreadonly(!controlesreadonly) }}>Modificar</Button>
                                    : <></>
                            }
                            {
                                !controlesreadonly ?
                                    <Button onClick={() => { GuardarCentroCosto() }} variant="outlined">Guardar</Button>
                                    : <></>
                            }
                        </StackMU>
                    </Container>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
}