import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';

// componentes
import Dashboard from './components/dashboard/dashboard';
import Bancos  from './components/bancos/bancos';
import CajaChica from './components/caja_chica/cajachica';
import Proveedores from './components/caja_chica/proveedores';
import ConfigCentroCosto from './components/configuracion/centrocosto';
import ConfigProyecto from './components/configuracion/proyectos';
import ConfigTipoGasto from './components/configuracion/tipogasto';
import ConfigTrabajador from './components/configuracion/trabajador';
import ConfigArea from './components/configuracion/areas';
import Configvistas from './components/configuracion/vistas';
import ConfigCodigoGasto from './components/configuracion/codigogasto';
import Login from './components/inicio/login';
import Estadisticas from './components/reportes/estadisticas';
import ElegirProyecto from './components/dashboard/proyectos';
import NotFoundPage from './components/utils/notfount';

function App() {  
  return (
    <BrowserRouter>
      <Routes>
      <Route exact path="/" element={<ElegirProyecto/>} />
        <Route exact path="/dashboard/:idproyecto" element={<Dashboard/>} />
        <Route exact path="/bancos/:idproyecto" element={<Bancos/>} />
        <Route exact path="/cajachica/:idproyecto" element={<CajaChica/>} />
        <Route exact path="/configurar/trabajador" element={<ConfigTrabajador/>} />
        <Route exact path="/configurar/detallegasto" element={<ConfigTipoGasto/>} />
        <Route exact path="/configurar/centrocosto" element={<ConfigCentroCosto/>} />
        <Route exact path="/configurar/proyectos" element={<ConfigProyecto/>} />
        <Route exact path="/configurar/proveedores" element={<Proveedores/>} />
        <Route exact path="/configurar/area" element={<ConfigArea/>} />        
        <Route exact path="/configurar/vistas" element={<Configvistas/>} />        
        <Route exact path="/configurar/codigos" element={<ConfigCodigoGasto/>} /> 
        <Route exact path="/reporte/estadistica" element={<Estadisticas/>} />
        <Route exact path="/login" element={<Login/>} />
        <Route path='*' element={<NotFoundPage/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
