import React, { Fragment, useEffect, useRef, useState } from 'react';
import Header from './../dashboard/header'

// Importaciones Bootstrap
import { Container, Row, Col, Form, Modal, Stack } from 'react-bootstrap';

// Importaciones MaterialUI
import { Table, TableContainer, TableHead, TableCell, TableBody, TableRow, TablePagination, IconButton, Button, Snackbar, Alert, AlertTitle } from '@mui/material';


import StackMU from '@mui/material/Stack'
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { ValidarUsuario, ObtenerUsuario, config,ValidarPermisos,LIST_SCREENS,listarPermisos } from '../utils/utils';
import PaginaCarga from '../utils/cargapagina'

import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

export default function Proyectos() {
    const navigate = useNavigate();

    //Estados
    const [mostrarModalDetalla, setmostrarModalDetalla] = useState(false);
    const [mostarModalPermisos, setmostarModalPermisos] = useState(false);

    const [abrirToast, setabrirToast] = useState(false);
    const [titulotoast, settitulotoast] = useState("");
    const [mensajetoast, setmensajetoast] = useState("");
    const [tipotoast, settipotoast] = useState("error");

    const [listaproyectos, setlistaproyectos] = useState([]);
    const [listatrabajadoresactivos, setlistatrabajadoresactivos] = useState([]);
    const [listapermisosproyecto, setlistapermisosproyecto] = useState([]);
    const [accionactual, setaccionactual] = useState("");
    const [controlesreadonly, setcontrolesreadonly] = useState(true);
    const [proyectoActual, setproyectoActual] = useState({});

    const [load, setload] = useState(false);
    const [loadcarga, setloadcarga] = useState(true);

    const [cantidadElementos, setcantidadElementos] = useState(0);
    const [filasporpagina, setfilasporpagina] = useState(10);
    const [pagina, setpagina] = useState(1);

    //Referencias controles
    const nombreproyectoRef = useRef();
    const descripcionproyectoRef = useRef();
    const responsableproyectoRef = useRef();
    //const codigoproyectoRef = useRef();
    const estadoproyectoRef = useRef();
    const filtroproyectoRef = useRef();
    //Referencia permisos
    const nombreproyectopermisoRef = useRef();
    const encargadoproyectopermisoRef = useRef();
    const nombretrabajadorpermisoRef = useRef();
    //Evento Inicio
    useEffect(() => {
        ValidarAccesos();
    }, []);
    const ValidarAccesos = () => {
        if (ValidarUsuario()) {
            listarPermisos().then((permisos) => {
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CONFIG_PROYECTOS, permisos)) {                    
                    BuscarListasproyectos(filasporpagina, pagina);
                } else {
                    settitulotoast("Alerta");
                    settipotoast("warning");
                    setmensajetoast("No tiene permisos para acceder a este Recurso.");
                    setabrirToast(true);
                    setTimeout(() => {
                        navigate('/');
                    }, 1000);
                }
            });
        } else {
            navigate('/login');
        }
    }
    //Procedimientos
    const BuscarListasproyectos = (filas, paginaactual) => {
        if (ValidarUsuario()) {
            let url = "";
            if (filtroproyectoRef.current.value.trim() === "") {
                url = `${config.endPoint}api/proyecto/listar/${filas}/${paginaactual}`;
            }
            else {
                url = `${config.endPoint}api/proyecto/listar/${filas}/${paginaactual}/${filtroproyectoRef.current.value}`;
            }
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data) {
                    setlistaproyectos(data.content);
                    setcantidadElementos(data.cantidad)
                    setload(true);
                    setloadcarga(false);
                }
            }).catch((error) => {

                settitulotoast("Alerta");
                settipotoast("warning");
                setmensajetoast(config.MessageErrorServer);
                setabrirToast(true);
            });
        } else {
            navigate("/login");
        }
    }
    const BuscarListaTrabajadoresActivos = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/trabajador/listaractivos`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        setlistatrabajadoresactivos(data.content);
                        
                        resolve(true);
                    }
                    else {
                        setlistatrabajadoresactivos([]);
                        settitulotoast("Alerta");
                        settipotoast("warning");
                        setmensajetoast(data.message);
                        setabrirToast(true);
                        resolve(false);
                    }
                }).catch(() => {
                    setlistatrabajadoresactivos([]);
                    settitulotoast("Error");
                    settipotoast("error");
                    setmensajetoast(config.MessageErrorServer);
                    setabrirToast(true);
                    resolve(false);
                });
            }
            else {
                navigate("/login")
                reject(true)
            }
        });
    }
    const BuscarListaPermisosProyecto = (registro) => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/permisoproyecto/buscar/${registro.id}`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };
                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        
                        setlistapermisosproyecto(data.content);
                        resolve(true);
                    }
                    else {
                        setlistapermisosproyecto([]);
                        settitulotoast("Alerta");
                        settipotoast("warning");
                        setmensajetoast(data.message);
                        setabrirToast(true);
                        resolve(false);
                    }
                }).catch(() => {
                    setlistapermisosproyecto([]);
                    settitulotoast("Error");
                    settipotoast("error");
                    setmensajetoast(config.MessageErrorServer);
                    setabrirToast(true);
                    resolve(false);
                });
            }
            else {
                navigate("/login")
                reject(true)
            }
        });

    }
    const AbrirModalproyecto = async (accion, fila) => {
        await setmostrarModalDetalla(true);
        if (accion === "VER") {
            setcontrolesreadonly(true);
            setproyectoActual(fila);
            nombreproyectoRef.current.value = fila.nombreproyecto;
            descripcionproyectoRef.current.value = fila.descripcionproyecto;
            responsableproyectoRef.current.value = fila.responsableproyecto
            estadoproyectoRef.current.value = fila.estado;
        }
        else {
            setcontrolesreadonly(false);
            nombreproyectoRef.current.value = "";
            descripcionproyectoRef.current.value = "";
            responsableproyectoRef.current.value = "";
            estadoproyectoRef.current.value = "";
        }
        setaccionactual(accion);

    }
    const ValidarInformacionEnviar = () => {
        let error = 0;
        if (nombreproyectoRef.current.value.trim() === "") {
            nombreproyectoRef.current.classList.add("is-invalid")
            error++;
        } else {
            nombreproyectoRef.current.classList.remove("is-invalid")
        }
        if (descripcionproyectoRef.current.value.trim() === "") {
            descripcionproyectoRef.current.classList.add("is-invalid");
            error++;
        } else {
            descripcionproyectoRef.current.classList.remove("is-invalid");
        }
        if (responsableproyectoRef.current.value.trim() === "") {
            responsableproyectoRef.current.classList.add("is-invalid");
            error++;
        } else {
            responsableproyectoRef.current.classList.remove("is-invalid");
        }

        if (estadoproyectoRef.current.value.trim() === "") {
            estadoproyectoRef.current.classList.add("is-invalid");
            error++;
        }
        else {
            estadoproyectoRef.current.classList.remove("is-invalid");
        }

        if (error > 0) {
            return false;
        } else {
            return true;
        }

    }
    const Guardarproyecto = () => {
        if (ValidarUsuario()) {
            if (ValidarInformacionEnviar()) {
                if (accionactual === "NUEVO") {
                    // 
                    CrearNuevoproyecto();
                } else {
                    // 
                    Modificarproyecto();
                }
            }
        }
        else {
            navigate("/login");
        }
    }
    const CrearNuevoproyecto = () => {
        let url = `${config.endPoint}api/proyecto/agregar`;
        let UsuarioStorage = ObtenerUsuario();
        UsuarioStorage = JSON.parse(UsuarioStorage);

        let nuevocentro = {
            nombreproyecto: nombreproyectoRef.current.value.toUpperCase(),
            descripcionproyecto: descripcionproyectoRef.current.value.toUpperCase(),
            responsableproyecto: responsableproyectoRef.current.value.toUpperCase(),
            estado: estadoproyectoRef.current.value.toUpperCase(),
            usuariocrea: UsuarioStorage.id
        }

        let headers = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + UsuarioStorage.token,
            },
            body: JSON.stringify(nuevocentro)
        };

        fetch(url, headers).then((respuesta) => {
            return respuesta.json()
        }).then((data) => {
            // 
            if (data.content) {
                //Buscar Lista Centros de Costo
                BuscarListasproyectos(filasporpagina, pagina);
                settitulotoast("Correcto");
                settipotoast("success");
                setmensajetoast(data.message);
                setabrirToast(true);
                setmostrarModalDetalla(false);
            }
            else {
                settitulotoast("Error");
                settipotoast("warning");
                setmensajetoast(data.message);
                setabrirToast(true);
            }

        }).catch((error) => {
            settitulotoast("Error");
            settipotoast("error");
            setmensajetoast(config.MessageErrorServer);
            setabrirToast(true);
        });

    }
    const Modificarproyecto = () => {

        let url = `${config.endPoint}api/proyecto/modificar`;
        let UsuarioStorage = ObtenerUsuario();
        UsuarioStorage = JSON.parse(UsuarioStorage);

        let nuevocentro = {
            idProyecto: proyectoActual.id,
            nombreproyecto: nombreproyectoRef.current.value.toUpperCase(),
            descripcionproyecto: descripcionproyectoRef.current.value.toUpperCase(),
            responsableproyecto: responsableproyectoRef.current.value.toUpperCase(),
            estado: estadoproyectoRef.current.value.toUpperCase(),
            usuariomodifica: UsuarioStorage.id
        }

        let headers = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + UsuarioStorage.token,
            },
            body: JSON.stringify(nuevocentro)
        };

        fetch(url, headers).then((respuesta) => {
            return respuesta.json()
        }).then((data) => {
            if (data.content) {
                BuscarListasproyectos(filasporpagina, pagina);
                settitulotoast("Correcto");
                settipotoast("success");
                setmensajetoast("Se actualizo Proyecto");
                setabrirToast(true);
                setmostrarModalDetalla(false);
            }
            else {
                settitulotoast("Alerta");
                settipotoast("warning");
                setmensajetoast(data.message);
                setabrirToast(true);
            }
        }).catch((error) => {
            settitulotoast("Error");
            settipotoast("error");
            setmensajetoast(config.MessageErrorServer);
            setabrirToast(true);
        });

    }
    const Eliminarproyecto = (fila) => {

        if (ValidarUsuario()) {
            if (window.confirm(`¿Estas Seguro de eliminar el proyecto ${fila.nombreproyecto}?`)) {

                let url = `${config.endPoint}api/proyecto/eliminar`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let body_permisos = {
                    idProyecto: fila.id,
                }

                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify(body_permisos)
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {

                    if (data.content) {
                        BuscarListasproyectos(filasporpagina, pagina);
                        settitulotoast("Correcto");
                        settipotoast("success");
                        setmensajetoast("Proyecto fue eliminado correctamente.");
                        setabrirToast(true);
                    }
                    else {
                        settitulotoast("Alerta");
                        settipotoast("warning");
                        setmensajetoast(data.message);
                        setabrirToast(true);
                    }

                }).catch((error) => {

                    settitulotoast("Error");
                    settipotoast("error");
                    setmensajetoast(config.MessageErrorServer);
                    setabrirToast(true);
                });
            }
        }
        else {
            navigate("/login");
        }
    }
    const AbrirModalPermiso = async (registro) => {
        await setmostarModalPermisos(true);
        await setproyectoActual(registro);
        //buscar lista proyectos
        await BuscarListaPermisosProyecto(registro);
        //buscar lista Trabajadores Activos
        await BuscarListaTrabajadoresActivos().then();
        nombreproyectopermisoRef.current.value = registro.nombreproyecto;
        encargadoproyectopermisoRef.current.value = registro.responsableproyecto;
        nombretrabajadorpermisoRef.current.value = "";
    }
    const CerrarModalPermiso = () => {
        setmostarModalPermisos(false);
        setlistapermisosproyecto([]);
    }
    const BuscarTrabajadorListado = async (trabajadorelegido) => {
        for await (const trabajador of listatrabajadoresactivos) {
            if (trabajadorelegido.indexOf(trabajador.nombres) > -1) {
                return trabajador;
            }
        }
        return null;
    }
    const AgregarPermisoTrabajador = async () => {
        if (ValidarUsuario()) {
            if (nombretrabajadorpermisoRef.current.value.trim() !== "") {
                nombretrabajadorpermisoRef.current.classList.remove("is-invalid")
                //Buscar Trabajador en lista de Permisos
                let trabajadorencontrado = await BuscarTrabajadorListado(nombretrabajadorpermisoRef.current.value);
                if (trabajadorencontrado) {

                    let url = `${config.endPoint}api/permisoproyecto/agregar`;
                    let UsuarioStorage = ObtenerUsuario();
                    UsuarioStorage = JSON.parse(UsuarioStorage);

                    let nuevocentro = {
                        idproyecto: proyectoActual.id,
                        idtrabajador: trabajadorencontrado.id
                    }

                    let headers = {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + UsuarioStorage.token,
                        },
                        body: JSON.stringify(nuevocentro)
                    };

                    fetch(url, headers).then((respuesta) => {
                        return respuesta.json()
                    }).then((data) => {
                        if (data.content) {
                            BuscarListaPermisosProyecto(proyectoActual);
                            nombretrabajadorpermisoRef.current.value="";
                            settitulotoast("Correcto");
                            settipotoast("success");
                            setmensajetoast("Permiso agregado");
                            setabrirToast(true);
                            setmostrarModalDetalla(false);
                        }
                        else {
                            settitulotoast("Alerta");
                            settipotoast("warning");
                            setmensajetoast(data.message);
                            setabrirToast(true);
                        }
                    }).catch((error) => {
                        settitulotoast("Error");
                        settipotoast("error");
                        setmensajetoast(config.MessageErrorServer);
                        setabrirToast(true);
                    });

                } else {
                    nombretrabajadorpermisoRef.current.value = "";
                    settitulotoast("Alerta");
                    settipotoast("warning");
                    setmensajetoast("Trabajador no encontrado o no coincide, seleccione un trabajador de la lista.");
                    setabrirToast(true);
                }
            } else {
                nombretrabajadorpermisoRef.current.classList.add("is-invalid")
            }
        } else {
            navigate('/login');
        }
    }
    const EliminarPermisoProyecto=(fila)=>{
        if (ValidarUsuario()) {
            if (window.confirm(`¿Estas Seguro de eliminar el Trabajador ${fila.ta_trabajador.nombres}?`)) {

                let url = `${config.endPoint}api/permisoproyecto/eliminar`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let body_permisos = {
                    idpermisoProyecto: fila.id,
                }

                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify(body_permisos)
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        BuscarListaPermisosProyecto(proyectoActual);
                        settitulotoast("Correcto");
                        settipotoast("success");
                        setmensajetoast("Permiso fue eliminado correctamente.");
                        setabrirToast(true);
                    }
                    else {
                        settitulotoast("Alerta");
                        settipotoast("warning");
                        setmensajetoast(data.message);
                        setabrirToast(true);
                    }

                }).catch((error) => {
                    settitulotoast("Error");
                    settipotoast("error");
                    setmensajetoast(config.MessageErrorServer);
                    setabrirToast(true);
                });
            }
        }
        else {
            navigate("/login");
        }
    }
    const ModificarEstadoPermisoProyecto=(id, estado)=>{
        if (ValidarUsuario()) {
            let url = `${config.endPoint}api/permisoproyecto/modificar`;

            let body_permisos = {
                idpermisoProyecto: id,
                estado: estado
            }
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify(body_permisos)
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json()
            }).then((data) => {
                if (data.content) {
                    BuscarListaPermisosProyecto(proyectoActual);
                    settitulotoast("Correcto");
                    settipotoast("success");
                    setmensajetoast("Se actualizo Permiso.");
                    setabrirToast(true);                    
                }
                else {
                    settitulotoast("Alerta");
                    settipotoast("warning");
                    setmensajetoast(data.message);
                    setabrirToast(true);
                }
            }).catch((error) => {
                settitulotoast("Error");
                settipotoast("error");
                setmensajetoast(config.MessageErrorServer);
                setabrirToast(true);
            });
        } else {
            navigate("/login");
        }

    }
    const OnChangeFiltroproyecto = () => {
        if (filtroproyectoRef.current.value.trim() === '') {
            BuscarListasproyectos(filasporpagina, pagina);
        }
    }
    const cambiarPagina = async (event, npagina) => {
        await setpagina(parseInt(npagina) + 1);
        BuscarListasproyectos(filasporpagina, parseInt(npagina) + 1)
    }
    const cambiarfilasporpagina = async (ev) => {
        await setfilasporpagina(parseInt(ev.target.value));
        await setpagina(1);
        BuscarListasproyectos(parseInt(ev.target.value), 1)
    }

    return (
        <Fragment>
            <PaginaCarga loadcarga={loadcarga} />

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={abrirToast} autoHideDuration={4000} onClose={() => { setabrirToast(false) }}>
                <Alert onClose={() => { setabrirToast(false) }} severity={tipotoast}>
                    <AlertTitle>{titulotoast}</AlertTitle>
                    {mensajetoast}
                </Alert>
            </Snackbar>

            <Header />
            {/* titulo */}
            <div className="container mt-1" style={{ backgroundColor: 'rgb(25,118,210)' }}>
                <div className="container  ">
                    <div className="row py-1">
                        <div className="col-6 col-sm-6 col-md-8 ">
                            <i title="Regresar" className="fas fa-tag  iconotitulo " style={{ color: 'aliceblue' }}></i>
                            <span className="mx-1" style={{ color: 'aliceblue' }}>PROYECTOS</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container border rounded mt-1'>
                <Container fluid className='mt-1'>
                    <Row>
                        {/* <Col sx={12} md={3}>
                <div className=" mt-md-0">
                    <select onChange={() => { }} className="form-select form-select-sm" >
                        <option value="Todos">Todos</option>
                        <option value="Trabajador">Nombre</option>
                        <option value="Supervisor">Estado</option>                                    
                    </select>
                </div>
            </Col> */}

                        <Col sx={12} md={3} >
                            <Form.Control onChange={() => { OnChangeFiltroproyecto() }} ref={filtroproyectoRef} type="text" placeholder="Valor Buscar" size="sm" />

                        </Col>
                        <Col sx={12} md={3} className="mt-md-0 mt-1">
                            <Button onClick={() => { BuscarListasproyectos(filasporpagina, pagina) }} fullWidth size='small' variant='outlined'>Buscar</Button>
                        </Col>
                        <Col sx={12} md={6} className="text-end mt-md-0 mt-1 ms-auto">
                            <Button onClick={() => {
                                AbrirModalproyecto("NUEVO", null);
                            }} variant="outlined">
                                <AddIcon></AddIcon>
                                Agregar
                            </Button>
                        </Col>
                        {/* <Col sx={12} md={3} className="text-end ">

                <div className="dropdown">
                    <button className="btn btn-sm dropdown-toggle" type="button" id="ListaMenuAcciones" data-bs-toggle="dropdown" aria-expanded="false">
                        Opciones
                    </button>
                    <ul className="dropdown-menu dropdown-menu-light" aria-labelledby="ListaMenuAcciones">
                        <li onClick={() => { }}><small className="dropdown-item" ><i className="fas fa-calendar-check mx-2"></i>Reporte</small></li>
                        <li onClick={() => { }}><small className="dropdown-item" ><i className="fas fa-calendar-check mx-2"></i>Ver Ejecutados</small></li>
                        <li onClick={() => { }}><small className="dropdown-item" ><i className="fas fa-calendar-check mx-2"></i>Ver Faltantes</small></li>
                    </ul>
                </div>

            </Col> */}
                    </Row>
                </Container>

                {/* Tabla de datos */}
                <div className="row p-1">

                    <TableContainer style={{ maxHeight: window.outerHeight < 760 ? 430 : 650 }}>
                        <Table size='small' stickyHeader>
                            <TableHead>
                                <TableRow >
                                    <TableCell>Acciones</TableCell>
                                    <TableCell>Permisos</TableCell>
                                    <TableCell>Nombre proyecto</TableCell>
                                    <TableCell>Responsable</TableCell>
                                    <TableCell>Estado</TableCell>
                                    <TableCell>Fecha Registro</TableCell>
                                    <TableCell>Usuario crea</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    load ? listaproyectos.map((row, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    <IconButton onClick={() => { AbrirModalproyecto('VER', row) }} size="small">
                                                        <EditIcon fontSize="inherit" />
                                                    </IconButton>
                                                    <IconButton disabled onClick={() => { Eliminarproyecto(row) }} size="small">
                                                        <DeleteIcon fontSize="inherit" />
                                                    </IconButton>
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton onClick={() => { AbrirModalPermiso(row) }} size="small">
                                                        <AdminPanelSettingsIcon fontSize="inherit" />
                                                    </IconButton>
                                                </TableCell>
                                                <TableCell >{row.nombreproyecto}</TableCell>
                                                <TableCell >{row.responsableproyecto}</TableCell>
                                                <TableCell >{row.estado}</TableCell>
                                                <TableCell >{moment(row.fechacreacion).format('yyy-MM-DD hh:mm:ss')}</TableCell>
                                                <TableCell >{row.usuariocrea}</TableCell>
                                                {/* <TableCell >{row.fechamodifica?moment(row.fechamodifica).format('yyy-MM-DD hh:mm:ss'):''}</TableCell>
                                    <TableCell >{row.usuariomodifica}</TableCell> */}
                                            </TableRow>
                                        );
                                    })
                                        : <></>
                                }

                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 15, 25, 50]}
                        component="div"
                        count={cantidadElementos}
                        rowsPerPage={filasporpagina}
                        page={pagina - 1}
                        onPageChange={(ev, npagina) => { cambiarPagina(ev, npagina) }}
                        onRowsPerPageChange={(ev) => { cambiarfilasporpagina(ev) }}
                        labelRowsPerPage={"Filas Por Pagina"}
                    />
                </div>

            </div>

            {/* Modales  */}

            <Modal
                show={mostrarModalDetalla}
                onHide={() => { setmostrarModalDetalla(false) }}
                keyboard={false}
                size="md"
                centered >
                <Modal.Header>
                    <Modal.Title>
                        {`Proyecto [${accionactual}]`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Container>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Nombre Proyecto</small>
                                    <Form.Control disabled={controlesreadonly} ref={nombreproyectoRef} type="text" placeholder='Ingrese Nombre'></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Detalle Proyecto</small>
                                    <Form.Control disabled={controlesreadonly} as={'textarea'} rows={2} ref={descripcionproyectoRef} type="text" placeholder='Ingrese Descripción'></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Responsable Proyecto</small>
                                    <Form.Control disabled={controlesreadonly} ref={responsableproyectoRef} type="text" placeholder='Ingrese Responsable'></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Codigo</small>
                                    <Form.Control disabled={controlesreadonly} ref={codigoproyectoRef} type="text" placeholder='Ingrese Codigo'></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row> */}
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Estado</small>
                                    <select disabled={controlesreadonly} ref={estadoproyectoRef} className="form-select" defaultValue="">
                                        <option value="" disabled>Seleccione el Estado</option>
                                        <option value="ACTIVO">ACTIVO</option>
                                        <option value="INACTIVO">INACTIVO</option>
                                    </select>
                                </Form.Group>

                            </Col>
                        </Row>
                        {/* <Row>
                <Col md={6}>
                    <small className="text-primary">Usuario Crea</small>
                    <Form.Control disabled value="Admin"></Form.Control>
                </Col>
                <Col md={6}>
                    <small className="text-primary">Fecha Crea</small>
                    <Form.Control disabled type="date"></Form.Control>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <small className="text-primary">Usuario Modifica</small>
                    <Form.Control disabled value="Admin"></Form.Control>
                </Col>
                <Col md={6}>
                    <small className="text-primary">Fecha Modifica</small>
                    <Form.Control disabled type="date"></Form.Control>
                </Col>
            </Row> */}
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <StackMU spacing={'auto'} direction='row' justifyContent={'end'} sx={{ mt: 2 }}>
                            <Button variant="outlined" onClick={() => { setmostrarModalDetalla(false) }}>Cancelar</Button>
                            {
                                controlesreadonly ?
                                    <Button variant="outlined" onClick={() => { setcontrolesreadonly(!controlesreadonly) }}>Modificar</Button>
                                    : <></>
                            }
                            {
                                !controlesreadonly ?
                                    <Button onClick={() => { Guardarproyecto() }} variant="outlined">Guardar</Button>
                                    : <></>
                            }
                        </StackMU>
                    </Container>
                </Modal.Footer>
            </Modal>

            {/* Modal Agregar Permisos */}
            <Modal
                show={mostarModalPermisos}
                onHide={() => { CerrarModalPermiso() }}
                keyboard={false}
                centered
            >
                <Modal.Header>
                    <Modal.Title>Permisos</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Container>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Nombre Proyecto</small>
                                    <Form.Control ref={nombreproyectopermisoRef} disabled type="text" placeholder='Nombre' ></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Encargado</small>
                                    <Form.Control ref={encargadoproyectopermisoRef} disabled type="text" placeholder='Nombre' ></Form.Control>
                                </Form.Group>

                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <small className="text-primary">Trabajador</small>
                                <Stack direction='horizontal' gap={2}>
                                    <input ref={nombretrabajadorpermisoRef} className="form-control" list="listaencargados" id="encargadoproyecto" placeholder="Escoja el trabajador" />
                                    <datalist id="listaencargados">
                                        {
                                            listatrabajadoresactivos.map((row, index) => {
                                                return (
                                                    <option key={index} value={row.nombres} />
                                                )
                                            })
                                        }
                                    </datalist>
                                    <Button onClick={() => { AgregarPermisoTrabajador() }} variant='outlined'>Agregar</Button>
                                </Stack>
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col>
                                <small className="text-primary">Estado</small>
                                <select className="form-select"  defaultValue="">
                                    <option value="" disabled>Seleccione Estado</option>
                                    <option value="1">VISUALIZAR</option>
                                    <option value="2">MODIFICAR</option>
                                </select>
                            </Col>
                        </Row> */}
                    </Container>
                    {/* Tabla */}
                    <TableContainer style={{ maxHeight: '300px' }}>
                        <Table size='small' stickyHeader>
                            <TableHead>
                                <TableRow >
                                    <TableCell>Acciones</TableCell>
                                    <TableCell>Trabajador</TableCell>
                                    {/* <TableCell>Permiso</TableCell> */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    listapermisosproyecto.map((row, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    <Container>
                                                        <Row>
                                                            <Col >
                                                                <IconButton onClick={() => {EliminarPermisoProyecto(row) }} size="small">
                                                                    <DeleteForeverOutlinedIcon fontSize="inherit" />
                                                                </IconButton>
                                                            </Col>
                                                            {/* <Col >
                                                                <Form.Check
                                                                    type="switch"
                                                                />
                                                            </Col> */}
                                                            <div className=" form-check form-switch " style={{ width: '10px' }}>
                                                                {
                                                                    (row.estado === 'ACTIVO') ?
                                                                        <input onClick={() => { ModificarEstadoPermisoProyecto(row.id, "INACTIVO") }} className="form-check-input" type="checkbox" defaultChecked />
                                                                        :
                                                                        <input onClick={() => { ModificarEstadoPermisoProyecto(row.id, "ACTIVO") }} className="form-check-input" type="checkbox" />
                                                                }
                                                            </div>
                                                        </Row>
                                                    </Container>
                                                </TableCell>
                                                <TableCell>{row.ta_trabajador.nombres}</TableCell>
                                                {/* <TableCell>MODIFICAR</TableCell> */}
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Container>
                        <Row>
                            <Col>
                                <Form.Text>3 Elementos</Form.Text>
                            </Col>
                        </Row>
                    </Container>

                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row>
                            <Col className='text-end'>
                                <Button onClick={() => { CerrarModalPermiso() }} variant="outlined">Cerrar</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>

            </Modal>

        </Fragment>
    );
}