import React, { Component } from 'react';

class estadisticas extends Component {
    render() {
        return (
            <div>
                estadisticas
            </div>
        );
    }
}
export default estadisticas;