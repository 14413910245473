import React, { Fragment, useEffect, useState } from 'react';
import Header from './header';
import { Container, Row, Col, Card, Form, Modal, Stack } from 'react-bootstrap';
import { InputLabel, Grid, Box, Button, IconButton, Alert, AlertTitle, Snackbar } from '@mui/material';
import StackMU from '@mui/material/Stack';
// Material UI
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SettingsIcon from '@mui/icons-material/Settings';
import EngineeringIcon from '@mui/icons-material/Engineering';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AutorenewIcon from '@mui/icons-material/Autorenew';

import { Chart as ChartJS, registerables } from 'chart.js';
import { Bar, Line, PolarArea, Pie } from 'react-chartjs-2';
import { useNavigate, useParams } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ValidarUsuario, listarPermisos, ValidarPermisos, LIST_SCREENS, config, ObtenerUsuario,CreateJsonToExcel } from '../utils/utils';

import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';

import { DataGrid } from '@mui/x-data-grid';

export default function Dashboard() {
    const navigate = useNavigate();
    let { idproyecto } = useParams();

    ChartJS.register(...registerables);

    const [abrirToast, setabrirToast] = useState(false);
    const [titulotoast, settitulotoast] = useState("");
    const [mensajetoast, setmensajetoast] = useState("");
    const [tipotoast, settipotoast] = useState("error");
    const [listapermisos, setlistapermisos] = useState([]);
    const [datacajachica, setdatacajachica] = useState(null);
    const [dataregistrobanco, setdataregistrobanco] = useState(null);
    const [listacodigogastoactivo, setlistacodigogastoactivo] = useState(null);
    const [mostrarmodalverresumen, setmostrarmodalverresumen] = useState(false);
    const [resumenproyecto, setresumenproyecto] = useState(null);
    const [columasresumen, setcolumasresumen] = useState(null);
    const [resumencantidaesProyecto,setresumencantidaesProyecto]=useState(null);

    useEffect(() => {
        ValidarAccesos();
    }, [listacodigogastoactivo])

    const ValidarAccesos = () => {
        if (ValidarUsuario()) {
            listarPermisos().then((permisos) => {
                setlistapermisos(permisos);
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_HOME, permisos)) {
                    BuscarResumenEstadisticas();
                } else {
                    settitulotoast("Alerta");
                    settipotoast("warning");
                    setmensajetoast("No tiene permisos para acceder a este Recurso.");
                    setabrirToast(true);
                    setTimeout(() => {
                        navigate('/');
                    }, 1000);
                }
            });
        } else {
            navigate('/login');
        }
    }
    const BuscarResumenEstadisticas = () => {
        BuscarEstadisticasCajaChica();
        BuscarEstadisticasRegistroBanco();
    }
    const BuscarEstadisticasCajaChica = () => {
        if (ValidarUsuario()) {
            let url = `${config.endPoint}api/cajachica/estadistica/${idproyecto}/${5}`;

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data.content) {
                    //setestadisticacajachica(data.content);
                    ProcesarEstadisticasCajaChica(data.content);
                }
            }).catch((error) => {
                settitulotoast("Alerta");
                settipotoast("warning");
                setmensajetoast(config.MessageErrorServer);
                setabrirToast(true);
            });
        } else {
            navigate("/login");
        }
    }
    const ProcesarEstadisticasCajaChica = (dataresumen) => {
        let arrayIngresoCajaChica = [];
        let arrayEgresoCajaChica = [];
        let arrayResumenCajaChica = [];
        let arrayLabelsCajaChica = [];
        for (let x = 0; x < dataresumen.length; x++) {
            // console.log(dataresumen[x]);
            arrayLabelsCajaChica.push(dataresumen[x].periodo ? dataresumen[x].periodo : 0);
            arrayIngresoCajaChica.push(dataresumen[x].ingreso ? dataresumen[x].ingreso : 0);
            arrayEgresoCajaChica.push(dataresumen[x].egreso ? dataresumen[x].egreso : 0);
            arrayResumenCajaChica.push(dataresumen[x].resumen ? dataresumen[x].resumen : 0);
        }

        let data = {
            // labels: ['RECLAMOS', 'NUEVOS SUMINISTROS', 'MANTENIMINETOS', 'AP Y TOTALIZADRORES', 'CONTRASTES', 'ZONA PEDREGAL', 'ZONAL CAMANA'],
            labels: arrayLabelsCajaChica,
            datasets: [
                {
                    type: 'bar',
                    label: 'Ingreso',
                    backgroundColor: 'rgba(54, 162, 235,0.3)',
                    borderColor: 'rgba(54, 162, 235,0.9)',
                    borderWidth: 2,
                    fill: false,
                    data: arrayIngresoCajaChica
                    // data: [85, 66, 45, 29, 37, 115, 96],
                },
                {
                    type: 'bar',
                    label: 'Egreso',
                    backgroundColor: 'rgba(255, 99, 132,0.3)',
                    borderColor: 'rgba(255, 99, 132)',
                    borderWidth: 2,
                    data: arrayEgresoCajaChica
                    // data: [86, 60, 20, 20, 37, 115, 83],
                    // borderColor: 'white',
                },
                {
                    type: 'bar',
                    label: 'Resumen',
                    backgroundColor: 'rgba(75, 192, 192,0.3)',
                    borderColor: 'rgba(75, 192, 192,0.9)',
                    borderDash: [5, 5],
                    borderWidth: 2,
                    // data: [1, 6, 15, 9, 0, 0, 13],
                    data: arrayResumenCajaChica
                },
            ],
        };
        setdatacajachica(data);
    }
    const BuscarEstadisticasRegistroBanco = () => {
        if (ValidarUsuario()) {
            let url = `${config.endPoint}api/registrobanco/estadistica/${idproyecto}/${5}`;

            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data.content) {
                    //setestadisticacajachica(data.content);
                    ProcesarEstadisticasRegistroBanco(data.content);
                }
            }).catch((error) => {
                settitulotoast("Alerta");
                settipotoast("warning");
                setmensajetoast(config.MessageErrorServer);
                setabrirToast(true);
            });
        } else {
            navigate("/login");
        }
    }
    const ProcesarEstadisticasRegistroBanco = (dataresumen) => {
        let arrayIngresoCajaChica = [];
        let arrayEgresoCajaChica = [];
        let arrayResumenCajaChica = [];
        let arrayLabelsCajaChica = [];
        for (let x = 0; x < dataresumen.length; x++) {
            // console.log(dataresumen[x]);
            arrayLabelsCajaChica.push(dataresumen[x].periodo ? dataresumen[x].periodo : 0);
            arrayIngresoCajaChica.push(dataresumen[x].ingreso ? dataresumen[x].ingreso : 0);
            arrayEgresoCajaChica.push(dataresumen[x].egreso ? dataresumen[x].egreso * -1 : 0);
            arrayResumenCajaChica.push(dataresumen[x].resumen ? dataresumen[x].resumen : 0);
        }

        let data = {
            // labels: ['RECLAMOS', 'NUEVOS SUMINISTROS', 'MANTENIMINETOS', 'AP Y TOTALIZADRORES', 'CONTRASTES', 'ZONA PEDREGAL', 'ZONAL CAMANA'],
            labels: arrayLabelsCajaChica,
            datasets: [
                {
                    type: 'line',
                    label: 'Ingreso',
                    backgroundColor: 'rgba(54, 162, 235,0.3)',
                    borderColor: 'rgba(54, 162, 235,0.9)',
                    borderWidth: 2,
                    fill: false,
                    data: arrayIngresoCajaChica
                    // data: [85, 66, 45, 29, 37, 115, 96],
                },
                {
                    type: 'line',
                    label: 'Egreso',
                    backgroundColor: 'rgba(255, 99, 132,0.3)',
                    borderColor: 'rgba(255, 99, 132)',
                    borderWidth: 2,
                    fill: false,
                    data: arrayEgresoCajaChica
                    // data: [86, 60, 20, 20, 37, 115, 83],
                    // borderColor: 'white',
                },
                {
                    type: 'line',
                    label: 'Resumen',
                    backgroundColor: 'rgba(75, 192, 192,0.3)',
                    borderColor: 'rgba(75, 192, 192,0.9)',
                    borderDash: [5, 5],
                    borderWidth: 2,
                    // data: [1, 6, 15, 9, 0, 0, 13],
                    data: arrayResumenCajaChica
                },
            ],
        };
        setdataregistrobanco(data);
    }
    const BuscarListaCodigosGastoActivos = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/codigogasto/listartodos`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        setlistacodigogastoactivo(data.content);
                        resolve(data.content);
                    }
                    else {
                        setlistacodigogastoactivo([]);
                        settitulotoast("Alerta");
                        settipotoast("warning");
                        setmensajetoast(data.message);
                        setabrirToast(true);
                        resolve(null);
                    }
                }).catch(() => {
                    setlistacodigogastoactivo([]);
                    settitulotoast("Error");
                    settipotoast("error");
                    setmensajetoast(config.MessageErrorServer);
                    setabrirToast(true);
                    resolve(null);
                });
            }
            else {
                navigate("/login")
                reject(true)
            }
        });
    }
    const BuscarResumenCajaChicaProyecto = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/resumen/cajachica/${idproyecto}`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        // console.log(data.content);
                        //setresumecajachicaproyecto(data.content);
                        resolve(data.content);
                    }
                    else {
                        //setresumecajachicaproyecto([]);
                        settitulotoast("Alerta");
                        settipotoast("warning");
                        setmensajetoast(data.message);
                        setabrirToast(true);
                        resolve(null);
                    }
                }).catch(() => {
                    //setresumecajachicaproyecto([]);
                    settitulotoast("Error");
                    settipotoast("error");
                    setmensajetoast(config.MessageErrorServer);
                    setabrirToast(true);
                    resolve(null);
                });
            }
            else {
                navigate("/login")
                reject(true)
            }
        });
    }
    const BuscarResumenRegistroBancoProyecto = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/resumen/registrobanco/${idproyecto}`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        // console.log(data.content);
                        // setresumeregistrobancoproyecto(data.content);
                        resolve(data.content);
                    }
                    else {
                        // setresumeregistrobancoproyecto([]);
                        settitulotoast("Alerta");
                        settipotoast("warning");
                        setmensajetoast(data.message);
                        setabrirToast(true);
                        resolve(null);
                    }
                }).catch(() => {
                    // setresumeregistrobancoproyecto([]);
                    settitulotoast("Error");
                    settipotoast("error");
                    setmensajetoast(config.MessageErrorServer);
                    setabrirToast(true);
                    resolve(null);
                });
            }
            else {
                navigate("/login")
                reject(true)
            }
        });
    }
    const BuscarCantidadesGeneralProyectoPorCodigos = () => {
        let url = `${config.endPoint}api/resumen/cantidades/${idproyecto}`;
        let UsuarioStorage = ObtenerUsuario();
        UsuarioStorage = JSON.parse(UsuarioStorage);
        let headers = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + UsuarioStorage.token,
            },
        };

        fetch(url, headers).then((respuesta) => {
            return respuesta.json();
        }).then(async (data) => {
            if (data.content) {                                                
                let _resumenProyecto={};
                if(data.content.Ingreso){
                    _resumenProyecto.Ingreso=data.content.Ingreso;
                }else{
                    _resumenProyecto.Ingreso=0;
                }
                let _resumencajachica=0;
                let _resumenregistrobanco=0;
                if(data.content.EgresoBanco){
                    _resumenregistrobanco=data.content.EgresoBanco;
                }

                if(data.content.EgresoCajachica){
                    _resumencajachica=data.content.EgresoCajachica;
                }
                _resumenProyecto.Egreso=_resumenregistrobanco+_resumencajachica;
                _resumenProyecto.Saldo=_resumenProyecto.Ingreso+_resumenProyecto.Egreso;                
                setresumencantidaesProyecto(_resumenProyecto);
            } else {
                setresumencantidaesProyecto(null);
            }
        }).catch((error) => {
            setresumencantidaesProyecto(null);
            settitulotoast("Alerta");
            settipotoast("warning");
            setmensajetoast(config.MessageErrorServer);
            setabrirToast(true);
        });
    }
    const BuscarResumenGeneralProyecto = async () => {
        let _listacodigos;
        let _resumencajachica;
        let _resumenregistrobanco;
        let _listaTitulos = [];
        await BuscarListaCodigosGastoActivos().then((respuesta) => {
            _listacodigos = respuesta;
        });
        await BuscarResumenCajaChicaProyecto().then((respuesta) => {
            _resumencajachica = respuesta;
        });
        await BuscarResumenRegistroBancoProyecto().then((respuesta) => {
            _resumenregistrobanco = respuesta;
        });
        await BuscarCantidadesGeneralProyectoPorCodigos();
        if (_listacodigos && _resumencajachica && _resumenregistrobanco) {
            //Obtener Nombres de Periodo
            for (const value in _resumencajachica) {
                //Buscar Si Existe 
                let _buscarComponente = _listaTitulos.find(x => x === _resumencajachica[value].periodo);

                if (_buscarComponente) {
                } else {
                    _listaTitulos.push(_resumencajachica[value].periodo);
                }
            }
            //Obtener Nombres de RegistroBanco            
            for (const value in _resumenregistrobanco) {
                //Buscar Si Existe                 
                let _buscarComponente = _listaTitulos.find(x => x === _resumenregistrobanco[value].periodo);
                if (_buscarComponente) {

                } else {
                    _listaTitulos.push(_resumenregistrobanco[value].periodo);
                }
            }
            _listaTitulos.sort();
            // console.log(_listacodigos);      
            // console.log(_resumencajachica);
            // console.log(_resumenregistrobanco);
            let _arrayResumen = [];
            for (const value in _listacodigos) {
                let _resumen = {};
                let _acumulado = 0;
                _resumen.id = value;
                _resumen.codigo = _listacodigos[value].codigo;
                for (const _titulo in _listaTitulos) {
                    //Buscar Elemento                    
                    let _cajachicaEncontrado = _resumencajachica.find(x => x.codigogasto === _listacodigos[value].codigo && x.periodo === _listaTitulos[_titulo]);
                    let _registroBancoEncontrado = _resumenregistrobanco.find(x => x.codigogasto === _listacodigos[value].codigo && x.periodo === _listaTitulos[_titulo]);

                    let _cantCajachica = 0;
                    let _cantRegistroBanco = 0;
                    //Acumular Caja Chica
                    if (_cajachicaEncontrado) {
                        _cantCajachica = _cajachicaEncontrado.total
                    }
                    //Acumular Registro Banco
                    if (_registroBancoEncontrado) {
                        _cantRegistroBanco = _registroBancoEncontrado.total
                    }
                    _resumen[_listaTitulos[_titulo]] = _cantCajachica + _cantRegistroBanco;
                    _acumulado = _acumulado + _cantCajachica + _cantRegistroBanco;
                }
                _resumen.total = _acumulado;
                _arrayResumen.push(_resumen);
            }
            //Columnas Proyecto
            let titulocolumnas = [];
            titulocolumnas.push({ field: 'codigo' });
            for (const _titulo in _listaTitulos) {
                titulocolumnas.push({ field: _listaTitulos[_titulo] });
            }
            titulocolumnas.push({ field: 'total' });

            setcolumasresumen(titulocolumnas);
            setresumenproyecto(_arrayResumen);
            setmostrarmodalverresumen(true);
        } else {
            // setresumeregistrobancoproyecto([]);
            settitulotoast("Error");
            settipotoast("error");
            setmensajetoast("Ocurrio un error al ejecutar esta accion.");
            setabrirToast(true);
        }
    }
    const MostrarModalMostrarResumenProyecto = () => {
        BuscarResumenGeneralProyecto();
    }
    const DescargarArchivo=async()=>{
        await CreateJsonToExcel(resumenproyecto, 'Resumen_');
    }
    return (
        <Fragment>
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={abrirToast} autoHideDuration={4000} onClose={() => { setabrirToast(false) }}>
                <Alert onClose={() => { setabrirToast(false) }} severity={tipotoast}>
                    <AlertTitle>{titulotoast}</AlertTitle>
                    {mensajetoast}
                </Alert>
            </Snackbar>

            <Header />
            {/* titulo */}
            <div className="container mt-1" style={{ backgroundColor: 'rgb(25,118,210)' }}>
                <div className="container  ">
                    <div className="row py-1">
                        <StackMU direction='row'>
                            <div className="col-6 col-sm-6 col-md-8 ">
                                <i title="Regresar" className="fas fa-tag  iconotitulo " style={{ color: 'aliceblue' }}></i>
                                <span className="mx-1" style={{ color: 'aliceblue' }}>DASHBOARD</span>
                            </div>
                            <div className='text-end ms-auto'>
                                <IconButton onClick={() => { navigate('/') }} style={{ color: 'white' }} size='small'>
                                    <ArrowBackIcon fontSize='inherit' />
                                </IconButton>
                            </div>
                        </StackMU>
                    </div>
                </div>
            </div>
            {/* contenido */}
            <Container className='mt-1'>
                <Row >
                    <Col>
                        <Button onClick={() => { BuscarEstadisticasCajaChica() }} variant="text" startIcon={<AutorenewIcon />}>
                            Actualizar
                        </Button>
                    </Col>
                    <Col sx={12} md={3} className='text-end'>
                        <div className="dropdown">
                            <button className="btn btn-sm dropdown-toggle" type="button" id="ListaMenuAcciones" data-bs-toggle="dropdown" aria-expanded="false">
                                Opciones
                            </button>
                            <ul className="dropdown-menu dropdown-menu-light" aria-labelledby="ListaMenuAcciones">
                                <li onClick={() => { MostrarModalMostrarResumenProyecto() }}><small className="dropdown-item" ><ContentPasteGoIcon /> Ver Resumen Caja Chica/Bancos</small></li>
                                {/* <li onClick={() => { }}><small className="dropdown-item" ><InsertInvitationIcon /> Reporte por Fecha</small></li> */}
                                <li><hr className="dropdown-divider" /></li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Container >
                <Row className='mb-4'>
                    {
                        datacajachica ?
                            <Col xs={12} md={6}>
                                <div className='text-center fst-normal text-secondary fs-4 '>CAJA CHICA</div>
                                <Bar data={datacajachica} />
                            </Col>
                            : <></>
                    }
                    {
                        dataregistrobanco ?
                            <Col xs={12} md={6}>
                                <div className='text-center fst-normal text-secondary fs-4 '>REGISTRO BANCO</div>
                                <Line data={dataregistrobanco} />
                            </Col>
                            : <></>
                    }
                </Row>
                {/* <Row>
                    <Col xs={6}>
                        <PolarArea data={data} />
                    </Col>
                    <Col xs={6}>
                        <Pie data={data} />
                    </Col>
                </Row> */}

                <Card >
                    <Card.Header>Accesos Rapidos</Card.Header>
                    <Card.Body>
                        <Grid container>
                            {
                                ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CAJACHICA, listapermisos) ?
                                    <Grid item>
                                        <Box
                                            sx={{
                                                bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                                                color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                                                border: '1px solid',
                                                borderColor: (theme) =>
                                                    theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                                                p: 1,
                                                m: 1,
                                                borderRadius: 2,
                                                fontSize: '0.875rem',
                                                fontWeight: '700'
                                            }}
                                            display='flex'
                                            justifyContent="center"
                                        >
                                            <Button onClick={() => { navigate(`/cajachica/${idproyecto}`) }} variant="text" startIcon={<MonetizationOnIcon />}>
                                                Caja Chica
                                            </Button>
                                        </Box>
                                    </Grid>
                                    : <></>
                            }
                            {
                                ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_BANCOS, listapermisos) ?
                                    <Grid item>
                                        <Box
                                            sx={{
                                                bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                                                color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                                                border: '1px solid',
                                                borderColor: (theme) =>
                                                    theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                                                p: 1,
                                                m: 1,
                                                borderRadius: 2,
                                                fontSize: '0.875rem',
                                                fontWeight: '700',
                                            }}
                                            display='flex'
                                            justifyContent="center"
                                        >
                                            <Button onClick={() => { navigate(`/bancos/${idproyecto}`) }} variant="text" startIcon={<AccountBalanceIcon />}>
                                                Bancos
                                            </Button>
                                        </Box>

                                    </Grid>
                                    : <></>
                            }
                            {/* <Grid item>
                                <Box
                                    sx={{
                                        bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                                        color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                                        border: '1px solid',
                                        borderColor: (theme) =>
                                            theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                                        p: 1,
                                        m: 1,
                                        borderRadius: 2,
                                        fontSize: '0.875rem',
                                        fontWeight: '700',
                                    }}
                                    display='flex'
                                    justifyContent="center"
                                >
                                    <Button variant="text" startIcon={<SettingsIcon />}>
                                        Configuración
                                    </Button>
                                </Box>

                            </Grid>
                            <Grid item>
                                <Box
                                    sx={{
                                        bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                                        color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                                        border: '1px solid',
                                        borderColor: (theme) =>
                                            theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                                        p: 1,
                                        m: 1,
                                        borderRadius: 2,
                                        fontSize: '0.875rem',
                                        fontWeight: '700',
                                    }}
                                    display='flex'
                                    justifyContent="center"
                                >
                                    <Button onClick={() => { navigate('/configurar/proyectos') }} variant="text" startIcon={<EngineeringIcon />}>
                                        Proyectos
                                    </Button>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box
                                    sx={{
                                        bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                                        color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                                        border: '1px solid',
                                        borderColor: (theme) =>
                                            theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                                        p: 1,
                                        m: 1,
                                        borderRadius: 2,
                                        fontSize: '0.875rem',
                                        fontWeight: '700',
                                    }}
                                    display='flex'
                                    justifyContent="center"
                                >
                                    <Button onClick={() => { navigate('/configurar/proveedores') }} variant="text" startIcon={<AssignmentIndIcon />}>
                                        Proveedores
                                    </Button>
                                </Box>
                            </Grid> */}
                        </Grid>
                    </Card.Body>
                </Card>
            </Container>


            {/* Modal Buscar Proveedor */}
            <Modal
                show={mostrarmodalverresumen}
                onHide={() => { setmostrarmodalverresumen(false) }}
                keyboard={false}
                centered
                size='lg'
            >
                <Modal.Body style={{ border: 'solid 2px rgb(48,132,215)', borderRadius: '10px' }}>
                    <h5>Resumen</h5>
                    <hr />
                    <Container>
                        <Row>
                            <Col className='text-end'>
                                <Button onClick={()=>{DescargarArchivo()}} endIcon={<SimCardDownloadIcon/>}>
                                    Descargar xls
                                </Button>                            
                            </Col>
                        </Row>
                        <Row>
                            {
                                resumencantidaesProyecto ?
                                    <Row className=''>
                                        <Col sm={12} md={4} className='mt-1'>
                                            <div className='text-center border rounded border-success'>
                                                <small>{`Total Ingresos: S/. ${resumencantidaesProyecto.Ingreso ? Math.round((resumencantidaesProyecto.Ingreso + Number.EPSILON) * 100) / 100 : 0}`}</small>
                                            </div>
                                        </Col>
                                        <Col sm={12} md={4} className='mt-1'>
                                            <div className='text-center border rounded border-danger'>
                                                <small>{`Total Egresos: S/. ${resumencantidaesProyecto.Egreso ? Math.round((resumencantidaesProyecto.Egreso + Number.EPSILON) * 100) / 100 : 0}`}</small>
                                            </div>
                                        </Col>
                                        <Col sm={12} md={4} className='mt-1'>
                                            <div className='text-center border rounded '>
                                                <small>{`Saldo: S/. ${resumencantidaesProyecto.Saldo ? Math.round((resumencantidaesProyecto.Saldo + Number.EPSILON) * 100) / 100 : 0}`}</small>
                                            </div>
                                        </Col>
                                    </Row>
                                    : <></>
                            }
                        </Row>
                        <hr/>
                        {
                            resumenproyecto && columasresumen ?
                                <>
                                    <div style={{ width: "100%", height: "70vh" }}>
                                        <DataGrid
                                            rows={resumenproyecto}
                                            columns={columasresumen}
                                            getRowId={(row) => row.id}
                                            density='compact'
                                            pagination
                                        />
                                    </div>
                                    <Row>
                                        <Col>
                                            <Form.Text>{`${resumenproyecto.length} Elemento(s)`}</Form.Text>
                                        </Col>
                                    </Row>
                                </>
                                : <></>
                        }
                        <hr></hr>
                        <StackMU spacing={'auto'} direction='row' justifyContent={'end'} sx={{ mt: 2 }}>
                            <Button onClick={() => { setmostrarmodalverresumen(false) }} variant="outlined">Cerrar</Button>
                        </StackMU>
                    </Container>
                </Modal.Body>
            </Modal>
        </Fragment>
    );

}