import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import InputLabel from '@mui/material/InputLabel';


import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import HomeIcon from '@mui/icons-material/Home';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import RuleIcon from '@mui/icons-material/Rule';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import TvIcon from '@mui/icons-material/Tv';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import ListAltIcon from '@mui/icons-material/ListAlt';

import { BorrarStorage, config, ObtenerUsuario, listarPermisos, LIST_SCREENS, ValidarPermisos } from '../utils/utils'

export default function Header() {
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [state, setState] = React.useState(false);
    const [usuario, setusuario] = React.useState("");
    const [listapermisos, setlistapermisos] = React.useState([]);

    React.useEffect(() => {
        BuscarUsuario();
    }, []);

    const BuscarUsuario = () => {
        listarPermisos().then((permisos) => {
            setlistapermisos(permisos);

        });
        let UsuarioStorage = ObtenerUsuario();
        if (UsuarioStorage) {
            UsuarioStorage = JSON.parse(UsuarioStorage);
            setusuario(UsuarioStorage.nombretrabajador);
        }
    }
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setState(open);
    };
    const CerrarSesion = () => {
        BorrarStorage(config.storageName);
        navigate('/login');
    }
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onClick={toggleDrawer('left', true)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Sistema Caja
                    </Typography>
                    {(
                        <div>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}
                                color="inherit"
                            >
                                <AccountCircle />
                                <InputLabel style={{ color: 'white' }} className='mx-1'>{usuario}</InputLabel>
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                {/* <MenuItem onClick={handleClose}>Perfil</MenuItem> */}
                                <MenuItem onClick={() => { CerrarSesion() }}>Cerrar Sesión</MenuItem>
                            </Menu>

                            {/* Drawer*/}
                            <SwipeableDrawer
                                anchor='left'
                                open={state}
                                onClose={toggleDrawer('left', false)}
                                onOpen={toggleDrawer('left', true)}
                            >
                                <Box
                                    sx={{ width: 250 }}
                                    role="presentation"
                                    onClick={toggleDrawer('left', false)}
                                    onKeyDown={toggleDrawer('left', false)}
                                >
                                    <Divider />
                                    <InputLabel color={'primary'} className='mx-1'>
                                        Inicio
                                    </InputLabel>
                                    <List>
                                        {
                                            ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_HOME, listapermisos) ?
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => { navigate('/') }}>
                                                        <ListItemIcon>
                                                            <HomeIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary='Inicio' />
                                                    </ListItemButton>
                                                </ListItem>
                                                : <></>
                                        }
                                        {
                                            ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CAJACHICA, listapermisos) ?
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => { navigate('/') }}>
                                                        <ListItemIcon>
                                                            <LocalAtmIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary='Caja Chica' />
                                                    </ListItemButton>
                                                </ListItem>
                                                : <></>
                                        }
                                        {
                                            ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_BANCOS, listapermisos) ?
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => { navigate('/') }}>
                                                        <ListItemIcon>
                                                            <AccountBalanceIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary='Bancos' />
                                                    </ListItemButton>
                                                </ListItem>
                                                : <></>
                                        }
                                    </List>
                                    {
                                        ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CONFIGURACION, listapermisos) ?
                                            <>
                                                <Divider />
                                                <InputLabel color={'primary'} className='mx-1'>
                                                    Configuración
                                                </InputLabel>
                                            </>
                                            : <></>
                                    }
                                    <List>
                                        {
                                            ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CONFIG_PROYECTOS, listapermisos) ?
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => { navigate('/configurar/proyectos') }}>
                                                        <ListItemIcon>
                                                            <AccountTreeIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Proyectos" />
                                                    </ListItemButton>
                                                </ListItem>
                                                : <></>
                                        }
                                        {
                                            ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CONFIG_PROVEEDORES, listapermisos) ?
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => { navigate('/configurar/proveedores') }}>
                                                        <ListItemIcon>
                                                            <Inventory2Icon />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Proveedores" />
                                                    </ListItemButton>
                                                </ListItem>
                                                : <></>
                                        }
                                        {
                                            ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CONFIG_DESCRIPCIONGASTOS, listapermisos) ?
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => { navigate('/configurar/detallegasto') }}>
                                                        <ListItemIcon>
                                                            <PriceCheckIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Descripcion de Gastos" />
                                                    </ListItemButton>
                                                </ListItem>
                                                : <></>
                                        }
                                        {
                                            ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CONFIG_AREAS, listapermisos) ?
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => { navigate('/configurar/area') }}>
                                                        <ListItemIcon>
                                                            <RuleIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Areas" />
                                                    </ListItemButton>
                                                </ListItem>
                                                : <></>
                                        }
                                        {
                                            ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CONFIG_CENTROCOSTO, listapermisos) ?
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => { navigate('/configurar/centrocosto') }}>
                                                        <ListItemIcon>
                                                            <CurrencyExchangeIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Centros de Gasto" />
                                                    </ListItemButton>
                                                </ListItem>
                                                : <></>
                                        }
                                        {
                                            ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CONFIG_CENTROCOSTO, listapermisos) ?
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => { navigate('/configurar/codigos') }}>
                                                        <ListItemIcon>
                                                            <ListAltIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Codigos" />                                                        
                                                    </ListItemButton>
                                                </ListItem>
                                                : <></>
                                        }
                                        {
                                            ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_PERMISOS, listapermisos) ?
                                                <>
                                                    <Divider />
                                                    <InputLabel color={'primary'} className='mx-1'>
                                                        Permisos
                                                    </InputLabel>
                                                </>
                                                : <></>
                                        }
                                        {
                                            ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_PERMISOS_TRABAJADOR, listapermisos) ?
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => { navigate('/configurar/trabajador') }}>
                                                        <ListItemIcon>
                                                            <SupervisorAccountIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Trabajadores / Usuarios" />
                                                    </ListItemButton>
                                                </ListItem>
                                                : <></>
                                        }
                                        {
                                            ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CONFIG_CODIGOS_GASTO, listapermisos) ?
                                                <ListItem disablePadding>
                                                    <ListItemButton onClick={() => { navigate('/configurar/vistas') }}>
                                                        <ListItemIcon>
                                                            <TvIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Vistas / Accesos" />
                                                    </ListItemButton>
                                                </ListItem>
                                                : <></>
                                        }
                                        {/* <ListItem disablePadding>
                                            <ListItemButton>
                                                <ListItemIcon>
                                                    <HowToRegIcon />
                                                </ListItemIcon>
                                                <ListItemText primary="Permisos" />
                                            </ListItemButton>
                                        </ListItem> */}



                                        {/* {['Personal', 'Clientes', 'Tipo Gasto', 'Areas', 'Centros de Costo'].map((text, index) => (
                                            <ListItem key={text} disablePadding>
                                                <ListItemButton>
                                                    <ListItemIcon>
                                                        {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                                                    </ListItemIcon>
                                                    <ListItemText primary={text} />
                                                </ListItemButton>
                                            </ListItem>
                                        ))} */}
                                    </List>
                                </Box>
                            </SwipeableDrawer>
                        </div>
                    )}
                </Toolbar>
            </AppBar>
        </Box>
    );
}