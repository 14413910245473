import React, { Fragment, useState, useRef, useEffect } from 'react'
import Header from '../dashboard/header';

// Importaciones Bootstrap
import { Container, Row, Col, Form, Modal, Stack, Carousel } from 'react-bootstrap';


// Importaciones MaterialUI
import { Table, TableContainer, TableHead, TableCell, TableBody, TableRow, TablePagination, IconButton, Button, Snackbar, Alert, AlertTitle } from '@mui/material';



import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Inventory2SharpIcon from '@mui/icons-material/Inventory2Sharp';
import { useNavigate, useParams } from 'react-router-dom';

import StackMU from '@mui/material/Stack'
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import HideImageIcon from '@mui/icons-material/HideImage';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';

import { ValidarUsuario, ObtenerUsuario, config, CreateJsonToExcel, listarPermisos, ValidarPermisos, LIST_SCREENS } from '../utils/utils';
import PaginaCarga from '../utils/cargapagina'

import moment from 'moment';

export default function Cajachica() {
    const navigate = useNavigate();
    let { idproyecto } = useParams();

    //Estados
    const [mostrarModalDetalla, setmostrarModalDetalla] = useState(false);
    const [mostarModalFotografia, setmostarModalFotografia] = useState(false);
    const [mostrarModalProveedor, setmostrarModalProveedor] = useState(false);
    const [mostrarModalAgregarProveedor, setmostrarModalAgregarProveedor] = useState(false);
    const [mostrarModalagregarTrabajador, setmostrarModalagregarTrabajador] = useState(false);
    const [mostrarModalAgregarDetalleGasto, setmostrarModalAgregarDetalleGasto] = useState(false);
    const [mostrarModalAgregarCodigoGasto,setmostrarModalAgregarCodigoGasto]=useState(false);
    const [mostrarModalAgregarArea, setmostrarModalAgregarArea] = useState(false);
    const [mostrarModalAgregarCentroCosto, setmostrarModalAgregarCentroCosto] = useState(false);
    const [mostrarModalReporteRangoFecha, setmostrarModalReporteRangoFecha] = useState(false);

    const [abrirToast, setabrirToast] = useState(false);
    const [titulotoast, settitulotoast] = useState("");
    const [mensajetoast, setmensajetoast] = useState("");
    const [tipotoast, settipotoast] = useState("error");

    const [listacajachica, setlistacajachica] = useState([]);
    const [listatrabajadoresactivos, setlistatrabajadoresactivos] = useState([]);
    const [listacodigoscajachica, setlistacodigoscajachica] = useState([]);
    const [listatiposdocumento, setlistatiposdocumento] = useState([]);
    const [listaproveedoresactivos, setlistaproveedoresactivos] = useState([]);
    const [listadetallegastosactivos, setlistadetallegastosactivos] = useState([]);
    const [listareaeasactivas, setlistareaeasactivas] = useState([]);
    const [listacentrosgastoactivo, setlistacentrosgastoactivo] = useState([]);
    const [listacodigogastoactivo,setlistacodigogastoactivo]=useState([]);
    const [idproveedor, setidproveedor] = useState();

    const [accionactual, setaccionactual] = useState("");
    const [controlesreadonly, setcontrolesreadonly] = useState(true);
    const [activarcontrolesEgreso, setactivarcontrolesEgreso] = useState(false);
    const [cajachicaActual, setcajachicaActual] = useState(null);
    const [resumencajachica, setresumencajachica] = useState(null);
    const [fotografiaActual, setfotografiaActual] = useState('');
    const [valorinpuesto, setvalorinpuesto] = useState(0.18);

    const [load, setload] = useState(false);
    const [loadcarga, setloadcarga] = useState(true);

    const [cantidadElementos, setcantidadElementos] = useState(0);
    const [filasporpagina, setfilasporpagina] = useState(50);
    const [pagina, setpagina] = useState(1);

    //Referencias controles
    const fechacajachicaRef = useRef();
    const periodocajachicaRef = useRef();
    const fechaemisionRef = useRef();
    const trabajadorresponsableRef = useRef();
    const tipocajachicaRef = useRef();
    const nrorucRef = useRef();
    const razonsocialRef = useRef();
    const descripciongastoRef = useRef();
    const codigocajachicaRef = useRef();
    const detallegastoRef = useRef();
    const tipogastoRef = useRef();
    const areacajachicaRef = useRef();
    const centrocostoRef = useRef();
    const activoacajachicaRef = useRef();
    const tipodocumentoRef = useRef();
    const nrodocumentoRef = useRef();
    const incluyeigvRef = useRef();
    const subtotalRef = useRef();
    const montoigvref = useRef();
    const montocajachicaRef = useRef();
    const motivoRef = useRef();
    const comentarioRef = useRef();
    const fotografiaRef = useRef();
    const filtrocajachicaRef = useRef();
    //Referencias Modal Trabajador
    const nombretrabajadorRef = useRef();
    const areatrabajadorRef = useRef();
    //Referencias Modal Proveedor
    const rucproveedorRef = useRef();
    const nombreproveedorRef = useRef();
    const filtrobuscarproveedorRef = useRef();
    //Referencias Modal Detalle Gasto
    const nombredetallegastoRef = useRef();
    const descripciondetallegastoRef = useRef();
    const codigodetallegastoRef = useRef();
    //Referencias Modal Area
    const nombreareaRef = useRef();
    const descripcionareaRef = useRef();
    const codigoareaRef = useRef();
    //Referencias Modal Centro Costo
    const nombrecentrocostoRef = useRef();
    const descripcioncentrocostoRef = useRef();
    const codigocentrocostoRef = useRef();
    //Referencias Modal Codigo Gasto
    const codigocodigogastoref=useRef();
    const descripcioncodigogastoRef=useRef();
    //Referencias Modal Reporte Rango Fechas
    const fechaInicioReporteRef = useRef();
    const fechaFinReporteRef = useRef();

    //Evento Inicio
    useEffect(() => {
        ValidarAccesos();
    }, []);
    const ValidarAccesos = () => {
        if (ValidarUsuario()) {
            listarPermisos().then((permisos) => {
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_CAJACHICA, permisos)) {
                    BuscarListascajachicas(filasporpagina, pagina);
                } else {
                    settitulotoast("Alerta");
                    settipotoast("warning");
                    setmensajetoast("No tiene permisos para acceder a este Recurso.");
                    setabrirToast(true);
                    setTimeout(() => {
                        navigate('/');
                    }, 1000);
                }
            });
        } else {
            navigate('/login');
        }
    }
    //Procedimientos
    const BuscarListascajachicas = (filas, paginaactual) => {
        if (ValidarUsuario()) {
            let url = "";
            if (filtrocajachicaRef.current.value.trim() === "") {
                url = `${config.endPoint}api/cajachica/listar/${idproyecto}/${filas}/${paginaactual}`;
            }
            else {
                url = `${config.endPoint}api/cajachica/listar/${idproyecto}/${filas}/${paginaactual}/${filtrocajachicaRef.current.value}`;
            }
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data) {
                    BuscarCantidadesGeneralCajaChica();
                    setlistacajachica(data.content);
                    setcantidadElementos(data.cantidad)
                    setload(true);
                    setloadcarga(false);
                }
            }).catch((error) => {

                settitulotoast("Alerta");
                settipotoast("warning");
                setmensajetoast(config.MessageErrorServer);
                setabrirToast(true);
            });
        } else {
            navigate("/login");
        }
    }
    const BuscarCantidadesGeneralCajaChica = () => {
        let url = `${config.endPoint}api/cajachica/buscar/resumen/${idproyecto}`;
        let UsuarioStorage = ObtenerUsuario();
        UsuarioStorage = JSON.parse(UsuarioStorage);
        let headers = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + UsuarioStorage.token,
            },
        };

        fetch(url, headers).then((respuesta) => {
            return respuesta.json();
        }).then(async (data) => {
            // console.log(data);
            if (data.content.resumen) {
                setresumencajachica(data.content);
            } else {
                setresumencajachica(null);
            }
        }).catch((error) => {
            setresumencajachica(null);
            settitulotoast("Alerta");
            settipotoast("warning");
            setmensajetoast(config.MessageErrorServer);
            setabrirToast(true);
        });
    }
    const BuscarIdCajaChica = (id) => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/cajachica/busqueda/${id}`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then(async (data) => {
                    if (data.content) {
                        await setcajachicaActual(data.content);
                        resolve(data.content);
                    }
                    else {
                        await setcajachicaActual(null);
                        settitulotoast("Alerta");
                        settipotoast("warning");
                        setmensajetoast(data.message);
                        setabrirToast(true);
                        resolve(null);
                    }
                }).catch(async () => {
                    await setcajachicaActual(null);
                    settitulotoast("Error");
                    settipotoast("error");
                    setmensajetoast(config.MessageErrorServer);
                    setabrirToast(true);
                    resolve(null);
                });
            }
            else {
                navigate("/login")
                reject(true)
            }
        });
    }
    const BuscarListaTrabajadoresActivos = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/trabajador/listaractivos`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        // console.log(data.content);
                        setlistatrabajadoresactivos(data.content);
                        resolve(true);
                    }
                    else {
                        setlistatrabajadoresactivos([]);
                        settitulotoast("Alerta");
                        settipotoast("warning");
                        setmensajetoast(data.message);
                        setabrirToast(true);
                        resolve(false);
                    }
                }).catch(() => {
                    setlistatrabajadoresactivos([]);
                    settitulotoast("Error");
                    settipotoast("error");
                    setmensajetoast(config.MessageErrorServer);
                    setabrirToast(true);
                    resolve(false);
                });
            }
            else {
                navigate("/login")
                reject(true)
            }
        });
    }
    const BuscarListaCodigosCajaChica = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/cajachica/listarcodigos`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        // console.log(data.content);
                        setlistacodigoscajachica(data.content);
                        resolve(true);
                    }
                    else {
                        setlistacodigoscajachica([]);
                        settitulotoast("Alerta");
                        settipotoast("warning");
                        setmensajetoast(data.message);
                        setabrirToast(true);
                        resolve(false);
                    }
                }).catch(() => {
                    setlistacodigoscajachica([]);
                    settitulotoast("Error");
                    settipotoast("error");
                    setmensajetoast(config.MessageErrorServer);
                    setabrirToast(true);
                    resolve(false);
                });
            }
            else {
                navigate("/login")
                reject(true)
            }
        });
    }
    const BuscarTiposDocumentoCajaChica = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/cajachica/listartipodocumento`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        // console.log(data.content);
                        setlistatiposdocumento(data.content);
                        resolve(true);
                    }
                    else {
                        setlistatiposdocumento([]);
                        settitulotoast("Alerta");
                        settipotoast("warning");
                        setmensajetoast(data.message);
                        setabrirToast(true);
                        resolve(false);
                    }
                }).catch(() => {
                    setlistatiposdocumento([]);
                    settitulotoast("Error");
                    settipotoast("error");
                    setmensajetoast(config.MessageErrorServer);
                    setabrirToast(true);
                    resolve(false);
                });
            }
            else {
                navigate("/login")
                reject(true)
            }
        });
    }
    const BuscarListaProveedoresActivos = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {

                let url = "";
                // console.log(filtrobuscarproveedorRef);
                if (filtrobuscarproveedorRef.current) {
                    if (filtrobuscarproveedorRef.current.value !== "") {
                        url = `${config.endPoint}api/proveedor/listaractivos/${filtrobuscarproveedorRef.current.value}`;
                    } else {
                        url = `${config.endPoint}api/proveedor/listaractivos`;
                    }

                } else {
                    url = `${config.endPoint}api/proveedor/listaractivos`;
                }

                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        // console.log(data.content);
                        setlistaproveedoresactivos(data.content);
                        resolve(true);
                    }
                    else {
                        setlistaproveedoresactivos([]);
                        settitulotoast("Alerta");
                        settipotoast("warning");
                        setmensajetoast(data.message);
                        setabrirToast(true);
                        resolve(false);
                    }
                }).catch(() => {
                    setlistaproveedoresactivos([]);
                    settitulotoast("Error");
                    settipotoast("error");
                    setmensajetoast(config.MessageErrorServer);
                    setabrirToast(true);
                    resolve(false);
                });
            }
            else {
                navigate("/login")
                reject(true)
            }
        });
    }
    const BuscarListaDetalleGastosActivos = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/detallegasto/listaractivos`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        // console.log(data.content);
                        setlistadetallegastosactivos(data.content);
                        resolve(true);
                    }
                    else {
                        setlistadetallegastosactivos([]);
                        settitulotoast("Alerta");
                        settipotoast("warning");
                        setmensajetoast(data.message);
                        setabrirToast(true);
                        resolve(false);
                    }
                }).catch(() => {
                    setlistadetallegastosactivos([]);
                    settitulotoast("Error");
                    settipotoast("error");
                    setmensajetoast(config.MessageErrorServer);
                    setabrirToast(true);
                    resolve(false);
                });
            }
            else {
                navigate("/login")
                reject(true)
            }
        });
    }
    const BuscarListaAreasActivos = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/area/listaractivo`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        // console.log(data.content);
                        setlistareaeasactivas(data.content);
                        resolve(true);
                    }
                    else {
                        setlistareaeasactivas([]);
                        settitulotoast("Alerta");
                        settipotoast("warning");
                        setmensajetoast(data.message);
                        setabrirToast(true);
                        resolve(false);
                    }
                }).catch(() => {
                    setlistareaeasactivas([]);
                    settitulotoast("Error");
                    settipotoast("error");
                    setmensajetoast(config.MessageErrorServer);
                    setabrirToast(true);
                    resolve(false);
                });
            }
            else {
                navigate("/login")
                reject(true)
            }
        });
    }
    const BuscarListaCentrosGastoActivos = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/centrocosto/listaractivos`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        // console.log(data.content);
                        setlistacentrosgastoactivo(data.content);
                        resolve(true);
                    }
                    else {
                        setlistacentrosgastoactivo([]);
                        settitulotoast("Alerta");
                        settipotoast("warning");
                        setmensajetoast(data.message);
                        setabrirToast(true);
                        resolve(false);
                    }
                }).catch(() => {
                    setlistacentrosgastoactivo([]);
                    settitulotoast("Error");
                    settipotoast("error");
                    setmensajetoast(config.MessageErrorServer);
                    setabrirToast(true);
                    resolve(false);
                });
            }
            else {
                navigate("/login")
                reject(true)
            }
        });
    }
    const BuscarListaCodigosGastoActivos = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/codigogasto/listaractivos`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        // console.log(data.content);
                        setlistacodigogastoactivo(data.content);
                        resolve(true);
                    }
                    else {
                        setlistacodigogastoactivo([]);
                        settitulotoast("Alerta");
                        settipotoast("warning");
                        setmensajetoast(data.message);
                        setabrirToast(true);
                        resolve(false);
                    }
                }).catch(() => {
                    setlistacodigogastoactivo([]);
                    settitulotoast("Error");
                    settipotoast("error");
                    setmensajetoast(config.MessageErrorServer);
                    setabrirToast(true);
                    resolve(false);
                });
            }
            else {
                navigate("/login")
                reject(true)
            }
        });
    }
    const AbrirModalcajachica = async (accion, fila) => {
        await setactivarcontrolesEgreso(true);
        await setidproveedor(null);
        await setmostrarModalDetalla(true);

        //Buscar Lista Trabajos
        await Promise.all([
            BuscarListaTrabajadoresActivos(),
            BuscarListaCodigosCajaChica(),
            BuscarTiposDocumentoCajaChica(),
            BuscarListaProveedoresActivos(),
            BuscarListaDetalleGastosActivos(),
            BuscarListaAreasActivos(),
            BuscarListaCentrosGastoActivos(),
            BuscarListaCodigosGastoActivos()
        ]).then(() => {

        });


        if (accion === "VER") {
            setcontrolesreadonly(true);
            setcajachicaActual(fila);
            incluyeigvRef.current.disabled = true;
            await BuscarIdCajaChica(fila.idcajachica).then(async (resp) => {
                // console.log(resp);
                if (resp) {
                    fechacajachicaRef.current.value = resp.fecha;
                    periodocajachicaRef.current.value = resp.periodo;
                    trabajadorresponsableRef.current.value = resp.responsable;
                    tipocajachicaRef.current.value = resp.tipo;

                    motivoRef.current.value = resp.motivo;
                    comentarioRef.current.value = resp.comentario;
                    //fotografiaRef.current.value = resp.fotografia;
                    if (resp.tipo === "EGRESO") {
                        await setactivarcontrolesEgreso(true);
                        nrorucRef.current.value = resp.numerodocumento;
                        razonsocialRef.current.value = resp.proveedor;
                        descripciongastoRef.current.value = resp.descripciongasto;
                        codigocajachicaRef.current.value = resp.codigogasto;
                        detallegastoRef.current.value = resp.detallegasto;
                        tipogastoRef.current.value = resp.tipogasto;
                        areacajachicaRef.current.value = resp.arearesponsable;
                        centrocostoRef.current.value = resp.nombrecentrocosto;
                        activoacajachicaRef.current.value = resp.activo;
                        fechaemisionRef.current.value = resp.fechaemision;
                        tipodocumentoRef.current.value = resp.tipodocumento;
                        nrodocumentoRef.current.value = resp.nrodocumento;
                        if (resp.conigv === "1") {
                            incluyeigvRef.current.checked = true;
                        } else {
                            incluyeigvRef.current.checked = false;
                        }
                        subtotalRef.current.value = resp.subtotal * -1;
                        montoigvref.current.value = resp.impuesto;
                        montocajachicaRef.current.value = resp.monto * -1;
                    } else {
                        await setactivarcontrolesEgreso(false);
                        montocajachicaRef.current.value = resp.monto;
                    }

                }
            });

        }
        else {
            setcontrolesreadonly(false);
            await setactivarcontrolesEgreso(false);
            let fechactual = Date.now();
            fechacajachicaRef.current.value = moment(fechactual).format("yyyy-MM-DD");
            periodocajachicaRef.current.value = moment(fechactual).format("yyyyMM");
            montocajachicaRef.current.value = 0;
        }
        setaccionactual(accion);
    }
    const ValidarInformacionEnviar = () => {
        let error = 0;

        if (tipocajachicaRef.current.value.trim() !== "") {

            tipocajachicaRef.current.classList.remove("is-invalid");

            if (fechacajachicaRef.current.value.trim() === "") {
                fechacajachicaRef.current.classList.add("is-invalid");
                // console.log(fechacajachicaRef.current.value);
                error++;
            } else {
                fechacajachicaRef.current.classList.remove("is-invalid");
            }

            if (periodocajachicaRef.current.value.trim() === "") {
                periodocajachicaRef.current.classList.add("is-invalid");
                error++;
            } else {
                periodocajachicaRef.current.classList.remove("is-invalid");
            }

            if (trabajadorresponsableRef.current.value.trim() === "") {
                trabajadorresponsableRef.current.classList.add("is-invalid");
                error++;
            } else {
                trabajadorresponsableRef.current.classList.remove("is-invalid");
            }

            if (montocajachicaRef.current.value.trim() === "") {
                montocajachicaRef.current.classList.add("is-invalid");
                error++;
            } else {
                let _cantidad = parseFloat(montocajachicaRef.current.value);
                if (isNaN(_cantidad)) {
                    error++;
                    montocajachicaRef.current.classList.add("is-invalid");
                    settitulotoast("Alerta");
                    settipotoast("warning");
                    setmensajetoast("Valor numerico no valido.");
                    setabrirToast(true);
                } else {
                    if (_cantidad > 0) {
                        montocajachicaRef.current.classList.remove("is-invalid");
                    } else {
                        error++;
                        montocajachicaRef.current.classList.add("is-invalid");
                        settitulotoast("Alerta");
                        settipotoast("warning");
                        setmensajetoast("Verifique el las cantidades, el valor numerico debe ser mayor a 0.");
                        setabrirToast(true);
                    }
                }
            }

            // if (motivoRef.current.value.trim() === "") {
            //     motivoRef.current.classList.add("is-invalid");
            //     error++;
            // } else {
            //     motivoRef.current.classList.remove("is-invalid");
            // }

            if (tipocajachicaRef.current.value === "EGRESO") {
                //Validar : NRO RUC, RAZON SOCIAL, CODIGO, DETALE GASTO
                if (nrorucRef.current.value.trim() === "") {
                    nrorucRef.current.classList.add("is-invalid");
                    error++;
                } else {
                    nrorucRef.current.classList.remove("is-invalid");
                }
                // console.log(idproveedor);
                if (idproveedor) {
                    nrorucRef.current.classList.remove("is-invalid");
                } else {
                    nrorucRef.current.classList.add("is-invalid");
                    error++;
                }

                if (codigocajachicaRef.current.value.trim() === "") {
                    codigocajachicaRef.current.classList.add("is-invalid");
                    error++;
                } else {
                    codigocajachicaRef.current.classList.remove("is-invalid");
                }

                if (detallegastoRef.current.value.trim() === "") {
                    detallegastoRef.current.classList.add("is-invalid");
                    error++;
                } else {
                    detallegastoRef.current.classList.remove("is-invalid");
                }

                if (tipogastoRef.current.value.trim() === "") {
                    tipogastoRef.current.classList.add("is-invalid");
                    error++;
                } else {
                    tipogastoRef.current.classList.remove("is-invalid");
                }

                if (areacajachicaRef.current.value.trim() === "") {
                    areacajachicaRef.current.classList.add("is-invalid");
                    error++;
                } else {
                    areacajachicaRef.current.classList.remove("is-invalid");
                }

                if (centrocostoRef.current.value.trim() === "") {
                    centrocostoRef.current.classList.add("is-invalid");
                    error++;
                } else {
                    centrocostoRef.current.classList.remove("is-invalid");
                }

                if (centrocostoRef.current.value.trim() === "") {
                    centrocostoRef.current.classList.add("is-invalid");
                    error++;
                } else {
                    centrocostoRef.current.classList.remove("is-invalid");
                }

                if (activoacajachicaRef.current.value.trim() === "") {
                    activoacajachicaRef.current.classList.add("is-invalid");
                    error++;
                } else {
                    activoacajachicaRef.current.classList.remove("is-invalid");
                }

                if (fechaemisionRef.current.value.trim() === "") {
                    fechaemisionRef.current.classList.add("is-invalid");
                    error++;
                } else {
                    fechaemisionRef.current.classList.remove("is-invalid");
                }

                if (tipodocumentoRef.current.value.trim() === "") {
                    tipodocumentoRef.current.classList.add("is-invalid");
                    error++;
                } else {
                    tipodocumentoRef.current.classList.remove("is-invalid");
                }

                if (nrodocumentoRef.current.value.trim() === "") {
                    nrodocumentoRef.current.classList.add("is-invalid");
                    error++;
                } else {
                    nrodocumentoRef.current.classList.remove("is-invalid");
                }

                if (subtotalRef.current.value.trim() === "") {
                    subtotalRef.current.classList.add("is-invalid");
                    error++;
                } else {
                    let _cantidad = parseFloat(subtotalRef.current.value);
                    if (isNaN(_cantidad)) {
                        error++;
                        subtotalRef.current.classList.add("is-invalid");
                        settitulotoast("Alerta");
                        settipotoast("warning");
                        setmensajetoast("Valor numerico no valido.");
                        setabrirToast(true);

                    } else {
                        if (_cantidad > 0) {
                            subtotalRef.current.classList.remove("is-invalid");
                        } else {
                            error++;
                            subtotalRef.current.classList.add("is-invalid");
                            settitulotoast("Alerta");
                            settipotoast("warning");
                            setmensajetoast("Verifique el las cantidades, el valor numerico debe ser mayor a 0.");
                            setabrirToast(true);
                        }
                    }
                }
            }
        } else {
            error++;
            tipocajachicaRef.current.classList.add("is-invalid");
            settitulotoast("Alerta");
            settipotoast("warning");
            setmensajetoast("No se ha seleccionado el tipo de registro a agregar. Seleccione el Tipo y vuelva a intentarlo.");
            setabrirToast(true);
        }
        // console.log(error);
        if (error > 0) {
            return false;
        } else {
            return true;
        }

    }
    const Guardarcajachica = () => {
        if (ValidarUsuario()) {
            if (ValidarInformacionEnviar()) {
                if (accionactual === "NUEVO") {
                    // console.log("nuevo");
                    if (fotografiaRef.current.files.length > 0) {
                        CrearNuevoCajachicaFoto();
                    } else {
                        CrearNuevocajachica();
                    }
                } else {
                    if (fotografiaRef.current.files.length > 0) {
                        ModificarcajachicaFoto();

                    } else {
                        // console.log("mantenimiento");
                        Modificarcajachica();
                    }
                }
            } else {
                settitulotoast("Alerta");
                settipotoast("warning");
                setmensajetoast("Se detectaron alertas al realizar esta acción. Corrija las alertas y vuelva a interntarlo.");
                setabrirToast(true);
            }
        }
        else {
            navigate("/login");
        }
    }
    const BuscarIdTrabajador = () => {
        let idtrabajador = listatrabajadoresactivos.find(x => x.nombres === trabajadorresponsableRef.current.value);
        if (idtrabajador) {
            return idtrabajador;
        }
        else {
            trabajadorresponsableRef.current.value = "";
            trabajadorresponsableRef.current.classList.add('is-invalid');
            return null;
        }
    }
    const KeyEnterInputRuc = (ev) => {
        if (ev.key === "Enter") {
            BuscarIdProveedor();
        }
    }
    const ChangeInputRuc = () => {
        razonsocialRef.current.value = ''
        setidproveedor(null);
    }
    const BuscarIdProveedor = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/proveedor/busquedacod`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify({ numerodocumento: nrorucRef.current.value })
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        nrorucRef.current.classList.remove("is-invalid");
                        setidproveedor(data.content.id);
                        razonsocialRef.current.value = data.content.nombreproveedor
                        resolve(true);
                    }
                    else {
                        nrorucRef.current.classList.add("is-invalid");
                        setidproveedor(null);
                        razonsocialRef.current.value = "";
                        settitulotoast("Alerta");
                        settipotoast("warning");
                        setmensajetoast("No se encotraron datos del Proveedor.");
                        setabrirToast(true);
                        resolve(true)
                    }
                }).catch(() => {
                    setidproveedor(null);
                    razonsocialRef.current.value = "";
                    settitulotoast("Error");
                    settipotoast("error");
                    setmensajetoast(config.MessageErrorServer);
                    setabrirToast(true);
                    resolve(false);
                });
            }
            else {
                reject(true);
            }
        });
    }
    const BuscarIdDetalleGasto = () => {
        let iddetallegasto = listadetallegastosactivos.find(x => x.detallegasto === detallegastoRef.current.value)
        if (iddetallegasto) {
            return iddetallegasto;
        }
        else {
            detallegastoRef.current.value = "";
            detallegastoRef.current.classList.add("is-invalid");
            return null;
        }
    }    
    const BuscarIdArea = () => {
        let idarea = listareaeasactivas.find(x => x.nombrearea === areacajachicaRef.current.value);
        if (idarea) {
            return idarea;
        } else {
            areacajachicaRef.current.value = "";
            areacajachicaRef.current.classList.add("is-invalid");
        }
    }
    const BuscarIdCentroCosto = () => {
        let idcentrocostro = listacentrosgastoactivo.find(x => x.nombrecentrocosto === centrocostoRef.current.value);
        if (idcentrocostro) {
            return idcentrocostro;
        } else {
            centrocostoRef.current.value = "";
            centrocostoRef.current.classList.add("is-invalid");
        }
    }
    const BuscarIdCodigoGasto = () => {
        let idcodigofind = listacodigogastoactivo.find(x => x.codigo === codigocajachicaRef.current.value)
        if (idcodigofind) {
            return idcodigofind;
        }
        else {
            codigocajachicaRef.current.value = "";
            codigocajachicaRef.current.classList.add("is-invalid");
            return null;
        }
    }
    const CrearNuevocajachica = () => {
        //Buscar Identificadores Necesarios
        let _trabajadorResponsable = BuscarIdTrabajador();
        // console.log(_trabajadorResponsable);

        let url = `${config.endPoint}api/cajachica/agregar`;
        let UsuarioStorage = ObtenerUsuario();
        UsuarioStorage = JSON.parse(UsuarioStorage);
        let nuevoreg = {};

        if (tipocajachicaRef.current.value === "EGRESO") {
            let _detallegasto = BuscarIdDetalleGasto();
            let _area = BuscarIdArea()
            let _centrocosto = BuscarIdCentroCosto();
            let _codigogasto=BuscarIdCodigoGasto();

            if (_trabajadorResponsable && _detallegasto && _area && _centrocosto) {
                nuevoreg = {
                    fecha: fechacajachicaRef.current.value,
                    periodo: periodocajachicaRef.current.value,
                    idresponsable: _trabajadorResponsable.id,
                    tipo: tipocajachicaRef.current.value.toUpperCase(),
                    idproveedor: idproveedor,
                    descripciongasto: descripciongastoRef.current.value.toUpperCase(),
                    iddetallegasto: _detallegasto.id,
                    tipogasto: tipogastoRef.current.value.toUpperCase(),
                    idarea: _area.id,
                    idcentrocosto: _centrocosto.id,                    
                    codigogasto: codigocajachicaRef.current.value.toUpperCase(),
                    idcodigogasto:_codigogasto.id,
                    activo: activoacajachicaRef.current.value.toUpperCase(),
                    fechaemision: fechaemisionRef.current.value,
                    tipodocumento: tipodocumentoRef.current.value.toUpperCase(),
                    nrodocumento: nrodocumentoRef.current.value.toUpperCase(),
                    conigv: incluyeigvRef.current.checked ? 1 : 0,
                    subtotal: subtotalRef.current.value * -1,
                    impuesto: montoigvref.current.value,
                    monto: montocajachicaRef.current.value * -1,
                    comentario: comentarioRef.current.value.toUpperCase(),
                    motivo: motivoRef.current.value.toUpperCase(),
                    idproyecto: idproyecto,
                    usuariocrea: UsuarioStorage.id,
                    fotografia: "",
                }
            }

        } else {
            if (_trabajadorResponsable) {
                nuevoreg = {
                    fecha: fechacajachicaRef.current.value,
                    periodo: periodocajachicaRef.current.value,
                    idresponsable: _trabajadorResponsable.id,
                    tipo: tipocajachicaRef.current.value,
                    monto: montocajachicaRef.current.value,
                    comentario: comentarioRef.current.value,
                    motivo: motivoRef.current.value,
                    idproyecto: idproyecto,
                    usuariocrea: UsuarioStorage.id,
                    fotografia: "",
                }
            }
        }

        let headers = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + UsuarioStorage.token,
            },
            body: JSON.stringify(nuevoreg)
        };

        fetch(url, headers).then((respuesta) => {
            return respuesta.json()
        }).then((data) => {
            // console.log(data);
            if (data.content) {
                // console.log(data.content);
                //Buscar Lista Centros de Costo
                BuscarListascajachicas(filasporpagina, pagina);
                settitulotoast("Correcto");
                settipotoast("success");
                setmensajetoast(data.message);
                setabrirToast(true);
                setmostrarModalDetalla(false);
            }
            else {
                settitulotoast("Error");
                settipotoast("warning");
                setmensajetoast(data.message);
                setabrirToast(true);
            }

        }).catch((error) => {
            settitulotoast("Error");
            settipotoast("error");
            setmensajetoast(config.MessageErrorServer);
            setabrirToast(true);
        });

    }
    // const SubirFoto = () => {
    //     CrearNuevoCajachicaFoto();
    // }
    const CrearNuevoCajachicaFoto = async () => {
        // return new Promise((resolve, reject) => {
        if (ValidarUsuario()) {

            let url = `${config.endPoint}api/cajachica/agregarfoto`;
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);

            let formData = new FormData();
            formData.append('file', fotografiaRef.current.files[0], fotografiaRef.current.files[0].name);
            //formData.append('idcajachica', idcajachica);  

            //Buscar Identificadores Necesarios
            let _trabajadorResponsable = BuscarIdTrabajador();

            if (tipocajachicaRef.current.value === "EGRESO") {
                let _detallegasto = BuscarIdDetalleGasto();
                let _area = BuscarIdArea()
                let _centrocosto = BuscarIdCentroCosto();
                let _codigogasto=BuscarIdCodigoGasto();

                if (_trabajadorResponsable && _detallegasto && _area && _centrocosto) {
                    formData.append('fecha', fechacajachicaRef.current.value);
                    formData.append('periodo', periodocajachicaRef.current.value);
                    formData.append('idresponsable', _trabajadorResponsable.id);
                    formData.append('tipo', tipocajachicaRef.current.value.toUpperCase());
                    formData.append('idproveedor', idproveedor);
                    formData.append('descripciongasto', descripciongastoRef.current.value.toUpperCase());
                    formData.append('iddetallegasto', _detallegasto.id);
                    formData.append('tipogasto', tipogastoRef.current.value.toUpperCase());
                    formData.append('idarea', _area.id);
                    formData.append('idcentrocosto', _centrocosto.id);                    
                    formData.append('codigogasto', codigocajachicaRef.current.value.toUpperCase());
                    formData.append('idcodigogasto', _codigogasto.id);
                    formData.append('activo', activoacajachicaRef.current.value.toUpperCase());
                    formData.append('fechaemision', fechaemisionRef.current.value);
                    formData.append('tipodocumento', tipodocumentoRef.current.value.toUpperCase());
                    formData.append('nrodocumento', nrodocumentoRef.current.value.toUpperCase());
                    formData.append('conigv', incluyeigvRef.current.checked ? 1 : 0);
                    formData.append('subtotal', subtotalRef.current.value * -1);
                    formData.append('impuesto', montoigvref.current.value);
                    formData.append('monto', montocajachicaRef.current.value * -1);
                    formData.append('comentario', comentarioRef.current.value.toUpperCase());
                    formData.append('motivo', motivoRef.current.value.toUpperCase());
                    formData.append('idproyecto', idproyecto);
                    formData.append('usuariocrea', UsuarioStorage.id);
                    //formData.append('fotografia', "");
                }

            } else {
                if (_trabajadorResponsable) {
                    formData.append('fecha', fechacajachicaRef.current.value);
                    formData.append('periodo', periodocajachicaRef.current.value);
                    formData.append('idresponsable', _trabajadorResponsable.id);
                    formData.append('tipo', tipocajachicaRef.current.value);
                    formData.append('monto', montocajachicaRef.current.value);
                    formData.append('comentario', comentarioRef.current.value);
                    formData.append('motivo', motivoRef.current.value);
                    formData.append('idproyecto', idproyecto);
                    formData.append('usuariocrea', UsuarioStorage.id);
                    //formData.append('fotografia', "");
                }
            }

            let headers = {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: formData
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json()
            }).then((data) => {
                // console.log(data);
                if (data.content) {
                    // console.log(data.content);
                    //Buscar Lista Centros de Costo
                    BuscarListascajachicas(filasporpagina, pagina);
                    settitulotoast("Correcto");
                    settipotoast("success");
                    setmensajetoast(data.message);
                    setabrirToast(true);
                    setmostrarModalDetalla(false);
                }
                else {
                    settitulotoast("Alerta");
                    settipotoast("warning");
                    setmensajetoast(data.message);
                    setabrirToast(true);
                }

            }).catch((error) => {
                settitulotoast("Error");
                settipotoast("error");
                setmensajetoast(config.MessageErrorServer);
                setabrirToast(true);
            });
        }
        else {
            navigate("/login")
            // reject(true)
        }
        // });
    }
    const HabilitarModificarRegistroCajaChica = () => {
        // console.log(cajachicaActual);
        setcontrolesreadonly(false);
        if (tipocajachicaRef.current.value === "EGRESO") {
            incluyeigvRef.current.disabled = false;
            setidproveedor(cajachicaActual.idproveedor);
        }
        else {

        }
    }
    const Modificarcajachica = () => {

        //Buscar Identificadores Necesarios
        let _trabajadorResponsable = BuscarIdTrabajador();
        // console.log(_trabajadorResponsable);

        let url = `${config.endPoint}api/cajachica/modificar`;
        let UsuarioStorage = ObtenerUsuario();
        UsuarioStorage = JSON.parse(UsuarioStorage);
        let nuevoreg = {};

        if (tipocajachicaRef.current.value === "EGRESO") {
            let _detallegasto = BuscarIdDetalleGasto();
            let _area = BuscarIdArea()
            let _centrocosto = BuscarIdCentroCosto();
            let _codigogasto=BuscarIdCodigoGasto();

            if (_trabajadorResponsable && _detallegasto && _area && _centrocosto) {
                nuevoreg = {
                    idcajachica: cajachicaActual.idcajachica,
                    fecha: fechacajachicaRef.current.value,
                    periodo: periodocajachicaRef.current.value,
                    idresponsable: _trabajadorResponsable.id,
                    tipo: tipocajachicaRef.current.value.toUpperCase(),
                    idproveedor: idproveedor,
                    descripciongasto: descripciongastoRef.current.value.toUpperCase(),
                    iddetallegasto: _detallegasto.id,
                    tipogasto: tipogastoRef.current.value.toUpperCase(),
                    idarea: _area.id,
                    idcentrocosto: _centrocosto.id,
                    codigogasto: codigocajachicaRef.current.value.toUpperCase(),
                    idcodigogasto:_codigogasto.id,
                    activo: activoacajachicaRef.current.value.toUpperCase(),
                    fechaemision: fechaemisionRef.current.value,
                    tipodocumento: tipodocumentoRef.current.value.toUpperCase(),
                    nrodocumento: nrodocumentoRef.current.value.toUpperCase(),
                    conigv: incluyeigvRef.current.checked ? 1 : 0,
                    subtotal: subtotalRef.current.value * -1,
                    impuesto: montoigvref.current.value,
                    monto: montocajachicaRef.current.value * -1,
                    comentario: comentarioRef.current.value.toUpperCase(),
                    motivo: motivoRef.current.value.toUpperCase(),
                    idproyecto: idproyecto,
                    usuariomodifica: UsuarioStorage.id,
                }
            }

        } else {
            if (_trabajadorResponsable) {
                nuevoreg = {
                    idcajachica: cajachicaActual.idcajachica,
                    fecha: fechacajachicaRef.current.value,
                    periodo: periodocajachicaRef.current.value,
                    idresponsable: _trabajadorResponsable.id,
                    tipo: tipocajachicaRef.current.value,
                    monto: montocajachicaRef.current.value,
                    comentario: comentarioRef.current.value,
                    motivo: motivoRef.current.value,
                    idproyecto: idproyecto,
                    usuariomodifica: UsuarioStorage.id,
                    idproveedor: null,
                    descripciongasto: "",
                    iddetallegasto: null,
                    tipogasto: "",
                    idarea: null,
                    idcentrocosto: null,
                    codigogasto: "",
                    idcodigogasto:null,
                    activo: "",
                    fechaemision: null,
                    tipodocumento: "",
                    nrodocumento: "",
                    conigv: 0,
                    subtotal: 0,
                    impuesto: 0,

                }
            }
        }

        let headers = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + UsuarioStorage.token,
            },
            body: JSON.stringify(nuevoreg)
        };

        fetch(url, headers).then((respuesta) => {
            return respuesta.json()
        }).then((data) => {
            // console.log(data);
            if (data.content) {
                // console.log(data.content);
                //Buscar Lista Centros de Costo
                BuscarListascajachicas(filasporpagina, pagina);
                settitulotoast("Correcto");
                settipotoast("success");
                setmensajetoast(data.message);
                setabrirToast(true);
                setmostrarModalDetalla(false);
            }
            else {
                settitulotoast("Error");
                settipotoast("warning");
                setmensajetoast(data.message);
                setabrirToast(true);
            }

        }).catch((error) => {
            settitulotoast("Error");
            settipotoast("error");
            setmensajetoast(config.MessageErrorServer);
            setabrirToast(true);
        });

    }
    const ModificarcajachicaFoto = () => {
        // return new Promise((resolve, reject) => {
        if (ValidarUsuario()) {

            let url = `${config.endPoint}api/cajachica/modificarfoto`;
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);

            let formData = new FormData();
            formData.append('file', fotografiaRef.current.files[0], fotografiaRef.current.files[0].name);
            //formData.append('idcajachica', idcajachica); 
            let nuevoreg = {};

            //Buscar Identificadores Necesarios
            let _trabajadorResponsable = BuscarIdTrabajador();

            if (tipocajachicaRef.current.value === "EGRESO") {
                let _detallegasto = BuscarIdDetalleGasto();
                let _area = BuscarIdArea()
                let _centrocosto = BuscarIdCentroCosto();
                let _codigogasto=BuscarIdCodigoGasto();

                if (_trabajadorResponsable && _detallegasto && _area && _centrocosto) {
                    nuevoreg = {
                        idcajachica: cajachicaActual.idcajachica,
                        fecha: fechacajachicaRef.current.value,
                        periodo: periodocajachicaRef.current.value,
                        idresponsable: _trabajadorResponsable.id,
                        tipo: tipocajachicaRef.current.value.toUpperCase(),
                        idproveedor: idproveedor,
                        descripciongasto: descripciongastoRef.current.value.toUpperCase(),
                        iddetallegasto: _detallegasto.id,
                        tipogasto: tipogastoRef.current.value.toUpperCase(),
                        idarea: _area.id,
                        idcentrocosto: _centrocosto.id,
                        codigogasto: codigocajachicaRef.current.value.toUpperCase(),
                        idcodigogasto:_codigogasto.id,
                        activo: activoacajachicaRef.current.value.toUpperCase(),
                        fechaemision: fechaemisionRef.current.value,
                        tipodocumento: tipodocumentoRef.current.value.toUpperCase(),
                        nrodocumento: nrodocumentoRef.current.value.toUpperCase(),
                        conigv: incluyeigvRef.current.checked ? 1 : 0,
                        subtotal: subtotalRef.current.value * -1,
                        impuesto: montoigvref.current.value,
                        monto: montocajachicaRef.current.value * -1,
                        comentario: comentarioRef.current.value.toUpperCase(),
                        motivo: motivoRef.current.value.toUpperCase(),
                        idproyecto: idproyecto,
                        usuariomodifica: UsuarioStorage.id,
                    }
                    formData.append('nuevoreg', JSON.stringify(nuevoreg));
                }
            } else {
                if (_trabajadorResponsable) {
                    nuevoreg = {
                        idcajachica: cajachicaActual.idcajachica,
                        fecha: fechacajachicaRef.current.value,
                        periodo: periodocajachicaRef.current.value,
                        idresponsable: _trabajadorResponsable.id,
                        tipo: tipocajachicaRef.current.value,
                        monto: montocajachicaRef.current.value,
                        comentario: comentarioRef.current.value,
                        motivo: motivoRef.current.value,
                        idproyecto: idproyecto,
                        usuariomodifica: UsuarioStorage.id,

                        idproveedor: null,
                        descripciongasto: "",
                        iddetallegasto: null,
                        tipogasto: "",
                        idarea: null,
                        idcentrocosto: null,
                        codigogasto: "",
                        idcodigogasto:null,
                        activo: "",
                        fechaemision: null,
                        tipodocumento: "",
                        nrodocumento: "",
                        conigv: 0,
                        subtotal: 0,
                        impuesto: 0,

                    }
                    formData.append('nuevoreg', JSON.stringify(nuevoreg));
                }
            }

            let headers = {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: formData
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json()
            }).then((data) => {
                // console.log(data);
                if (data.content) {
                    // console.log(data.content);
                    //Buscar Lista Centros de Costo
                    BuscarListascajachicas(filasporpagina, pagina);
                    settitulotoast("Correcto");
                    settipotoast("success");
                    setmensajetoast(data.message);
                    setabrirToast(true);
                    setmostrarModalDetalla(false);
                }
                else {
                    settitulotoast("Alerta");
                    settipotoast("warning");
                    setmensajetoast(data.message);
                    setabrirToast(true);
                }

            }).catch((error) => {
                settitulotoast("Error");
                settipotoast("error");
                setmensajetoast(config.MessageErrorServer);
                setabrirToast(true);
            });
        }
        else {
            navigate("/login")
            // reject(true)
        }
    }
    const Eliminarcajachica = (fila) => {

        if (ValidarUsuario()) {
            if (window.confirm(`¿Estas Seguro de eliminar el registro ${fila.tipo} con monto S/. ${fila.monto}?`)) {

                let url = `${config.endPoint}api/cajachica/eliminar`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let body_permisos = {
                    idcajachica: fila.idcajachica,
                }

                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify(body_permisos)
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {

                    if (data.content) {
                        BuscarListascajachicas(filasporpagina, pagina);
                        settitulotoast("Correcto");
                        settipotoast("success");
                        setmensajetoast("Registro fue eliminado correctamente.");
                        setabrirToast(true);
                    }
                    else {
                        settitulotoast("Alerta");
                        settipotoast("warning");
                        setmensajetoast(data.message);
                        setabrirToast(true);
                    }

                }).catch((error) => {

                    settitulotoast("Error");
                    settipotoast("error");
                    setmensajetoast(config.MessageErrorServer);
                    setabrirToast(true);
                });
            }
        }
        else {
            navigate("/login");
        }
    }
    const OnChangeFiltrocajachica = () => {
        if (filtrocajachicaRef.current.value.trim() === '') {
            BuscarListascajachicas(filasporpagina, pagina);
        }
    }
    const cambiarPagina = async (event, npagina) => {
        await setpagina(parseInt(npagina) + 1);
        BuscarListascajachicas(filasporpagina, parseInt(npagina) + 1)
    }
    const cambiarfilasporpagina = async (ev) => {
        await setfilasporpagina(parseInt(ev.target.value));
        await setpagina(1);
        BuscarListascajachicas(parseInt(ev.target.value), 1)
    }
    // Procedimientos de Modal
    const CambiarTipoIngreso = async (ev) => {
        if (ev.target.value === 'EGRESO') {
            await setactivarcontrolesEgreso(true);
            incluyeigvRef.current.checked = true;
            subtotalRef.current.disabled = true;
            montocajachicaRef.current.disabled = false;
            let fechactual = Date.now();
            fechaemisionRef.current.value = moment(fechactual).format("yyyy-MM-DD");
        }
        else {
            setactivarcontrolesEgreso(false);
            montocajachicaRef.current.disabled = false;
        }
        CalcularMontoImpuesto();
    }
    const CambiarIncluyeImpuesto = () => {
        if (incluyeigvRef.current.checked) {
            subtotalRef.current.disabled = true;
            montocajachicaRef.current.disabled = false;
        } else {
            subtotalRef.current.disabled = false;
            montocajachicaRef.current.disabled = true;
        }
        CalcularMontoImpuesto()
    }
    const CalcularMontoImpuesto = () => {
        let subtotalCalc = 0;
        let totalCalc = 0;
        try {
            if (tipocajachicaRef.current.value === "EGRESO") {
                // Calcular Impuesto                
                if (incluyeigvRef.current.checked) {
                    let montocalcular = parseFloat(montocajachicaRef.current.value);
                    if (isNaN(montocalcular)) {
                        montocalcular = 0;
                        montocajachicaRef.current.value = 0;
                    }
                    subtotalCalc = Math.round(((montocalcular / (1 + valorinpuesto)) + Number.EPSILON) * 100) / 100;
                    subtotalRef.current.value = subtotalCalc;
                    totalCalc = Math.round(((montocalcular - subtotalCalc) + Number.EPSILON) * 100) / 100;
                    montoigvref.current.value = totalCalc;
                } else {
                    let montocalcular = parseFloat(subtotalRef.current.value);
                    if (isNaN(montocalcular)) {
                        montocalcular = 0;
                        subtotalRef.current.value = 0;
                    }
                    subtotalCalc = Math.round(((montocalcular * valorinpuesto) + Number.EPSILON) * 100) / 100;
                    montoigvref.current.value = subtotalCalc;
                    totalCalc = Math.round(((montocalcular + subtotalCalc) + Number.EPSILON) * 100) / 100;
                    montocajachicaRef.current.value = totalCalc;
                }
            } else {


            }
        } catch (error) {
            subtotalRef.current.value = 0;
            montoigvref.current.value = 0;
            montocajachicaRef.current.value = 0;
        }

    }
    //Procedimientos Agregar 
    const ValidarAgregarTrabajador = () => {
        let error = 0;
        if (nombretrabajadorRef.current.value.trim() === "") {
            error++;
            nombretrabajadorRef.current.classList.add("is-invalid");
        } else {
            nombretrabajadorRef.current.classList.remove("is-invalid");
        }

        if (areatrabajadorRef.current.value.trim() === "") {
            error++;
            areatrabajadorRef.current.classList.add('is-invalid');
        }
        else {
            areatrabajadorRef.current.classList.remove('is-invalid');
        }
        if (error > 0) {
            return false;
        } else {
            return true;
        }

    }
    const AgregarNuevoTrabajador = () => {
        if (ValidarAgregarTrabajador()) {
            let url = `${config.endPoint}api/trabajador/agregar`;
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            //buscar Area
            // console.log(areatrabajadorRef.current.value);
            let filtroarea = listareaeasactivas.find(x => x.id === parseInt(areatrabajadorRef.current.value));
            let nuevocentro = {
                nombres: nombretrabajadorRef.current.value.toUpperCase(),
                idarea: areatrabajadorRef.current.value.toUpperCase(),
                area: filtroarea.nombrearea,
                estado: "ACTIVO"
            }

            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify(nuevocentro)
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json()
            }).then((data) => {
                if (data.content) {
                    BuscarListaTrabajadoresActivos();
                    settitulotoast("Correcto");
                    settipotoast("success");
                    setmensajetoast(data.message);
                    setabrirToast(true);
                    setmostrarModalagregarTrabajador(false);
                }
                else {
                    settitulotoast("Alerta");
                    settipotoast("warning");
                    setmensajetoast(data.message);
                    setabrirToast(true);
                }

            }).catch((error) => {
                settitulotoast("Error");
                settipotoast("error");
                setmensajetoast(config.MessageErrorServer);
                setabrirToast(true);
            });

        } else {
            settitulotoast("Alerta");
            settipotoast("warning");
            setmensajetoast("Se detectaron alertas al realizar esta acción. Corrija las alertas y vuelva a interntarlo.");
            setabrirToast(true);
        }
    }
    // Procedimientos Proveedor
    const ValidarAgregarProveedor = () => {
        let error = 0;
        if (rucproveedorRef.current.value.trim() === "") {
            error++;
            rucproveedorRef.current.classList.add("is-invalid");
        } else {
            rucproveedorRef.current.classList.remove("is-invalid");
        }

        if (nombreproveedorRef.current.value.trim() === "") {
            error++;
            nombreproveedorRef.current.classList.add('is-invalid');
        }
        else {
            nombreproveedorRef.current.classList.remove('is-invalid');
        }
        if (error > 0) {
            return false;
        } else {
            return true;
        }


    }
    const AgregarNuevoProveedor = () => {
        if (ValidarAgregarProveedor()) {
            let url = `${config.endPoint}api/proveedor/agregar`;
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);

            let nuevoproveedor = {
                numerodocumento: rucproveedorRef.current.value.toUpperCase(),
                nombreproveedor: nombreproveedorRef.current.value.toUpperCase(),
                estado: "ACTIVO",
                usuariocrea: UsuarioStorage.id
            }
            // console.log(nuevoproveedor);

            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify(nuevoproveedor)
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json()
            }).then((data) => {

                if (data.content) {
                    //Buscar Lista proveedors de Costo                    
                    settitulotoast("Correcto");
                    settipotoast("success");
                    setmensajetoast(data.message);
                    setabrirToast(true);
                    setmostrarModalAgregarProveedor(false);
                }
                else {
                    settitulotoast("Error");
                    settipotoast("warning");
                    setmensajetoast(data.message);
                    setabrirToast(true);
                }

            }).catch((error) => {
                settitulotoast("Error");
                settipotoast("error");
                setmensajetoast(config.MessageErrorServer);
                setabrirToast(true);
            });

        } else {
            settitulotoast("Alerta");
            settipotoast("warning");
            setmensajetoast("Se detectaron alertas al realizar esta acción. Corrija las alertas y vuelva a interntarlo.");
            setabrirToast(true);
        }
    }
    const MostrarModalBuscarProveedor = () => {
        BuscarListaProveedoresActivos();
        setmostrarModalProveedor(true);
    }
    const ElegirProveedorFiltrado = (row) => {
        // console.log(row);
        setidproveedor(row.id);
        razonsocialRef.current.value = row.nombreproveedor;
        nrorucRef.current.value = row.numerodocumento;
        setmostrarModalProveedor(false);
    }
    //Procedimientos Detalle Gasto
    const ValidarDetalleGasto = () => {
        let error = 0;
        if (nombredetallegastoRef.current.value.trim() === "") {
            error++;
            nombredetallegastoRef.current.classList.add('is-invalid')
        } else {
            nombredetallegastoRef.current.classList.remove('is-invalid')
        }

        if (descripciondetallegastoRef.current.value.trim() === "") {
            error++;
            descripciondetallegastoRef.current.classList.add('is-invalid')
        } else {
            descripciondetallegastoRef.current.classList.remove('is-invalid')
        }

        if (codigodetallegastoRef.current.value.trim() === "") {
            error++;
            codigodetallegastoRef.current.classList.add('is-invalid')
        } else {
            codigodetallegastoRef.current.classList.remove('is-invalid')
        }

        if (error > 0) {
            return false;
        } else {
            return true;
        }

    }
    const AgregarNuevoDetalleGasto = () => {
        if (ValidarDetalleGasto()) {
            let url = `${config.endPoint}api/detallegasto/agregar`;
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);

            let nuevocentro = {
                detallegasto: nombredetallegastoRef.current.value.toUpperCase(),
                descripcion: descripciondetallegastoRef.current.value.toUpperCase(),
                codigo: codigodetallegastoRef.current.value.toUpperCase(),
                estado: "ACTIVO",
                usuariocrea: UsuarioStorage.id
            }

            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify(nuevocentro)
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json()
            }).then((data) => {
                // console.log(data);
                if (data.content) {
                    //Buscar Lista Centros de Costo
                    BuscarListaDetalleGastosActivos();
                    settitulotoast("Correcto");
                    settipotoast("success");
                    setmensajetoast(data.message);
                    setabrirToast(true);
                    setmostrarModalAgregarDetalleGasto(false);
                }
                else {
                    settitulotoast("Alerta");
                    settipotoast("warning");
                    setmensajetoast(data.message);
                    setabrirToast(true);
                }

            }).catch((error) => {
                settitulotoast("Error");
                settipotoast("error");
                setmensajetoast(config.MessageErrorServer);
                setabrirToast(true);
            });

        } else {
            settitulotoast("Alerta");
            settipotoast("warning");
            setmensajetoast("Se detectaron alertas al realizar esta acción. Corrija las alertas y vuelva a interntarlo.");
            setabrirToast(true);
        }
    }
    //Procedimiento Area
    const ValidarArea = () => {
        let error = 0;
        if (nombreareaRef.current.value.trim() === "") {
            error++;
            nombreareaRef.current.classList.add('is-invalid')
        } else {
            nombreareaRef.current.classList.remove('is-invalid')
        }

        if (descripcionareaRef.current.value.trim() === "") {
            error++;
            descripcionareaRef.current.classList.add('is-invalid')
        } else {
            descripcionareaRef.current.classList.remove('is-invalid')
        }

        if (codigoareaRef.current.value.trim() === "") {
            error++;
            codigoareaRef.current.classList.add('is-invalid')
        } else {
            codigoareaRef.current.classList.remove('is-invalid')
        }

        if (error > 0) {
            return false;
        }
        else {
            return true;
        }
    }
    const AgregarNuevoArea = () => {
        if (ValidarArea()) {
            let url = `${config.endPoint}api/area/agregar`;
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);

            let nuevocentro = {
                nombrearea: nombreareaRef.current.value.toUpperCase(),
                descripcion: descripcionareaRef.current.value.toUpperCase(),
                codigo: codigoareaRef.current.value.toUpperCase(),
                estado: "ACTIVO",
                usuariocrea: UsuarioStorage.id
            }

            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify(nuevocentro)
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json()
            }).then((data) => {
                // console.log(data);
                if (data.content) {
                    //Buscar Lista Centros de Costo
                    BuscarListaAreasActivos();
                    settitulotoast("Correcto");
                    settipotoast("success");
                    setmensajetoast(data.message);
                    setabrirToast(true);
                    setmostrarModalAgregarArea(false);
                }
                else {
                    settitulotoast("Error");
                    settipotoast("warning");
                    setmensajetoast(data.message);
                    setabrirToast(true);
                }

            }).catch((error) => {
                settitulotoast("Error");
                settipotoast("error");
                setmensajetoast(config.MessageErrorServer);
                setabrirToast(true);
            });

        } else {
            settitulotoast("Alerta");
            settipotoast("warning");
            setmensajetoast("Se detectaron alertas al realizar esta acción. Corrija las alertas y vuelva a interntarlo.");
            setabrirToast(true);
        }
    }
    //Procedunuento Centro Costo
    const ValidarCentroCosto = () => {
        let error = 0;
        if (nombrecentrocostoRef.current.value.trim() === "") {
            error++;
            nombrecentrocostoRef.current.classList.add('is-invalid')
        } else {
            nombrecentrocostoRef.current.classList.remove('is-invalid')
        }

        if (descripcioncentrocostoRef.current.value.trim() === "") {
            error++;
            descripcioncentrocostoRef.current.classList.add('is-invalid')
        } else {
            descripcioncentrocostoRef.current.classList.remove('is-invalid')
        }

        if (codigocentrocostoRef.current.value.trim() === "") {
            error++;
            codigocentrocostoRef.current.classList.add('is-invalid')
        } else {
            codigocentrocostoRef.current.classList.remove('is-invalid')
        }
        if (error > 0) {
            return false;
        } else {
            return true;
        }

    }
    const AgregarNuevoCentroCosto = () => {
        if (ValidarCentroCosto()) {
            let url = `${config.endPoint}api/centrocosto/agregar`;
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);

            let nuevocentro = {
                nombrecentrocosto: nombrecentrocostoRef.current.value.toUpperCase(),
                descripcion: descripcioncentrocostoRef.current.value.toUpperCase(),
                codigo: codigocentrocostoRef.current.value.toUpperCase(),
                estado: "ACTIVO",
                usuariocrea: UsuarioStorage.id
            }

            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify(nuevocentro)
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json()
            }).then((data) => {
                // console.log(data);
                if (data.content) {
                    //Buscar Lista Centros de Costo
                    BuscarListaCentrosGastoActivos();
                    settitulotoast("Correcto");
                    settipotoast("success");
                    setmensajetoast(data.message);
                    setabrirToast(true);
                    setmostrarModalAgregarCentroCosto(false);
                }
                else {
                    settitulotoast("Error");
                    settipotoast("warning");
                    setmensajetoast(data.message);
                    setabrirToast(true);
                }

            }).catch((error) => {
                settitulotoast("Error");
                settipotoast("error");
                setmensajetoast(config.MessageErrorServer);
                setabrirToast(true);
            });
        } else {
            settitulotoast("Alerta");
            settipotoast("warning");
            setmensajetoast("Se detectaron alertas al realizar esta acción. Corrija las alertas y vuelva a interntarlo.");
            setabrirToast(true);
        }
    }
    //Procedimiento Codigo Gasto
    const ValidarCodigoGasto = () => {
        let error = 0;                

        if (codigocodigogastoref.current.value.trim() === "") {
            error++;
            codigocodigogastoref.current.classList.add('is-invalid')
        } else {
            codigocodigogastoref.current.classList.remove('is-invalid')
        }
        if (error > 0) {
            return false;
        } else {
            return true;
        }

    }
    const AgregarNuevoCodigoGasto = () => {
        if (ValidarCodigoGasto()) {
            let url = `${config.endPoint}api/codigogasto/agregar`;
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);

            let nuevocentro = {
                codigo: codigocodigogastoref.current.value.toUpperCase(),
                descripcion: descripcioncodigogastoRef.current.value.toUpperCase(),                
                estado: "ACTIVO",
                usuariocrea: UsuarioStorage.id
            }

            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify(nuevocentro)
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json()
            }).then((data) => {
                // console.log(data);
                if (data.content) {
                    //Buscar Lista Cosigo Gasto
                    BuscarListaCodigosGastoActivos();
                    settitulotoast("Correcto");
                    settipotoast("success");
                    setmensajetoast(data.message);
                    setabrirToast(true);
                    setmostrarModalAgregarCodigoGasto(false);
                }
                else {
                    settitulotoast("Alerta");
                    settipotoast("warning");
                    setmensajetoast(data.error);
                    setabrirToast(true);
                }

            }).catch((error) => {
                settitulotoast("Error");
                settipotoast("error");
                setmensajetoast(config.MessageErrorServer);
                setabrirToast(true);
            });
        } else {
            settitulotoast("Alerta");
            settipotoast("warning");
            setmensajetoast("Se detectaron alertas al realizar esta acción. Corrija las alertas y vuelva a interntarlo.");
            setabrirToast(true);
        }
    }
    //Modal Fotografias
    const MostrarModalFotografias = async (row) => {
        if (row.fotografia !== "") {
            await setfotografiaActual(row.fotografia);
            setmostarModalFotografia(true);
        } else {
            settitulotoast("Alerta");
            settipotoast("warning");
            setmensajetoast("No se encontro imagen para mostrar.");
            setabrirToast(true);
        }
    }
    //Reporte
    const ReporteCompleto = () => {
        let url = `${config.endPoint}api/cajachica/reporteCompleto/${idproyecto}`;
        let UsuarioStorage = ObtenerUsuario();
        UsuarioStorage = JSON.parse(UsuarioStorage);
        let headers = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + UsuarioStorage.token,
            },
        };

        fetch(url, headers).then((respuesta) => {
            return respuesta.json();
        }).then(async (data) => {
            if (data.content) {
                await CreateJsonToExcel(data.content, 'Reporte Completo_');
            } else {
                settitulotoast("Alerta");
                settipotoast("warning");
                setmensajetoast(data.message);
                setabrirToast(true);
            }
        }).catch((error) => {
            setresumencajachica(null);
            settitulotoast("Error");
            settipotoast("error");
            setmensajetoast(config.MessageErrorServer);
            setabrirToast(true);
        });
    }
    const ReporteRangoFechas = () => {
        let error = 0;
        if (fechaInicioReporteRef.current.value.trim() === "") {
            error++;
            fechaInicioReporteRef.current.classList.add("is-invalid");
        }
        else {
            fechaInicioReporteRef.current.classList.remove("is-invalid");
        }
        if (fechaFinReporteRef.current.value.trim() === "") {
            error++;
            fechaFinReporteRef.current.classList.add("is-invalid");
        } else {
            fechaFinReporteRef.current.classList.remove("is-invalid");
        }
        if (error === 0) {
            let url = `${config.endPoint}api/cajachica/reporteFechas/${idproyecto}/${fechaInicioReporteRef.current.value}/${fechaFinReporteRef.current.value}`;
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data.content.length > 0) {
                    // console.log(data.content);
                    await CreateJsonToExcel(data.content, `Reporte_${fechaInicioReporteRef.current.value}_${fechaFinReporteRef.current.value}`);
                    setmostrarModalReporteRangoFecha(false);
                } else {
                    settitulotoast("Alerta");
                    settipotoast("warning");
                    setmensajetoast("No se encontraron registros para exportar.");
                    setabrirToast(true);
                }
            }).catch((error) => {
                setresumencajachica(null);
                settitulotoast("Error");
                settipotoast("error");
                setmensajetoast(config.MessageErrorServer);
                setabrirToast(true);
            });
        }
    }

    return (
        <Fragment>
            <PaginaCarga loadcarga={loadcarga} />

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={abrirToast} autoHideDuration={4000} onClose={() => { setabrirToast(false) }}>
                <Alert onClose={() => { setabrirToast(false) }} severity={tipotoast}>
                    <AlertTitle>{titulotoast}</AlertTitle>
                    {mensajetoast}
                </Alert>
            </Snackbar>

            <Header />
            {/* titulo */}
            <div className="container mt-1" style={{ backgroundColor: 'rgb(25,118,210)' }}>
                <div className="container  ">
                    <div className="row py-1">
                        <StackMU direction='row'>
                            <div className="col-6 col-sm-6 col-md-8 ">
                                <i title="Regresar" className="fas fa-tag  iconotitulo " style={{ color: 'aliceblue' }}></i>
                                <span className="mx-1" style={{ color: 'aliceblue' }}>CAJA CHICA</span>
                            </div>
                            <div className='text-end ms-auto'>
                                <IconButton onClick={() => { navigate(`/dashboard/${idproyecto}`) }} style={{ color: 'white' }} size='small'>
                                    <ArrowBackIcon fontSize='inherit' />
                                </IconButton>
                            </div>
                        </StackMU>
                    </div>
                </div>
            </div>

            <div className='container border rounded mt-1'>
                <Container fluid className='mt-1'>
                    <Row>
                        <Col sx={12} md={3} >
                            <Form.Control onChange={() => { OnChangeFiltrocajachica() }} ref={filtrocajachicaRef} type="text" placeholder="Valor Buscar" size="sm" />

                        </Col>
                        <Col sx={12} md={3} className="mt-md-0 mt-1">
                            <Button onClick={() => { BuscarListascajachicas(filasporpagina, pagina) }} fullWidth size='small' variant='outlined'>Buscar</Button>
                        </Col>
                        <Col sx={12} md={3} className="mt-md-0 mt-1 ms-auto">
                            <Button onClick={() => {
                                AbrirModalcajachica("NUEVO", null);
                            }} variant="outlined" size='small'>
                                <AddIcon></AddIcon>
                                Agregar
                            </Button>
                        </Col>
                        <Col sx={12} md={3} className='text-end'>
                            <div className="dropdown">
                                <button className="btn btn-sm dropdown-toggle" type="button" id="ListaMenuAcciones" data-bs-toggle="dropdown" aria-expanded="false">
                                    Opciones
                                </button>
                                <ul className="dropdown-menu dropdown-menu-light" aria-labelledby="ListaMenuAcciones">
                                    <li onClick={() => { ReporteCompleto() }}><small className="dropdown-item" ><ContentPasteGoIcon /> Reporte Completo</small></li>
                                    <li onClick={() => { setmostrarModalReporteRangoFecha(true) }}><small className="dropdown-item" ><InsertInvitationIcon /> Reporte por Fecha</small></li>
                                    <li><hr className="dropdown-divider" /></li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                    {
                        resumencajachica ?
                            <Row className=''>
                                <Col sm={12} md={4} className='mt-1'>
                                    <div className='text-center border rounded border-success'>
                                        <small>{`Total Ingresos: S/. ${resumencajachica.ingreso ? Math.round((resumencajachica.ingreso+Number.EPSILON)*100)/100 : 0}`}</small>
                                    </div>
                                </Col>
                                <Col sm={12} md={4} className='mt-1'>
                                    <div className='text-center border rounded border-danger'>
                                        <small>{`Total Egresos: S/. ${resumencajachica.egreso ? Math.round((resumencajachica.egreso+Number.EPSILON)*100)/100 : 0}`}</small>
                                    </div>
                                </Col>
                                <Col sm={12} md={4} className='mt-1'>
                                    <div className='text-center border rounded '>
                                        <small>{`Efectivo: S/. ${resumencajachica.resumen ? Math.round( (resumencajachica.resumen+Number.EPSILON)*100)/100 : 0}`}</small>
                                    </div>
                                </Col>
                            </Row>
                            : <></>
                    }
                </Container>

                {/* Tabla de datos */}
                <div className="row p-1">

                    <TableContainer style={{ maxHeight: window.outerHeight < 760 ? 430 : 650 }}>
                        <Table size='small' stickyHeader>
                            <TableHead>
                                <TableRow >
                                    <TableCell>Acciones</TableCell>
                                    <TableCell>Fecha</TableCell>
                                    <TableCell>Tipo</TableCell>
                                    <TableCell>Monto</TableCell>
                                    <TableCell>Responsable</TableCell>
                                    <TableCell>Proveedor</TableCell>
                                    <TableCell>Fotografia</TableCell>
                                    <TableCell>Activo/Pasivo</TableCell>
                                    <TableCell>Fecha Registro</TableCell>
                                    <TableCell>Usuario crea</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    load ? listacajachica.map((row, index) => {
                                        return (
                                            <TableRow key={index} >
                                                <TableCell>
                                                    <IconButton onClick={() => { AbrirModalcajachica('VER', row) }} size="small">
                                                        <EditIcon fontSize="inherit" />
                                                    </IconButton>
                                                    <IconButton onClick={() => { Eliminarcajachica(row) }} size="small">
                                                        <DeleteIcon fontSize="inherit" />
                                                    </IconButton>
                                                </TableCell>

                                                <TableCell sx={{ fontSize: '0.8em' }}>{row.fecha}</TableCell>
                                                {
                                                    row.tipo === 'EGRESO' ?
                                                        <TableCell sx={{ fontSize: '0.8em' }} className='text-danger'>{row.tipo}</TableCell>
                                                        :
                                                        <TableCell sx={{ fontSize: '0.8em' }} className='text-primary'>{row.tipo}</TableCell>
                                                }
                                                {
                                                    row.tipo === 'EGRESO' ?
                                                        <TableCell sx={{ fontSize: '0.8em' }} className='text-danger'>{`S/. ${row.monto}`}</TableCell>
                                                        :
                                                        <TableCell sx={{ fontSize: '0.8em' }} className='text-primary'>{`S/. ${row.monto}`}</TableCell>
                                                }
                                                <TableCell sx={{ fontSize: '0.8em' }} >{row.responsable}</TableCell>
                                                <TableCell sx={{ fontSize: '0.8em' }} >{row.proveedor}</TableCell>
                                                <TableCell sx={{ fontSize: '0.8em' }}>
                                                    <IconButton onClick={() => { MostrarModalFotografias(row) }} size="small" color='primary'>
                                                        {row.fotografia !== "" ?
                                                            <Inventory2SharpIcon fontSize="inherit" />
                                                            :

                                                            <HideImageIcon fontSize="inherit" color='error' />
                                                        }
                                                    </IconButton>
                                                    {row.fotografia !== "" ? 1 : 0}
                                                </TableCell>
                                                <TableCell sx={{ fontSize: '0.8em' }}>{row.activo}</TableCell>
                                                <TableCell sx={{ fontSize: '0.8em' }}>{moment(row.fechacreacion).format('yyy-MM-DD hh:mm:ss a')}</TableCell>
                                                <TableCell sx={{ fontSize: '0.8em' }}>{row.usuariocrea}</TableCell>
                                                {/* <TableCell >{row.fechamodifica?moment(row.fechamodifica).format('yyy-MM-DD hh:mm:ss'):''}</TableCell>
                                    <TableCell >{row.usuariomodifica}</TableCell> */}
                                            </TableRow>
                                        );
                                    })
                                        : <></>
                                }

                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[50, 100, 150, 200]}
                        component="div"
                        count={cantidadElementos}
                        rowsPerPage={filasporpagina}
                        page={pagina - 1}
                        onPageChange={(ev, npagina) => { cambiarPagina(ev, npagina) }}
                        onRowsPerPageChange={(ev) => { cambiarfilasporpagina(ev) }}
                        labelRowsPerPage={"Filas Por Pagina"}
                    />
                </div>
            </div>

            {/* Modales  */}
            <Modal
                show={mostrarModalDetalla}
                onHide={() => { setmostrarModalDetalla(false) }}
                keyboard={false}
                size="lg"
                centered >
                <Modal.Header>
                    <Modal.Title>
                        {`Registro ${accionactual}`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Container>
                        <Row>
                            <Col sm={12} md={4}>
                                <Form.Group>
                                    <small className="text-primary">Fecha</small>
                                    <Form.Control disabled={controlesreadonly} ref={fechacajachicaRef} type="date" size='sm' placeholder='Ingrese Fecha'></Form.Control>
                                </Form.Group>
                            </Col>
                            <Col sm={12} md={4}>
                                <Form.Group>
                                    <small className="text-primary">Periodo Mes</small>
                                    <Form.Control disabled={controlesreadonly} ref={periodocajachicaRef} type="text" size='sm' placeholder='Ingrese Periodo'></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={6}>
                                <small className="text-primary">Trabajador Responsable</small>
                                <Stack direction='horizontal' gap={2}>
                                    <input disabled={controlesreadonly} ref={trabajadorresponsableRef} className="form-control form-control-sm" list="listaresponsables" placeholder="Elija el trabajador responsable" />
                                    <datalist id="listaresponsables">
                                        {
                                            listatrabajadoresactivos.map((row, index) => {
                                                return (
                                                    <option key={index} value={row.nombres} />
                                                )
                                            })
                                        }
                                    </datalist>
                                    <IconButton disabled={controlesreadonly} onClick={() => { setmostrarModalagregarTrabajador(true) }}>
                                        <AddIcon />
                                    </IconButton>
                                </Stack>
                            </Col>
                            <Col sm={12} md={6}>
                                <Form.Group>
                                    <small className="text-primary">Tipo</small>
                                    <select disabled={controlesreadonly} onChange={(ev) => { CambiarTipoIngreso(ev) }} ref={tipocajachicaRef} className="form-select form-select-sm" defaultValue="">
                                        <option value="" disabled>Seleccione el Tipo</option>
                                        <option value="INGRESO">INGRESO</option>
                                        <option value="EGRESO">EGRESO</option>
                                    </select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Motivo</small>
                                    <Form.Control disabled={controlesreadonly} ref={motivoRef} type="text" size='sm' placeholder='Ingrese el motivo'></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <hr></hr>
                        {
                            activarcontrolesEgreso ?
                                <>
                                    <Row>
                                        <Col md={4}>
                                            <small className="text-primary">RUC / Nro</small>
                                            <Stack direction='horizontal' gap={1}>
                                                <Form.Control disabled={controlesreadonly} onChange={() => { ChangeInputRuc() }} onKeyPress={(ev) => { KeyEnterInputRuc(ev) }} ref={nrorucRef} type="text" size='sm' placeholder='Ingrese el RUC'></Form.Control>
                                                <IconButton disabled={controlesreadonly} onClick={() => { MostrarModalBuscarProveedor() }} title='Buscar'>
                                                    <ManageSearchIcon />
                                                </IconButton>
                                                <IconButton disabled={controlesreadonly} onClick={() => { setmostrarModalAgregarProveedor(true) }}>
                                                    <AddIcon />
                                                </IconButton>
                                            </Stack>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Group>
                                                <small className="text-primary">Razon Social / Nombre</small>
                                                <Form.Control ref={razonsocialRef} disabled type="text" size='sm' placeholder='Razon Social / Nombre no encontrado'></Form.Control>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Col>
                                                <Form.Group>
                                                    <small className="text-primary">Descripción </small>
                                                    <Form.Control disabled={controlesreadonly} ref={descripciongastoRef} type="text" size='sm' placeholder='Ingrese la descripcion'></Form.Control>
                                                </Form.Group>
                                            </Col>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12} md={4}>
                                            <small className="text-primary">Codigo</small>
                                            <Stack direction='horizontal' gap={2}>
                                                <input disabled={controlesreadonly} ref={codigocajachicaRef} className="form-control form-control-sm" list="listacodigoscajachica" placeholder="Ingrese el Codigo" />
                                                <datalist id="listacodigoscajachica">
                                                    {
                                                        listacodigogastoactivo.map((row, index) => {
                                                            return (
                                                                <option key={index} value={row.codigo} />
                                                            )
                                                        })
                                                    }
                                                </datalist>
                                                <IconButton disabled={controlesreadonly} onClick={() => {  setmostrarModalAgregarCodigoGasto(true)}}>
                                                    <AddIcon />
                                                </IconButton>
                                            </Stack>
                                        </Col>
                                        <Col sm={12} md={4}>
                                            <small className="text-primary">Descripción Gasto</small>
                                            <Stack direction='horizontal' gap={2}>
                                                <input disabled={controlesreadonly} ref={detallegastoRef} className="form-control form-control-sm" list="listadetallegasto" placeholder="Elija el Descripción Gasto" />
                                                <datalist id="listadetallegasto">
                                                    {
                                                        listadetallegastosactivos.map((row, index) => {
                                                            return (
                                                                <option key={index} value={row.detallegasto} />

                                                            )
                                                        })
                                                    }
                                                </datalist>
                                                <IconButton disabled={controlesreadonly} onClick={() => { setmostrarModalAgregarDetalleGasto(true) }}>
                                                    <AddIcon />
                                                </IconButton>
                                            </Stack>
                                        </Col>
                                        <Col sm={12} md={4}>
                                            <Form.Group>
                                                <small className="text-primary">Tipo Gasto</small>
                                                <select disabled={controlesreadonly} ref={tipogastoRef} className="form-select form-select-sm" defaultValue="">
                                                    <option value="" disabled>Seleccione el Tipo Gasto</option>
                                                    <option value="FIJO">FIJO</option>
                                                    <option value="VARIABLE">VARIABLE</option>
                                                </select>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12} md={4}>
                                            <small className="text-primary">Area</small>
                                            <Stack direction='horizontal' gap={2}>
                                                <input disabled={controlesreadonly} ref={areacajachicaRef} className="form-control form-control-sm" list="listaarearesponsable" placeholder="Elija el Area" />
                                                <datalist id="listaarearesponsable">
                                                    {
                                                        listareaeasactivas.map((row, index) => {
                                                            return (
                                                                <option key={index} value={row.nombrearea} />
                                                            )
                                                        })
                                                    }
                                                </datalist>
                                                <IconButton disabled={controlesreadonly} onClick={() => { setmostrarModalAgregarArea(true) }}>
                                                    <AddIcon />
                                                </IconButton>
                                            </Stack>

                                        </Col>
                                        <Col sm={12} md={4}>
                                            <small className="text-primary">Centro Gasto</small>
                                            <Stack direction='horizontal' gap={2}>
                                                <input disabled={controlesreadonly} ref={centrocostoRef} className="form-control form-control-sm" list="datasetcentrocosto" placeholder="Elija el Centro Gasto" />
                                                <datalist id="datasetcentrocosto">
                                                    {
                                                        listacentrosgastoactivo.map((row, index) => {
                                                            return (
                                                                <option key={index} value={row.nombrecentrocosto} />
                                                            )
                                                        })
                                                    }
                                                </datalist>
                                                <IconButton disabled={controlesreadonly} onClick={() => { setmostrarModalAgregarCentroCosto(true) }}>
                                                    <AddIcon />
                                                </IconButton>
                                            </Stack>
                                        </Col>
                                        <Col sm={12} md={4}>
                                            <Form.Group>
                                                <small className="text-primary">{'(Activo / Corriente)'}</small>
                                                <select disabled={controlesreadonly} ref={activoacajachicaRef} className="form-select form-select-sm" defaultValue="">
                                                    <option value="" disabled>Seleccione el Tipo</option>
                                                    <option value="ACTIVO">ACTIVO</option>
                                                    <option value="CORRIENTE">CORRIENTE</option>
                                                </select>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12} md={4}>
                                            <Form.Group>
                                                <small className="text-primary">Fecha de Emisión</small>
                                                <Form.Control disabled={controlesreadonly} ref={fechaemisionRef} type="date" size='sm' placeholder='Ingrese Fecha Emisión'></Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} md={4}>
                                            <Form.Group>
                                                <small className="text-primary">Tipo Documento</small>
                                                {/* <select disabled={controlesreadonly} ref={tipodocumentoRef} className="form-select form-select-sm" defaultValue="">
                                                    <option value="" disabled>Seleccione el Tipo Doc.</option>
                                                    <option value="FACTURA">Factura</option>
                                                    <option value="BOLETA">Boleta</option>
                                                </select> */}
                                                <input disabled={controlesreadonly} ref={tipodocumentoRef} className="form-control form-control-sm" list="listatiposdocumento" placeholder="Elija el Tipo Documento" />
                                                <datalist id="listatiposdocumento">
                                                    {/* <option value="" disabled>Seleccione el Tipo Doc.</option> */}
                                                    {
                                                        listatiposdocumento.length > 2 ?
                                                            listatiposdocumento.map((row, index) => {
                                                                return (
                                                                    <option key={index} value={row.tipodocumento} />
                                                                )
                                                            })
                                                            :
                                                            ['BOLETA', 'FACTURA'].map((value, index) => {
                                                                return (
                                                                    <option key={index} value={value} />
                                                                )
                                                            })
                                                    }
                                                </datalist>
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} md={4}>
                                            <Form.Group>
                                                <small className="text-primary">Nro Documento</small>
                                                <Form.Control disabled={controlesreadonly} ref={nrodocumentoRef} type="text" size='sm' placeholder='Ingrese el Nro. Documento'></Form.Control>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </>

                                : <></>
                        }

                        <Row>
                            {
                                activarcontrolesEgreso ?
                                    <>
                                        <Col sm={12} md={3}>
                                            <Form.Group>
                                                <small className="text-primary">IGV (18%)</small>
                                                <Form.Check onChange={() => { CambiarIncluyeImpuesto() }} disabled={!activarcontrolesEgreso} size='sm' ref={incluyeigvRef} type='checkbox' label='Incluye IGV' />
                                                {/* <Form.Control ref={montocajachicaRef} type="number" placeholder='Ingrese el Monto'></Form.Control> */}
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} md={3}>
                                            <Form.Group>
                                                <small className="text-primary">Sub Total</small>
                                                <Form.Control onChange={() => { CalcularMontoImpuesto() }} disabled ref={subtotalRef} size='sm' type="number" placeholder='Ingrese el Sub Total'></Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} md={3}>
                                            <Form.Group>
                                                <small className="text-primary">Monto IGV</small>
                                                <Form.Control disabled ref={montoigvref} type="number" size='sm' placeholder='Ingrese el Sub Total'></Form.Control>
                                            </Form.Group>
                                        </Col>
                                    </>
                                    : <></>
                            }
                            <Col sm={12} md={3}>
                                <Form.Group>
                                    <small className="text-primary">Monto Total</small>
                                    <Form.Control disabled={controlesreadonly} onChange={() => { CalcularMontoImpuesto() }} size='sm' ref={montocajachicaRef} type="number" placeholder='Ingrese el Monto'></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Comentario (opcional)</small>
                                    <Form.Control disabled={controlesreadonly} ref={comentarioRef} type="text" size='sm' placeholder='Ingrese el Comentario'></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Fotografia</small>
                                    {/* <Form.Control  accept='image/*' type="file" size="sm" /> */}
                                    <input accept="image/*" disabled={controlesreadonly} ref={fotografiaRef} className="form-control form-control-sm" type="file"></input>
                                </Form.Group>
                            </Col>
                        </Row>
                        <hr></hr>
                        <StackMU spacing={'auto'} direction='row' justifyContent={'end'} sx={{ mt: 2 }}>
                            <Button onClick={() => { setmostrarModalDetalla(false) }} variant="outlined" size='small'>Cancelar</Button>
                            {/* <Button onClick={() => { SubirFoto() }} variant="outlined" size='small'>Guardar Foto</Button> */}
                            {
                                controlesreadonly ?
                                    <Button onClick={() => { HabilitarModificarRegistroCajaChica() }} variant="outlined" size='small'>Modificar</Button>
                                    : <></>
                            }
                            {
                                !controlesreadonly ?
                                    <Button onClick={() => { Guardarcajachica() }} size='small' variant="outlined">Guardar</Button>
                                    : <></>
                            }
                        </StackMU>
                    </Container>
                </Modal.Body>
            </Modal>

            {/* Modal Fotos*/}
            <Modal
                show={mostarModalFotografia}
                onHide={() => { setmostarModalFotografia(false) }}
                keyboard={false}
                size="lg"
                centered >
                {/* <Modal.Header>
                        <Modal.Title>Fotos</Modal.Title>
                    </Modal.Header> */}

                <Modal.Body style={{ backgroundColor: 'rgb(33,37,41)' }}>
                    <div className="container" >
                        <div className="row" style={{ maxHeight: '600px' }}>
                            <Carousel wrap={false} >
                                <Carousel.Item >
                                    <div >
                                        <img
                                            className="d-block m-auto"
                                            src={`${config.endPoint}api/foto/DownloadFileServer/${fotografiaActual}`}
                                            alt={`Imagen `}
                                            height='550'
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => { window.open(`${config.endPoint}api/foto/DownloadFileServer/${fotografiaActual}`, '_blank'); }}
                                        />
                                    </div>
                                </Carousel.Item>
                            </Carousel>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* modal Agregar Trabajador Responsable */}
            <Modal
                show={mostrarModalagregarTrabajador}
                onHide={() => { setmostrarModalagregarTrabajador(false) }}
                keyboard={false}
                backdrop="static"
                centered
                fullscreen='sm-down'
            >
                <Modal.Body style={{ border: 'solid 2px rgb(48,132,215)', borderRadius: '10px' }}>
                    <h5>Agregar Trabajador</h5>
                    <hr />
                    <Container>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Nombres Trabajador</small>
                                    <Form.Control ref={nombretrabajadorRef} type="text" placeholder='Ingrese Nombre'></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Area</small>
                                    <select ref={areatrabajadorRef} className="form-select" defaultValue="">
                                        <option value="" disabled>Seleccione el Area</option>
                                        {
                                            listareaeasactivas.map((row, index) => {
                                                return (
                                                    <option key={index} value={row.id}>{row.nombrearea}</option>
                                                );
                                            })
                                        }
                                    </select>
                                </Form.Group>
                            </Col>
                        </Row>

                        <hr></hr>
                        <StackMU spacing={'auto'} direction='row' justifyContent={'end'} sx={{ mt: 2 }}>
                            <Button onClick={() => { setmostrarModalagregarTrabajador(false) }} variant="outlined">Cerrar</Button>
                            <Button onClick={() => { AgregarNuevoTrabajador() }} variant="outlined">Guardar</Button>
                        </StackMU>
                    </Container>
                </Modal.Body>
            </Modal>

            {/* Modal Buscar Proveedor */}
            <Modal
                show={mostrarModalProveedor}
                onHide={() => { setmostrarModalProveedor(false) }}
                keyboard={false}
                backdrop="static"
                centered
                fullscreen='sm-down'
            >
                <Modal.Body style={{ border: 'solid 2px rgb(48,132,215)', borderRadius: '10px' }}>
                    <h5>Buscar Proveedor</h5>
                    <hr />
                    <Container>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary" >Buscar</small>
                                    <Form.Control onChange={() => { BuscarListaProveedoresActivos() }} ref={filtrobuscarproveedorRef} type="text" placeholder='Ingrese Valor a Buscar' ></Form.Control>
                                </Form.Group>
                                <small className='text-secondary'>Ingrese el valor a buscar, en el cuadro inferior se mostrara los elementos encontrados. De clic sobre el nombre del elemento para elegirlo.</small>
                            </Col>
                        </Row>
                        {/* Tabla de Datos */}
                        <TableContainer style={{ maxHeight: '300px' }}>
                            <Table size='small' stickyHeader>
                                <TableHead>
                                    <TableRow >
                                        <TableCell>RUC</TableCell>
                                        <TableCell>Proveedor</TableCell>
                                        {/* <TableCell>Permiso</TableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        listaproveedoresactivos.map((row, index) => {
                                            return (
                                                <TableRow key={index} onClick={() => { ElegirProveedorFiltrado(row) }} style={{ cursor: 'pointer' }}>
                                                    <TableCell className='selectElemento'>{row.numerodocumento}</TableCell>
                                                    <TableCell className='selectElemento'>{row.nombreproveedor}</TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }

                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Row>
                            <Col>
                                <Form.Text>{`${listaproveedoresactivos.length} Elemento(s)`}</Form.Text>
                            </Col>
                        </Row>
                        <hr></hr>
                        <StackMU spacing={'auto'} direction='row' justifyContent={'end'} sx={{ mt: 2 }}>
                            <Button onClick={() => { setmostrarModalProveedor(false) }} variant="outlined">Cerrar</Button>
                        </StackMU>
                    </Container>
                </Modal.Body>
            </Modal>

            {/* Modal Agregar Proveedor */}
            <Modal
                show={mostrarModalAgregarProveedor}
                onHide={() => { setmostrarModalAgregarProveedor(false) }}
                keyboard={false}
                backdrop="static"
                centered
                fullscreen='sm-down'
            >
                <Modal.Body style={{ border: 'solid 2px rgb(48,132,215)', borderRadius: '10px' }}>
                    <h5>Agregar Proveedor</h5>
                    <hr />
                    <Container>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Nombre Numero Documento (RUC / ...)</small>
                                    <Form.Control ref={rucproveedorRef} type="number" placeholder='Ingrese Nombre'></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Nombre Proveedor</small>
                                    <Form.Control ref={nombreproveedorRef} type="text" placeholder='Ingrese Descripción'></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>

                        <hr></hr>
                        <StackMU spacing={'auto'} direction='row' justifyContent={'end'} sx={{ mt: 2 }}>
                            <Button onClick={() => { setmostrarModalAgregarProveedor(false) }} variant="outlined">Cerrar</Button>
                            <Button onClick={() => { AgregarNuevoProveedor() }} variant="outlined">Guardar</Button>
                        </StackMU>
                    </Container>
                </Modal.Body>
            </Modal>

            {/* Modal Agregar Detalle Gasto */}
            <Modal
                show={mostrarModalAgregarDetalleGasto}
                onHide={() => { setmostrarModalAgregarDetalleGasto(false) }}
                keyboard={false}
                backdrop="static"
                centered
                fullscreen='sm-down'
            >
                <Modal.Body style={{ border: 'solid 2px rgb(48,132,215)', borderRadius: '10px' }}>
                    <h5>Agregar Detalle Gasto</h5>
                    <hr />
                    <Container>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Nombre Descripción Gasto</small>
                                    <Form.Control ref={nombredetallegastoRef} type="text" placeholder='Ingrese Nombre'></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Detalle</small>
                                    <Form.Control ref={descripciondetallegastoRef} type="text" placeholder='Ingrese Detalle'></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Codigo</small>
                                    <Form.Control ref={codigodetallegastoRef} type="text" placeholder='Ingrese Codigo'></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>

                        <hr></hr>
                        <StackMU spacing={'auto'} direction='row' justifyContent={'end'} sx={{ mt: 2 }}>
                            <Button onClick={() => { setmostrarModalAgregarDetalleGasto(false) }} variant="outlined">Cerrar</Button>
                            <Button onClick={() => { AgregarNuevoDetalleGasto() }} variant="outlined">Guardar</Button>
                        </StackMU>
                    </Container>
                </Modal.Body>
            </Modal>

            {/* Modal Agregar Area */}
            <Modal
                show={mostrarModalAgregarArea}
                onHide={() => { setmostrarModalAgregarArea(false) }}
                keyboard={false}
                backdrop="static"
                centered
                fullscreen='sm-down'
            >
                <Modal.Body style={{ border: 'solid 2px rgb(48,132,215)', borderRadius: '10px' }}>
                    <h5>Agregar Area</h5>
                    <hr />
                    <Container>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Nombre Area</small>
                                    <Form.Control ref={nombreareaRef} type="text" placeholder='Ingrese Nombre'></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Descripción</small>
                                    <Form.Control ref={descripcionareaRef} type="text" placeholder='Ingrese Descripción'></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Codigo</small>
                                    <Form.Control ref={codigoareaRef} type="text" placeholder='Ingrese Codigo'></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>

                        <hr></hr>
                        <StackMU spacing={'auto'} direction='row' justifyContent={'end'} sx={{ mt: 2 }}>
                            <Button onClick={() => { setmostrarModalAgregarArea(false) }} variant="outlined">Cerrar</Button>
                            <Button onClick={() => { AgregarNuevoArea() }} variant="outlined">Guardar</Button>
                        </StackMU>
                    </Container>
                </Modal.Body>
            </Modal>

            {/* Modal Agregar Centro Costo */}
            <Modal
                show={mostrarModalAgregarCentroCosto}
                onHide={() => { setmostrarModalAgregarCentroCosto(false) }}
                keyboard={false}
                backdrop="static"
                centered
                fullscreen='sm-down'
            >
                <Modal.Body style={{ border: 'solid 2px rgb(48,132,215)', borderRadius: '10px' }}>
                    <h5>Agregar Centro Costo</h5>
                    <hr />
                    <Container>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Nombre Centro de Costo</small>
                                    <Form.Control ref={nombrecentrocostoRef} type="text" placeholder='Ingrese Nombre'></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Descripción</small>
                                    <Form.Control ref={descripcioncentrocostoRef} type="text" placeholder='Ingrese Descripción'></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Codigo</small>
                                    <Form.Control ref={codigocentrocostoRef} type="text" placeholder='Ingrese Codigo'></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>

                        <hr></hr>
                        <StackMU spacing={'auto'} direction='row' justifyContent={'end'} sx={{ mt: 2 }}>
                            <Button onClick={() => { setmostrarModalAgregarCentroCosto(false) }} variant="outlined">Cerrar</Button>
                            <Button onClick={() => { AgregarNuevoCentroCosto() }} variant="outlined">Guardar</Button>
                        </StackMU>
                    </Container>
                </Modal.Body>
            </Modal>
            {/* Modal Agregar Codigo Gasto*/}
            <Modal
                show={mostrarModalAgregarCodigoGasto}
                onHide={() => { setmostrarModalAgregarCodigoGasto(false) }}
                keyboard={false}
                backdrop="static"
                centered
                fullscreen='sm-down'
            >
                <Modal.Body style={{ border: 'solid 2px rgb(48,132,215)', borderRadius: '10px' }}>
                    <h5>Agregar Codigo Gasto</h5>
                    <hr />
                    <Container>                    
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Codigo</small>
                                    <Form.Control ref={codigocodigogastoref} type="text" placeholder='Ingrese Codigo'></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>                        
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Descripción</small>
                                    <Form.Control ref={descripcioncodigogastoRef} type="text" placeholder='Ingrese Descripción'></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>

                        <hr></hr>
                        <StackMU spacing={'auto'} direction='row' justifyContent={'end'} sx={{ mt: 2 }}>
                            <Button onClick={() => { setmostrarModalAgregarCodigoGasto(false) }} variant="outlined">Cerrar</Button>
                            <Button onClick={() => { AgregarNuevoCodigoGasto() }} variant="outlined">Guardar</Button>
                        </StackMU>
                    </Container>
                </Modal.Body>
            </Modal>

            {/* Modal Reporte Rango Fecha */}
            <Modal
                show={mostrarModalReporteRangoFecha}
                onHide={() => { setmostrarModalReporteRangoFecha(false) }}
                keyboard={false}
                centered >
                <Modal.Header>
                    <Modal.Title>{`Elegir Fechas`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col>
                                <small className="text-primary">Fecha Inicio</small>
                                <Form.Control ref={fechaInicioReporteRef} size='sm' type="date"></Form.Control>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <small className="text-primary">Fecha Fin</small>
                                <Form.Control ref={fechaFinReporteRef} size='sm' type="date"></Form.Control>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => { ReporteRangoFechas() }} variant="outlined">Descargar</Button>
                </Modal.Footer>

            </Modal>

        </Fragment>
    );
}