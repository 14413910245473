import React, { Fragment, useState, useRef, useEffect } from 'react'
import Header from '../dashboard/header';

// Importaciones Bootstrap
import { Container, Row, Col, Form, Modal, Stack, Carousel } from 'react-bootstrap';


// Importaciones MaterialUI
import { Table, TableContainer, TableHead, TableCell, TableBody, TableRow, TablePagination, IconButton, Button, Snackbar, Alert, AlertTitle } from '@mui/material';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Inventory2SharpIcon from '@mui/icons-material/Inventory2Sharp';
import { useNavigate, useParams } from 'react-router-dom';

import StackMU from '@mui/material/Stack'
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import HideImageIcon from '@mui/icons-material/HideImage';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';

import { ValidarUsuario, ObtenerUsuario, config, CreateJsonToExcel, listarPermisos, ValidarPermisos, LIST_SCREENS } from '../utils/utils';
import PaginaCarga from '../utils/cargapagina'

import moment from 'moment';

export default function Bancos() {
    const navigate = useNavigate();
    let { idproyecto } = useParams();

    //Estados
    const [mostrarModalDetalla, setmostrarModalDetalla] = useState(false);
    const [mostarModalFotografia, setmostarModalFotografia] = useState(false);
    const [mostrarModalProveedor, setmostrarModalProveedor] = useState(false);
    const [mostrarModalAgregarProveedor, setmostrarModalAgregarProveedor] = useState(false);
    // const [mostrarModalagregarTrabajador, setmostrarModalagregarTrabajador] = useState(false);
    const [mostrarModalAgregarDetalleGasto, setmostrarModalAgregarDetalleGasto] = useState(false);
    const [mostrarModalAgregarArea, setmostrarModalAgregarArea] = useState(false);
    const [mostrarModalAgregarCentroCosto, setmostrarModalAgregarCentroCosto] = useState(false);
    const [mostrarModalAgregarCodigoGasto, setmostrarModalAgregarCodigoGasto] = useState(false);
    const [mostrarModalReporteRangoFecha, setmostrarModalReporteRangoFecha] = useState(false);

    const [abrirToast, setabrirToast] = useState(false);
    const [titulotoast, settitulotoast] = useState("");
    const [mensajetoast, setmensajetoast] = useState("");
    const [tipotoast, settipotoast] = useState("error");

    const [listaregistrosbanco, setlistaregistrosbanco] = useState([]);
    // const [listatrabajadoresactivos, setlistatrabajadoresactivos] = useState([]);
    const [listacodigosregistrobanco, setlistacodigosregistrobanco] = useState([]);
    const [listatiposdocumento, setlistatiposdocumento] = useState([]);
    const [listaproveedoresactivos, setlistaproveedoresactivos] = useState([]);
    const [listadetallegastosactivos, setlistadetallegastosactivos] = useState([]);
    const [listareaeasactivas, setlistareaeasactivas] = useState([]);
    const [listacentrosgastoactivo, setlistacentrosgastoactivo] = useState([]);
    const [listacodigogastoactivo, setlistacodigogastoactivo] = useState([]);
    const [idproveedor, setidproveedor] = useState();

    const [accionactual, setaccionactual] = useState("");
    const [controlesreadonly, setcontrolesreadonly] = useState(true);
    const [activarcontrolesEgreso, setactivarcontrolesEgreso] = useState(false);
    const [activarregistrofactura, setactivarregistrofactura] = useState(false);
    const [registrobancoActual, setregistrobancoActual] = useState(null);
    const [resumenregistrobanco, setresumenregistrobanco] = useState(null);
    const [fotografiaActual, setfotografiaActual] = useState('');
    const [tiporegistroactual, settiporegistroactual] = useState('');
    // const [valorinpuesto, setvalorinpuesto] = useState(0.18);

    const [load, setload] = useState(false);
    const [loadcarga, setloadcarga] = useState(true);

    const [cantidadElementos, setcantidadElementos] = useState(0);
    const [filasporpagina, setfilasporpagina] = useState(50);
    const [pagina, setpagina] = useState(1);

    //Referencias controles
    const fecharegistrobancoRef = useRef();
    const periodoregistrobancoRef = useRef();
    const detalleoperacionRef = useRef();
    const descripcionoperacionRef = useRef();
    const tiporegistrobancoRef = useRef();
    const subtiporegistrobancoRef = useRef();
    const montoregistrobancoRef = useRef();
    const totalegresoRef = useRef();
    const observacionesregistrogastoRef = useRef();
    const fechafacturaRef = useRef();
    const descripciongastoRef = useRef();
    const codigogastoRef = useRef();
    const tipogastoRef = useRef();
    const activoaregistrobancoRef = useRef();
    const tipodocumentoRef = useRef();
    const nrodocumentoRef = useRef();
    const fotografiaRef = useRef();
    // const comentarioRef = useRef();
    const tiposervicioRef = useRef();
    const siscontRef = useRef();
    const nrorucRef = useRef();
    const razonsocialRef = useRef();
    const detallegastoRef = useRef();
    const arearegistrobancoRef = useRef();
    const centrocostoRef = useRef();
    const registrofacturaRef = useRef();
    const filtroregistrobancoRef = useRef();

    //Referencias Modal Trabajador
    const nombretrabajadorRef = useRef();
    const areatrabajadorRef = useRef();
    //Referencias Modal Proveedor
    const rucproveedorRef = useRef();
    const nombreproveedorRef = useRef();
    const filtrobuscarproveedorRef = useRef();
    //Referencias Modal Detalle Gasto
    const nombredetallegastoRef = useRef();
    const descripciondetallegastoRef = useRef();
    const codigodetallegastoRef = useRef();
    //Referencias Modal Area
    const nombreareaRef = useRef();
    const descripcionareaRef = useRef();
    const codigoareaRef = useRef();
    //Referencias Modal Centro Costo
    const nombrecentrocostoRef = useRef();
    const descripcioncentrocostoRef = useRef();
    const codigocentrocostoRef = useRef();
    //Referencias Modal Codigo Gasto
    const codigocodigogastoref = useRef();
    const descripcioncodigogastoRef = useRef();
    //Referencias Modal Reporte Rango Fechas
    const fechaInicioReporteRef = useRef();
    const fechaFinReporteRef = useRef();

    //Evento Inicio
    useEffect(() => {
        ValidarAccesos();
    }, []);
    const ValidarAccesos = () => {
        if (ValidarUsuario()) {
            listarPermisos().then((permisos) => {
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_BANCOS, permisos)) {
                    BuscarListasregistrobancos(filasporpagina, pagina);
                } else {
                    settitulotoast("Alerta");
                    settipotoast("warning");
                    setmensajetoast("No tiene permisos para acceder a este Recurso.");
                    setabrirToast(true);
                    setTimeout(() => {
                        navigate('/');
                    }, 1000);
                }
            });
        } else {
            navigate('/login');
        }
    }
    //Procedimientos
    const BuscarListasregistrobancos = (filas, paginaactual) => {
        if (ValidarUsuario()) {
            let url = "";
            if (filtroregistrobancoRef.current.value.trim() === "") {
                url = `${config.endPoint}api/registrobanco/listar/${idproyecto}/${filas}/${paginaactual}`;
            }
            else {
                url = `${config.endPoint}api/registrobanco/listar/${idproyecto}/${filas}/${paginaactual}/${filtroregistrobancoRef.current.value}`;
            }
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data) {
                    BuscarCantidadesGeneralregistrobanco();
                    setlistaregistrosbanco(data.content);
                    setcantidadElementos(data.cantidad)
                    setload(true);
                    setloadcarga(false);
                }
            }).catch((error) => {

                settitulotoast("Alerta");
                settipotoast("warning");
                setmensajetoast(config.MessageErrorServer);
                setabrirToast(true);
            });
        } else {
            navigate("/login");
        }
    }
    const BuscarCantidadesGeneralregistrobanco = () => {
        let url = `${config.endPoint}api/registrobanco/buscar/resumen/${idproyecto}`;
        let UsuarioStorage = ObtenerUsuario();
        UsuarioStorage = JSON.parse(UsuarioStorage);
        let headers = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + UsuarioStorage.token,
            },
        };

        fetch(url, headers).then((respuesta) => {
            return respuesta.json();
        }).then(async (data) => {
            if (data.content.resumen) {
                setresumenregistrobanco(data.content);
            } else {
                setresumenregistrobanco(null);
            }
        }).catch((error) => {
            setresumenregistrobanco(null);
            settitulotoast("Alerta");
            settipotoast("warning");
            setmensajetoast(config.MessageErrorServer);
            setabrirToast(true);
        });
    }
    const BuscarIdregistrobanco = (id) => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/registrobanco/busqueda/${id}`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then(async (data) => {
                    if (data.content) {
                        await setregistrobancoActual(data.content);
                        resolve(data.content);
                    }
                    else {
                        await setregistrobancoActual(null);
                        settitulotoast("Alerta");
                        settipotoast("warning");
                        setmensajetoast(data.message);
                        setabrirToast(true);
                        resolve(null);
                    }
                }).catch(async () => {
                    await setregistrobancoActual(null);
                    settitulotoast("Error");
                    settipotoast("error");
                    setmensajetoast(config.MessageErrorServer);
                    setabrirToast(true);
                    resolve(null);
                });
            }
            else {
                navigate("/login")
                reject(true)
            }
        });
    }
    const BuscarListaCodigosregistrobanco = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/registrobanco/listarcodigos`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        // console.log(data.content);
                        setlistacodigosregistrobanco(data.content);
                        resolve(true);
                    }
                    else {
                        setlistacodigosregistrobanco([]);
                        settitulotoast("Alerta");
                        settipotoast("warning");
                        setmensajetoast(data.message);
                        setabrirToast(true);
                        resolve(false);
                    }
                }).catch(() => {
                    setlistacodigosregistrobanco([]);
                    settitulotoast("Error");
                    settipotoast("error");
                    setmensajetoast(config.MessageErrorServer);
                    setabrirToast(true);
                    resolve(false);
                });
            }
            else {
                navigate("/login")
                reject(true)
            }
        });
    }
    const BuscarTiposDocumentoregistrobanco = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/registrobanco/listartipodocumento`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        // console.log(data.content);
                        setlistatiposdocumento(data.content);
                        resolve(true);
                    }
                    else {
                        setlistatiposdocumento([]);
                        settitulotoast("Alerta");
                        settipotoast("warning");
                        setmensajetoast(data.message);
                        setabrirToast(true);
                        resolve(false);
                    }
                }).catch(() => {
                    setlistatiposdocumento([]);
                    settitulotoast("Error");
                    settipotoast("error");
                    setmensajetoast(config.MessageErrorServer);
                    setabrirToast(true);
                    resolve(false);
                });
            }
            else {
                navigate("/login")
                reject(true)
            }
        });
    }
    const BuscarListaProveedoresActivos = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {

                let url = "";
                // console.log(filtrobuscarproveedorRef);
                if (filtrobuscarproveedorRef.current) {
                    if (filtrobuscarproveedorRef.current.value !== "") {
                        url = `${config.endPoint}api/proveedor/listaractivos/${filtrobuscarproveedorRef.current.value}`;
                    } else {
                        url = `${config.endPoint}api/proveedor/listaractivos`;
                    }

                } else {
                    url = `${config.endPoint}api/proveedor/listaractivos`;
                }

                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        // console.log(data.content);
                        setlistaproveedoresactivos(data.content);
                        resolve(true);
                    }
                    else {
                        setlistaproveedoresactivos([]);
                        settitulotoast("Alerta");
                        settipotoast("warning");
                        setmensajetoast(data.message);
                        setabrirToast(true);
                        resolve(false);
                    }
                }).catch(() => {
                    setlistaproveedoresactivos([]);
                    settitulotoast("Error");
                    settipotoast("error");
                    setmensajetoast(config.MessageErrorServer);
                    setabrirToast(true);
                    resolve(false);
                });
            }
            else {
                navigate("/login")
                reject(true)
            }
        });
    }
    const BuscarListaDetalleGastosActivos = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/detallegasto/listaractivos`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        // console.log(data.content);
                        setlistadetallegastosactivos(data.content);
                        resolve(true);
                    }
                    else {
                        setlistadetallegastosactivos([]);
                        settitulotoast("Alerta");
                        settipotoast("warning");
                        setmensajetoast(data.message);
                        setabrirToast(true);
                        resolve(false);
                    }
                }).catch(() => {
                    setlistadetallegastosactivos([]);
                    settitulotoast("Error");
                    settipotoast("error");
                    setmensajetoast(config.MessageErrorServer);
                    setabrirToast(true);
                    resolve(false);
                });
            }
            else {
                navigate("/login")
                reject(true)
            }
        });
    }
    const BuscarListaAreasActivos = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/area/listaractivo`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        // console.log(data.content);
                        setlistareaeasactivas(data.content);
                        resolve(true);
                    }
                    else {
                        setlistareaeasactivas([]);
                        settitulotoast("Alerta");
                        settipotoast("warning");
                        setmensajetoast(data.message);
                        setabrirToast(true);
                        resolve(false);
                    }
                }).catch(() => {
                    setlistareaeasactivas([]);
                    settitulotoast("Error");
                    settipotoast("error");
                    setmensajetoast(config.MessageErrorServer);
                    setabrirToast(true);
                    resolve(false);
                });
            }
            else {
                navigate("/login")
                reject(true)
            }
        });
    }
    const BuscarListaCentrosGastoActivos = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/centrocosto/listaractivos`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        // console.log(data.content);
                        setlistacentrosgastoactivo(data.content);
                        resolve(true);
                    }
                    else {
                        setlistacentrosgastoactivo([]);
                        settitulotoast("Alerta");
                        settipotoast("warning");
                        setmensajetoast(data.message);
                        setabrirToast(true);
                        resolve(false);
                    }
                }).catch(() => {
                    setlistacentrosgastoactivo([]);
                    settitulotoast("Error");
                    settipotoast("error");
                    setmensajetoast(config.MessageErrorServer);
                    setabrirToast(true);
                    resolve(false);
                });
            }
            else {
                navigate("/login")
                reject(true)
            }
        });
    }
    const BuscarListaCodigosGastoActivos = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/codigogasto/listaractivos`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let headers = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    }
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        // console.log(data.content);
                        setlistacodigogastoactivo(data.content);
                        resolve(true);
                    }
                    else {
                        setlistacodigogastoactivo([]);
                        settitulotoast("Alerta");
                        settipotoast("warning");
                        setmensajetoast(data.message);
                        setabrirToast(true);
                        resolve(false);
                    }
                }).catch(() => {
                    setlistacodigogastoactivo([]);
                    settitulotoast("Error");
                    settipotoast("error");
                    setmensajetoast(config.MessageErrorServer);
                    setabrirToast(true);
                    resolve(false);
                });
            }
            else {
                navigate("/login")
                reject(true)
            }
        });
    }
    const AbrirModalregistrobanco = async (accion, fila) => {
        await setactivarcontrolesEgreso(true);
        await setactivarregistrofactura(true);
        await setidproveedor(null);
        await setmostrarModalDetalla(true);
        await settiporegistroactual('');

        //Buscar Lista Trabajos
        await Promise.all([
            // BuscarListaTrabajadoresActivos(),
            //BuscarListaCodigosregistrobanco(),
            BuscarTiposDocumentoregistrobanco(),
            BuscarListaProveedoresActivos(),
            BuscarListaDetalleGastosActivos(),
            BuscarListaAreasActivos(),
            BuscarListaCentrosGastoActivos(),
            BuscarListaCodigosGastoActivos()
        ]).then(() => {

        });


        if (accion === "VER") {
            setcontrolesreadonly(true);
            setregistrobancoActual(fila);
            await settiporegistroactual(fila.tipo);
            // incluyeigvRef.current.disabled = true;
            await BuscarIdregistrobanco(fila.idregistrobanco).then(async (resp) => {
                if (resp) {
                    fecharegistrobancoRef.current.value = resp.fecha;
                    periodoregistrobancoRef.current.value = resp.periodo;
                    codigogastoRef.current.value = resp.codigogasto;
                    tiporegistrobancoRef.current.value = resp.tipo;
                    subtiporegistrobancoRef.current.value = resp.subtipo;
                    detalleoperacionRef.current.value = resp.detalleoperacion;
                    descripcionoperacionRef.current.value = resp.descripcionoperacion;
                    observacionesregistrogastoRef.current.value = resp.observaciones;
                    tiposervicioRef.current.value = resp.tiposervicio;
                    siscontRef.current.value = resp.siscont;
                    montoregistrobancoRef.current.value = resp.monto;

                    if (resp.tipo === "EGRESO") {
                        await setactivarcontrolesEgreso(true);
                        montoregistrobancoRef.current.value = resp.monto * -1;
                        if (resp.registrofactura === '1') {
                            await setactivarregistrofactura(true);
                            registrofacturaRef.current.checked = true;
                            nrorucRef.current.value = resp.numerodocumento;
                            razonsocialRef.current.value = resp.proveedor;
                            descripciongastoRef.current.value = resp.descripciongasto;
                            detallegastoRef.current.value = resp.detallegasto;
                            tipogastoRef.current.value = resp.tipogasto;
                            arearegistrobancoRef.current.value = resp.arearesponsable;
                            centrocostoRef.current.value = resp.nombrecentrocosto;
                            activoaregistrobancoRef.current.value = resp.activo;
                            fechafacturaRef.current.value = resp.fechafactura;
                            tipodocumentoRef.current.value = resp.tipodocumento;
                            nrodocumentoRef.current.value = resp.nrodocumento;
                            subtiporegistrobancoRef.current.value = resp.subtipo;
                        } else {
                            await setactivarregistrofactura(false);
                            registrofacturaRef.current.checked = false;
                        }
                    } else {
                        await setactivarcontrolesEgreso(false);
                        await setactivarregistrofactura(false);
                    }
                }
            });
        }
        else {
            setcontrolesreadonly(false);
            await setactivarcontrolesEgreso(false);
            await setactivarregistrofactura(false);
            let fechactual = Date.now();
            fecharegistrobancoRef.current.value = moment(fechactual).format("yyyy-MM-DD");
            periodoregistrobancoRef.current.value = moment(fechactual).format("yyyyMM");
            montoregistrobancoRef.current.value = 0;
        }
        setaccionactual(accion);
    }
    const ValidarInformacionEnviar = () => {
        let error = 0;

        if (tiporegistrobancoRef.current.value.trim() !== "") {

            tiporegistrobancoRef.current.classList.remove("is-invalid");

            if (fecharegistrobancoRef.current.value.trim() === "") {
                fecharegistrobancoRef.current.classList.add("is-invalid");
                error++;
            } else {
                fecharegistrobancoRef.current.classList.remove("is-invalid");
            }

            if (periodoregistrobancoRef.current.value.trim() === "") {
                periodoregistrobancoRef.current.classList.add("is-invalid");
                error++;
            } else {
                periodoregistrobancoRef.current.classList.remove("is-invalid");
            }


            if (montoregistrobancoRef.current.value.trim() === "") {
                montoregistrobancoRef.current.classList.add("is-invalid");
                error++;
            } else {
                let _cantidad = parseFloat(montoregistrobancoRef.current.value);
                if (isNaN(_cantidad)) {
                    error++;
                    montoregistrobancoRef.current.classList.add("is-invalid");
                    settitulotoast("Alerta");
                    settipotoast("warning");
                    setmensajetoast("Valor numerico no valido.");
                    setabrirToast(true);
                } else {
                    if (_cantidad > 0) {
                        montoregistrobancoRef.current.classList.remove("is-invalid");
                    } else {
                        error++;
                        montoregistrobancoRef.current.classList.add("is-invalid");
                        settitulotoast("Alerta");
                        settipotoast("warning");
                        setmensajetoast("Verifique el las cantidades, el valor numerico debe ser mayor a 0.");
                        setabrirToast(true);
                    }
                }
            }

            if (codigogastoRef.current.value === "") {
                codigogastoRef.current.classList.remove("is-invalid");
            } else {
                // Validar que el codigo ingresado exista en la lista de cogidos
                let _codigogasto = BuscarIdCodigoGasto();
                if (_codigogasto) {
                    codigogastoRef.current.classList.remove("is-invalid");
                } else {
                    error++;
                    codigogastoRef.current.classList.add("is-invalid");
                }
            }

            if (subtiporegistrobancoRef.current.value === "") {
                subtiporegistrobancoRef.current.classList.add('is-invalid');
                error++;
            } else {
                subtiporegistrobancoRef.current.classList.remove('is-invalid');
            }

            if (registrofacturaRef.current) {
                if (registrofacturaRef.current.checked) {

                    if (nrorucRef.current.value.trim() === "") {
                        nrorucRef.current.classList.add("is-invalid");
                        error++;
                    } else {
                        nrorucRef.current.classList.remove("is-invalid");
                    }
                    if (idproveedor) {
                        nrorucRef.current.classList.remove("is-invalid");
                    } else {
                        nrorucRef.current.classList.add("is-invalid");
                        error++;
                    }


                    if (detallegastoRef.current.value.trim() === "") {
                        detallegastoRef.current.classList.add("is-invalid");
                        error++;
                    } else {
                        detallegastoRef.current.classList.remove("is-invalid");
                    }

                    if (tipogastoRef.current.value.trim() === "") {
                        tipogastoRef.current.classList.add("is-invalid");
                        error++;
                    } else {
                        tipogastoRef.current.classList.remove("is-invalid");
                    }

                    if (arearegistrobancoRef.current.value.trim() === "") {
                        arearegistrobancoRef.current.classList.add("is-invalid");
                        error++;
                    } else {
                        arearegistrobancoRef.current.classList.remove("is-invalid");
                    }

                    if (centrocostoRef.current.value.trim() === "") {
                        centrocostoRef.current.classList.add("is-invalid");
                        error++;
                    } else {
                        centrocostoRef.current.classList.remove("is-invalid");
                    }

                    if (centrocostoRef.current.value.trim() === "") {
                        centrocostoRef.current.classList.add("is-invalid");
                        error++;
                    } else {
                        centrocostoRef.current.classList.remove("is-invalid");
                    }

                    if (activoaregistrobancoRef.current.value.trim() === "") {
                        activoaregistrobancoRef.current.classList.add("is-invalid");
                        error++;
                    } else {
                        activoaregistrobancoRef.current.classList.remove("is-invalid");
                    }

                    if (fechafacturaRef.current.value.trim() === "") {
                        fechafacturaRef.current.classList.add("is-invalid");
                        error++;
                    } else {
                        fechafacturaRef.current.classList.remove("is-invalid");
                    }

                    if (tipodocumentoRef.current.value.trim() === "") {
                        tipodocumentoRef.current.classList.add("is-invalid");
                        error++;
                    } else {
                        tipodocumentoRef.current.classList.remove("is-invalid");
                    }

                    if (nrodocumentoRef.current.value.trim() === "") {
                        nrodocumentoRef.current.classList.add("is-invalid");
                        error++;
                    } else {
                        nrodocumentoRef.current.classList.remove("is-invalid");
                    }
                }


            }
        } else {
            error++;
            tiporegistrobancoRef.current.classList.add("is-invalid");
            settitulotoast("Alerta");
            settipotoast("warning");
            setmensajetoast("No se ha seleccionado el tipo de registro a agregar. Seleccione el Tipo y vuelva a intentarlo.");
            setabrirToast(true);
        }

        if (error > 0) {
            return false;
        } else {
            return true;
        }

    }
    const Guardarregistrobanco = () => {
        if (ValidarUsuario()) {
            if (ValidarInformacionEnviar()) {
                if (accionactual === "NUEVO") {
                    // console.log("nuevo");
                    if (fotografiaRef.current.files.length > 0) {
                        CrearNuevoregistrobancoFoto();
                    } else {
                        CrearNuevoregistrobanco();
                    }
                } else {
                    if (fotografiaRef.current.files.length > 0) {
                        ModificarregistrobancoFoto();

                    } else {
                        Modificarregistrobanco();
                    }
                }
            } else {
                settitulotoast("Alerta");
                settipotoast("warning");
                setmensajetoast("Se detectaron alertas al realizar esta acción. Corrija las alertas y vuelva a interntarlo.");
                setabrirToast(true);
            }
        }
        else {
            navigate("/login");
        }
    }
    // const BuscarIdTrabajador = () => {
    //     let idtrabajador = listatrabajadoresactivos.find(x => x.nombres === trabajadorresponsableRef.current.value);
    //     if (idtrabajador) {
    //         return idtrabajador;
    //     }
    //     else {
    //         trabajadorresponsableRef.current.value = "";
    //         trabajadorresponsableRef.current.classList.add('is-invalid');
    //         return null;
    //     }
    // }
    const KeyEnterInputRuc = (ev) => {
        if (ev.key === "Enter") {
            BuscarIdProveedor();
        }
    }
    const ChangeInputRuc = () => {
        razonsocialRef.current.value = ''
        setidproveedor(null);
    }
    const BuscarIdProveedor = () => {
        return new Promise((resolve, reject) => {
            if (ValidarUsuario()) {
                let url = `${config.endPoint}api/proveedor/busquedacod`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);
                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify({ numerodocumento: nrorucRef.current.value })
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {
                    if (data.content) {
                        nrorucRef.current.classList.remove("is-invalid");
                        setidproveedor(data.content.id);
                        razonsocialRef.current.value = data.content.nombreproveedor
                        resolve(true);
                    }
                    else {
                        nrorucRef.current.classList.add("is-invalid");
                        setidproveedor(null);
                        razonsocialRef.current.value = "";
                        settitulotoast("Alerta");
                        settipotoast("warning");
                        setmensajetoast("No se encotraron datos del Proveedor.");
                        setabrirToast(true);
                        resolve(true)
                    }
                }).catch(() => {
                    setidproveedor(null);
                    razonsocialRef.current.value = "";
                    settitulotoast("Error");
                    settipotoast("error");
                    setmensajetoast(config.MessageErrorServer);
                    setabrirToast(true);
                    resolve(false);
                });
            }
            else {
                reject(true);
            }
        });
    }
    const BuscarIdDetalleGasto = () => {
        let iddetallegasto = listadetallegastosactivos.find(x => x.detallegasto === detallegastoRef.current.value)
        if (iddetallegasto) {
            return iddetallegasto;
        }
        else {
            detallegastoRef.current.value = "";
            detallegastoRef.current.classList.add("is-invalid");
            return null;
        }
    }
    const BuscarIdArea = () => {
        let idarea = listareaeasactivas.find(x => x.nombrearea === arearegistrobancoRef.current.value);
        if (idarea) {
            return idarea;
        } else {
            arearegistrobancoRef.current.value = "";
            arearegistrobancoRef.current.classList.add("is-invalid");
        }
    }
    const BuscarIdCentroCosto = () => {
        let idcentrocostro = listacentrosgastoactivo.find(x => x.nombrecentrocosto === centrocostoRef.current.value);
        if (idcentrocostro) {
            return idcentrocostro;
        } else {
            centrocostoRef.current.value = "";
            centrocostoRef.current.classList.add("is-invalid");
        }
    }
    const BuscarIdCodigoGasto = () => {
        if (codigogastoRef.current.value.trim() !== '') {
            let idcodigofind = listacodigogastoactivo.find(x => x.codigo === codigogastoRef.current.value)
            if (idcodigofind) {
                return idcodigofind;
            }
            else {
                codigogastoRef.current.value = "";
                codigogastoRef.current.classList.add("is-invalid");
                return null;
            }
        } else {
            return null;
        }
    }
    const ActualizarMontoRegistro = (ev) => {
        if (totalegresoRef.current) {
            totalegresoRef.current.value = montoregistrobancoRef.current.value;
        }
    }
    const CrearNuevoregistrobanco = () => {
        //Buscar Identificadores Necesarios        

        let url = `${config.endPoint}api/registrobanco/agregar`;
        let UsuarioStorage = ObtenerUsuario();
        UsuarioStorage = JSON.parse(UsuarioStorage);
        let nuevoreg = {};

        let _codigogasto = BuscarIdCodigoGasto();
        if (tiporegistrobancoRef.current.value === "EGRESO") {
            if (registrofacturaRef.current.checked) {
                let _detallegasto = BuscarIdDetalleGasto();
                let _area = BuscarIdArea()
                let _centrocosto = BuscarIdCentroCosto();

                if (_detallegasto && _area && _centrocosto) {
                    nuevoreg = {
                        fecha: fecharegistrobancoRef.current.value,
                        periodo: periodoregistrobancoRef.current.value,
                        detalleoperacion: detalleoperacionRef.current.value.toUpperCase(),
                        descripcionoperacion: descripcionoperacionRef.current.value.toUpperCase(),
                        tipo: tiporegistrobancoRef.current.value.toUpperCase(),
                        subtipo: subtiporegistrobancoRef.current.value.toUpperCase(),
                        monto: montoregistrobancoRef.current.value * -1,
                        observaciones: observacionesregistrogastoRef.current.value.toUpperCase(),
                        fechafactura: fechafacturaRef.current.value,
                        descripciongasto: descripciongastoRef.current.value.toUpperCase(),
                        codigogasto: codigogastoRef.current.value.toUpperCase(),
                        tipogasto: tipogastoRef.current.value.toUpperCase(),
                        activo: activoaregistrobancoRef.current.value.toUpperCase(),
                        tipodocumento: tipodocumentoRef.current.value.toUpperCase(),
                        nrodocumento: nrodocumentoRef.current.value.toUpperCase(),
                        tiposervicio: tiposervicioRef.current.value.toUpperCase(),
                        siscont: siscontRef.current.value.toUpperCase(),
                        registrofactura: registrofacturaRef.current.checked ? 1 : 0,
                        idproveedor: idproveedor,
                        iddetallegasto: _detallegasto.id,
                        idarea: _area.id,
                        idcentrocosto: _centrocosto.id,
                        idcodigogasto: _codigogasto ? _codigogasto.id : null,
                        idproyecto: idproyecto,
                        usuariocrea: UsuarioStorage.id
                    }
                }
            } else {
                nuevoreg = {
                    fecha: fecharegistrobancoRef.current.value,
                    periodo: periodoregistrobancoRef.current.value,
                    codigogasto: codigogastoRef.current.value.toUpperCase(),
                    idcodigogasto: _codigogasto ? _codigogasto.id : null,
                    tipo: tiporegistrobancoRef.current.value.toUpperCase(),
                    subtipo: subtiporegistrobancoRef.current.value.toUpperCase(),
                    detalleoperacion: detalleoperacionRef.current.value.toUpperCase(),
                    descripcionoperacion: descripcionoperacionRef.current.value.toUpperCase(),
                    monto: montoregistrobancoRef.current.value * -1,
                    observaciones: observacionesregistrogastoRef.current.value.toUpperCase(),
                    tiposervicio: tiposervicioRef.current.value.toUpperCase(),
                    siscont: siscontRef.current.value.toUpperCase(),
                    idproyecto: idproyecto,
                    usuariocrea: UsuarioStorage.id
                }
            }

        } else {
            nuevoreg = {
                fecha: fecharegistrobancoRef.current.value,
                periodo: periodoregistrobancoRef.current.value,
                codigogasto: codigogastoRef.current.value.toUpperCase(),
                idcodigogasto: _codigogasto ? _codigogasto.id : null,
                tipo: tiporegistrobancoRef.current.value.toUpperCase(),
                subtipo: subtiporegistrobancoRef.current.value.toUpperCase(),
                detalleoperacion: detalleoperacionRef.current.value.toUpperCase(),
                descripcionoperacion: descripcionoperacionRef.current.value.toUpperCase(),
                monto: montoregistrobancoRef.current.value,
                observaciones: observacionesregistrogastoRef.current.value.toUpperCase(),
                tiposervicio: tiposervicioRef.current.value.toUpperCase(),
                siscont: siscontRef.current.value.toUpperCase(),
                idproyecto: idproyecto,
                usuariocrea: UsuarioStorage.id
            }
        }

        let headers = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + UsuarioStorage.token,
            },
            body: JSON.stringify(nuevoreg)
        };
        // console.log(nuevoreg);

        fetch(url, headers).then((respuesta) => {
            return respuesta.json()
        }).then((data) => {
            // console.log(data);
            if (data.content) {

                //Buscar Lista Centros de Costo
                BuscarListasregistrobancos(filasporpagina, pagina);
                settitulotoast("Correcto");
                settipotoast("success");
                setmensajetoast(data.message);
                setabrirToast(true);
                setmostrarModalDetalla(false);
            }
            else {
                settitulotoast("Error");
                settipotoast("warning");
                setmensajetoast(data.message);
                setabrirToast(true);
            }

        }).catch((error) => {
            settitulotoast("Error");
            settipotoast("error");
            setmensajetoast(config.MessageErrorServer);
            setabrirToast(true);
        });

    }
    const CrearNuevoregistrobancoFoto = async () => {
        // return new Promise((resolve, reject) => {
        if (ValidarUsuario()) {

            let url = `${config.endPoint}api/registrobanco/agregarfoto`;
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);

            let formData = new FormData();
            formData.append('file', fotografiaRef.current.files[0], fotografiaRef.current.files[0].name);

            //Buscar Identificadores Necesarios            

            let _codigogasto = BuscarIdCodigoGasto();
            if (tiporegistrobancoRef.current.value === "EGRESO") {
                if (registrofacturaRef.current.checked) {
                    let _detallegasto = BuscarIdDetalleGasto();
                    let _area = BuscarIdArea()
                    let _centrocosto = BuscarIdCentroCosto();

                    if (_detallegasto && _area && _centrocosto) {

                        formData.append('fecha', fecharegistrobancoRef.current.value);
                        formData.append('periodo', periodoregistrobancoRef.current.value);
                        formData.append('detalleoperacion', detalleoperacionRef.current.value.toUpperCase());
                        formData.append('descripcionoperacion', descripcionoperacionRef.current.value.toUpperCase());
                        formData.append('tipo', tiporegistrobancoRef.current.value.toUpperCase());
                        formData.append('subtipo', subtiporegistrobancoRef.current.value.toUpperCase());
                        formData.append('monto', montoregistrobancoRef.current.value * -1);
                        formData.append('observaciones', observacionesregistrogastoRef.current.value.toUpperCase());
                        formData.append('fechafactura', fechafacturaRef.current.value);
                        formData.append('descripciongasto', descripciongastoRef.current.value.toUpperCase());
                        formData.append('codigogasto', codigogastoRef.current.value.toUpperCase());
                        formData.append('tipogasto', tipogastoRef.current.value.toUpperCase());
                        formData.append('activo', activoaregistrobancoRef.current.value.toUpperCase());
                        formData.append('tipodocumento', tipodocumentoRef.current.value.toUpperCase());
                        formData.append('nrodocumento', nrodocumentoRef.current.value.toUpperCase());
                        formData.append('tiposervicio', tiposervicioRef.current.value.toUpperCase());
                        formData.append('siscont', siscontRef.current.value.toUpperCase());
                        formData.append('registrofactura', registrofacturaRef.current.checked ? 1 : 0);
                        formData.append('idproveedor', idproveedor);
                        formData.append('iddetallegasto', _detallegasto.id);
                        formData.append('idarea', _area.id);
                        formData.append('idcentrocosto', _centrocosto.id);
                        formData.append('idcodigogasto', _codigogasto ? _codigogasto.id : null);
                        formData.append('idproyecto', idproyecto);
                        formData.append('usuariocrea', UsuarioStorage.id);
                    }
                } else {
                    formData.append('fecha', fecharegistrobancoRef.current.value);
                    formData.append('periodo', periodoregistrobancoRef.current.value);
                    formData.append('codigogasto', codigogastoRef.current.value.toUpperCase());
                    formData.append('idcodigogasto', _codigogasto ? _codigogasto.id : null);
                    formData.append('tipo', tiporegistrobancoRef.current.value.toUpperCase());
                    formData.append('subtipo', subtiporegistrobancoRef.current.value.toUpperCase());
                    formData.append('detalleoperacion', detalleoperacionRef.current.value.toUpperCase());
                    formData.append('descripcionoperacion', descripcionoperacionRef.current.value.toUpperCase());
                    formData.append('monto', montoregistrobancoRef.current.value * -1);
                    formData.append('observaciones', observacionesregistrogastoRef.current.value.toUpperCase());
                    formData.append('tiposervicio', tiposervicioRef.current.value.toUpperCase());
                    formData.append('siscont', siscontRef.current.value.toUpperCase());
                    formData.append('idproyecto', idproyecto);
                    formData.append('usuariocrea', UsuarioStorage.id);
                }

            } else {
                formData.append('fecha', fecharegistrobancoRef.current.value);
                formData.append('periodo', periodoregistrobancoRef.current.value);
                formData.append('codigogasto', codigogastoRef.current.value.toUpperCase());
                formData.append('idcodigogasto', _codigogasto ? _codigogasto.id : null);
                formData.append('tipo', tiporegistrobancoRef.current.value.toUpperCase());
                formData.append('subtipo', subtiporegistrobancoRef.current.value.toUpperCase());
                formData.append('detalleoperacion', detalleoperacionRef.current.value.toUpperCase());
                formData.append('descripcionoperacion', descripcionoperacionRef.current.value.toUpperCase());
                formData.append('monto', montoregistrobancoRef.current.value);
                formData.append('observaciones', observacionesregistrogastoRef.current.value.toUpperCase());
                formData.append('tiposervicio', tiposervicioRef.current.value.toUpperCase());
                formData.append('siscont', siscontRef.current.value.toUpperCase());
                formData.append('idproyecto', idproyecto);
                formData.append('usuariocrea', UsuarioStorage.id);
            }
            let headers = {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: formData
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json()
            }).then((data) => {
                // console.log(data);
                if (data.content) {
                    // console.log(data.content);
                    //Buscar Lista Centros de Costo
                    BuscarListasregistrobancos(filasporpagina, pagina);
                    settitulotoast("Correcto");
                    settipotoast("success");
                    setmensajetoast(data.message);
                    setabrirToast(true);
                    setmostrarModalDetalla(false);
                }
                else {
                    settitulotoast("Alerta");
                    settipotoast("warning");
                    setmensajetoast(data.message);
                    setabrirToast(true);
                }

            }).catch((error) => {
                settitulotoast("Error");
                settipotoast("error");
                setmensajetoast(config.MessageErrorServer);
                setabrirToast(true);
            });
        }
        else {
            navigate("/login")
            // reject(true)
        }
        // });
    }
    const HabilitarModificarRegistroregistrobanco = () => {
        setcontrolesreadonly(false);
        if (tiporegistrobancoRef.current.value === "EGRESO") {
            setidproveedor(registrobancoActual.idproveedor);
        }
        else {

        }
    }
    const Modificarregistrobanco = () => {

        //Buscar Identificadores Necesarios     
        let url = `${config.endPoint}api/registrobanco/modificar`;
        let UsuarioStorage = ObtenerUsuario();
        UsuarioStorage = JSON.parse(UsuarioStorage);
        let nuevoreg = {};

        let _codigogasto = BuscarIdCodigoGasto();
        if (tiporegistrobancoRef.current.value === "EGRESO") {
            if (registrofacturaRef.current.checked) {
                let _detallegasto = BuscarIdDetalleGasto();
                let _area = BuscarIdArea()
                let _centrocosto = BuscarIdCentroCosto();

                if (_detallegasto && _area && _centrocosto) {
                    nuevoreg = {
                        idregistrobanco: registrobancoActual.idregistrobanco,
                        fecha: fecharegistrobancoRef.current.value,
                        periodo: periodoregistrobancoRef.current.value,
                        detalleoperacion: detalleoperacionRef.current.value.toUpperCase(),
                        descripcionoperacion: descripcionoperacionRef.current.value.toUpperCase(),
                        tipo: tiporegistrobancoRef.current.value.toUpperCase(),
                        subtipo: subtiporegistrobancoRef.current.value.toUpperCase(),
                        monto: montoregistrobancoRef.current.value * -1,
                        observaciones: observacionesregistrogastoRef.current.value.toUpperCase(),
                        fechafactura: fechafacturaRef.current.value,
                        descripciongasto: descripciongastoRef.current.value.toUpperCase(),
                        codigogasto: codigogastoRef.current.value.toUpperCase(),
                        idcodigogasto: _codigogasto ? _codigogasto.id : null,
                        tipogasto: tipogastoRef.current.value.toUpperCase(),
                        activo: activoaregistrobancoRef.current.value.toUpperCase(),
                        tipodocumento: tipodocumentoRef.current.value.toUpperCase(),
                        nrodocumento: nrodocumentoRef.current.value.toUpperCase(),
                        tiposervicio: tiposervicioRef.current.value.toUpperCase(),
                        siscont: siscontRef.current.value.toUpperCase(),
                        registrofactura: registrofacturaRef.current.checked ? 1 : 0,
                        idproveedor: idproveedor,
                        iddetallegasto: _detallegasto.id,
                        idarea: _area.id,
                        idcentrocosto: _centrocosto.id,
                        idproyecto: idproyecto,
                        usuariomodifica: UsuarioStorage.id
                    }
                }
            } else {

                nuevoreg = {
                    idregistrobanco: registrobancoActual.idregistrobanco,
                    fecha: fecharegistrobancoRef.current.value,
                    periodo: periodoregistrobancoRef.current.value,
                    codigogasto: codigogastoRef.current.value.toUpperCase(),
                    idcodigogasto: _codigogasto ? _codigogasto.id : null,
                    tipo: tiporegistrobancoRef.current.value.toUpperCase(),
                    subtipo: subtiporegistrobancoRef.current.value.toUpperCase(),
                    detalleoperacion: detalleoperacionRef.current.value.toUpperCase(),
                    descripcionoperacion: descripcionoperacionRef.current.value.toUpperCase(),
                    monto: montoregistrobancoRef.current.value * -1,
                    observaciones: observacionesregistrogastoRef.current.value.toUpperCase(),
                    tiposervicio: tiposervicioRef.current.value.toUpperCase(),
                    siscont: siscontRef.current.value.toUpperCase(),
                    registrofactura: 0,
                    idproyecto: idproyecto,
                    usuariomodifica: UsuarioStorage.id,

                    idproveedor: null,
                    descripciongasto: '',
                    iddetallegasto: null,
                    tipogasto: '',
                    idarea: null,
                    idcentrocosto: null,
                    activo: '',
                    fechafactura: null,
                    tipodocumento: '',
                    nrodocumento: '',
                }
            }

        } else {
            nuevoreg = {
                idregistrobanco: registrobancoActual.idregistrobanco,
                fecha: fecharegistrobancoRef.current.value,
                periodo: periodoregistrobancoRef.current.value,
                codigogasto: codigogastoRef.current.value.toUpperCase(),
                idcodigogasto: _codigogasto ? _codigogasto.id : null,
                tipo: tiporegistrobancoRef.current.value.toUpperCase(),
                subtipo: subtiporegistrobancoRef.current.value.toUpperCase(),
                detalleoperacion: detalleoperacionRef.current.value.toUpperCase(),
                descripcionoperacion: descripcionoperacionRef.current.value.toUpperCase(),
                monto: montoregistrobancoRef.current.value,
                observaciones: observacionesregistrogastoRef.current.value.toUpperCase(),
                tiposervicio: tiposervicioRef.current.value.toUpperCase(),
                siscont: siscontRef.current.value.toUpperCase(),
                registrofactura: 0,
                idproyecto: idproyecto,
                usuariomodifica: UsuarioStorage.id,

                idproveedor: null,
                descripciongasto: '',
                iddetallegasto: null,
                tipogasto: '',
                idarea: null,
                idcentrocosto: null,
                activo: '',
                fechafactura: null,
                tipodocumento: '',
                nrodocumento: '',
            }
        }

        let headers = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + UsuarioStorage.token,
            },
            body: JSON.stringify(nuevoreg)
        };


        fetch(url, headers).then((respuesta) => {
            return respuesta.json()
        }).then((data) => {
            // console.log(data);
            if (data.content) {
                // console.log(data.content);
                //Buscar Lista Centros de Costo
                BuscarListasregistrobancos(filasporpagina, pagina);
                settitulotoast("Correcto");
                settipotoast("success");
                setmensajetoast(data.message);
                setabrirToast(true);
                setmostrarModalDetalla(false);
            }
            else {
                settitulotoast("Error");
                settipotoast("warning");
                setmensajetoast(data.message);
                setabrirToast(true);
            }

        }).catch((error) => {
            settitulotoast("Error");
            settipotoast("error");
            setmensajetoast(config.MessageErrorServer);
            setabrirToast(true);
        });

    }
    const ModificarregistrobancoFoto = () => {
        // return new Promise((resolve, reject) => {
        if (ValidarUsuario()) {

            let url = `${config.endPoint}api/registrobanco/modificarfoto`;
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);

            let formData = new FormData();
            formData.append('file', fotografiaRef.current.files[0], fotografiaRef.current.files[0].name);
            //formData.append('idregistrobanco', idregistrobanco); 
            let nuevoreg = {};

            //Buscar Identificadores Necesarios                      
            let _codigogasto = BuscarIdCodigoGasto();
            if (tiporegistrobancoRef.current.value === "EGRESO") {
                if (registrofacturaRef.current.checked) {
                    let _detallegasto = BuscarIdDetalleGasto();
                    let _area = BuscarIdArea()
                    let _centrocosto = BuscarIdCentroCosto();

                    if (_detallegasto && _area && _centrocosto) {
                        nuevoreg = {
                            idregistrobanco: registrobancoActual.idregistrobanco,
                            fecha: fecharegistrobancoRef.current.value,
                            periodo: periodoregistrobancoRef.current.value,
                            detalleoperacion: detalleoperacionRef.current.value.toUpperCase(),
                            descripcionoperacion: descripcionoperacionRef.current.value.toUpperCase(),
                            tipo: tiporegistrobancoRef.current.value.toUpperCase(),
                            subtipo: subtiporegistrobancoRef.current.value.toUpperCase(),
                            monto: montoregistrobancoRef.current.value * -1,
                            observaciones: observacionesregistrogastoRef.current.value.toUpperCase(),
                            fechafactura: fechafacturaRef.current.value,
                            descripciongasto: descripciongastoRef.current.value.toUpperCase(),
                            codigogasto: codigogastoRef.current.value.toUpperCase(),
                            tipogasto: tipogastoRef.current.value.toUpperCase(),
                            activo: activoaregistrobancoRef.current.value.toUpperCase(),
                            tipodocumento: tipodocumentoRef.current.value.toUpperCase(),
                            nrodocumento: nrodocumentoRef.current.value.toUpperCase(),
                            tiposervicio: tiposervicioRef.current.value.toUpperCase(),
                            siscont: siscontRef.current.value.toUpperCase(),
                            registrofactura: registrofacturaRef.current.checked ? 1 : 0,
                            idproveedor: idproveedor,
                            iddetallegasto: _detallegasto.id,
                            idarea: _area.id,
                            idcentrocosto: _centrocosto.id,
                            idcodigogasto: _codigogasto ? _codigogasto.id : null,
                            idproyecto: idproyecto,
                            usuariomodifica: UsuarioStorage.id
                        }
                        formData.append('nuevoreg', JSON.stringify(nuevoreg));
                    }
                } else {
                    nuevoreg = {
                        idregistrobanco: registrobancoActual.idregistrobanco,
                        fecha: fecharegistrobancoRef.current.value,
                        periodo: periodoregistrobancoRef.current.value,
                        codigogasto: codigogastoRef.current.value.toUpperCase(),
                        idcodigogasto: _codigogasto ? _codigogasto.id : null,
                        tipo: tiporegistrobancoRef.current.value.toUpperCase(),
                        subtipo: subtiporegistrobancoRef.current.value.toUpperCase(),
                        detalleoperacion: detalleoperacionRef.current.value.toUpperCase(),
                        descripcionoperacion: descripcionoperacionRef.current.value.toUpperCase(),
                        monto: montoregistrobancoRef.current.value * -1,
                        observaciones: observacionesregistrogastoRef.current.value.toUpperCase(),
                        tiposervicio: tiposervicioRef.current.value.toUpperCase(),
                        siscont: siscontRef.current.value.toUpperCase(),
                        registrofactura: 0,
                        idproyecto: idproyecto,
                        usuariomodifica: UsuarioStorage.id,

                        idproveedor: null,
                        descripciongasto: '',
                        iddetallegasto: null,
                        tipogasto: '',
                        idarea: null,
                        idcentrocosto: null,
                        activo: '',
                        fechafactura: null,
                        tipodocumento: '',
                        nrodocumento: '',
                    }
                    formData.append('nuevoreg', JSON.stringify(nuevoreg));
                }
            } else {
                nuevoreg = {
                    idregistrobanco: registrobancoActual.idregistrobanco,
                    fecha: fecharegistrobancoRef.current.value,
                    periodo: periodoregistrobancoRef.current.value,
                    codigogasto: codigogastoRef.current.value.toUpperCase(),
                    idcodigogasto: _codigogasto ? _codigogasto.id : null,
                    tipo: tiporegistrobancoRef.current.value.toUpperCase(),
                    subtipo: subtiporegistrobancoRef.current.value.toUpperCase(),
                    detalleoperacion: detalleoperacionRef.current.value.toUpperCase(),
                    descripcionoperacion: descripcionoperacionRef.current.value.toUpperCase(),
                    monto: montoregistrobancoRef.current.value,
                    observaciones: observacionesregistrogastoRef.current.value.toUpperCase(),
                    tiposervicio: tiposervicioRef.current.value.toUpperCase(),
                    siscont: siscontRef.current.value.toUpperCase(),
                    registrofactura: 0,
                    idproyecto: idproyecto,
                    usuariomodifica: UsuarioStorage.id,

                    idproveedor: null,
                    descripciongasto: '',
                    iddetallegasto: null,
                    tipogasto: '',
                    idarea: null,
                    idcentrocosto: null,
                    activo: '',
                    fechafactura: null,
                    tipodocumento: '',
                    nrodocumento: '',
                }
                formData.append('nuevoreg', JSON.stringify(nuevoreg));
            }
            let headers = {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: formData
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json()
            }).then((data) => {
                // console.log(data);
                if (data.content) {
                    // console.log(data.content);
                    //Buscar Lista Centros de Costo
                    BuscarListasregistrobancos(filasporpagina, pagina);
                    settitulotoast("Correcto");
                    settipotoast("success");
                    setmensajetoast(data.message);
                    setabrirToast(true);
                    setmostrarModalDetalla(false);
                }
                else {
                    settitulotoast("Alerta");
                    settipotoast("warning");
                    setmensajetoast(data.message);
                    setabrirToast(true);
                }

            }).catch((error) => {
                settitulotoast("Error");
                settipotoast("error");
                setmensajetoast(config.MessageErrorServer);
                setabrirToast(true);
            });
        }
        else {
            navigate("/login")
            // reject(true)
        }
    }
    const Eliminarregistrobanco = (fila) => {

        if (ValidarUsuario()) {
            if (window.confirm(`¿Estas Seguro de eliminar el registro ${fila.tipo} con monto S/. ${fila.monto}?`)) {

                let url = `${config.endPoint}api/registrobanco/eliminar`;
                let UsuarioStorage = ObtenerUsuario();
                UsuarioStorage = JSON.parse(UsuarioStorage);

                let body_permisos = {
                    idregistrobanco: fila.idregistrobanco,
                }

                let headers = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + UsuarioStorage.token,
                    },
                    body: JSON.stringify(body_permisos)
                };

                fetch(url, headers).then((respuesta) => {
                    return respuesta.json();
                }).then((data) => {

                    if (data.content) {
                        BuscarListasregistrobancos(filasporpagina, pagina);
                        settitulotoast("Correcto");
                        settipotoast("success");
                        setmensajetoast("Registro fue eliminado correctamente.");
                        setabrirToast(true);
                    }
                    else {
                        settitulotoast("Alerta");
                        settipotoast("warning");
                        setmensajetoast(data.message);
                        setabrirToast(true);
                    }

                }).catch((error) => {

                    settitulotoast("Error");
                    settipotoast("error");
                    setmensajetoast(config.MessageErrorServer);
                    setabrirToast(true);
                });
            }
        }
        else {
            navigate("/login");
        }
    }
    const OnChangeFiltroregistrobanco = () => {
        if (filtroregistrobancoRef.current.value.trim() === '') {
            BuscarListasregistrobancos(filasporpagina, pagina);
        }
    }
    const cambiarPagina = async (event, npagina) => {
        await setpagina(parseInt(npagina) + 1);
        BuscarListasregistrobancos(filasporpagina, parseInt(npagina) + 1)
    }
    const cambiarfilasporpagina = async (ev) => {
        await setfilasporpagina(parseInt(ev.target.value));
        await setpagina(1);
        BuscarListasregistrobancos(parseInt(ev.target.value), 1)
    }
    // Procedimientos de Modal
    const CambiarTipoIngreso = async (ev) => {
        // console.log(ev.target.value);
        await settiporegistroactual(ev.target.value);
        if (ev.target.value === 'EGRESO') {
            await setactivarcontrolesEgreso(true);
            await setactivarregistrofactura(false);
            montoregistrobancoRef.current.disabled = false;
            // let fechactual = Date.now();     
            // console.log(fechafacturaRef.current);       
            // fechafacturaRef.current.value = moment(fechactual).format("yyyy-MM-DD");
        }
        else {
            setactivarcontrolesEgreso(false);
            setactivarregistrofactura(false);
            montoregistrobancoRef.current.disabled = false;
        }
        //CalcularMontoImpuesto();
    }
    const activarRegistroFactura = () => {
        if (registrofacturaRef.current.checked) {
            setactivarregistrofactura(true);
        } else {
            setactivarregistrofactura(false);
        }
    }
    const CambiarIncluyeImpuesto = () => {
        // if (incluyeigvRef.current.checked) {
        //     subtotalRef.current.disabled = true;
        //     montoregistrobancoRef.current.disabled = false;
        // } else {
        //     subtotalRef.current.disabled = false;
        //     montoregistrobancoRef.current.disabled = true;
        // }
        // CalcularMontoImpuesto()
    }
    const CalcularMontoImpuesto = () => {
        // let subtotalCalc = 0;
        // let totalCalc = 0;
        // try {
        //     if (tiporegistrobancoRef.current.value === "EGRESO") {
        //         // Calcular Impuesto                
        //         if (incluyeigvRef.current.checked) {
        //             let montocalcular = parseFloat(montoregistrobancoRef.current.value);
        //             if (isNaN(montocalcular)) {
        //                 montocalcular = 0;
        //                 montoregistrobancoRef.current.value = 0;
        //             }
        //             subtotalCalc = Math.round(((montocalcular / (1 + valorinpuesto)) + Number.EPSILON) * 100) / 100;
        //             subtotalRef.current.value = subtotalCalc;
        //             totalCalc = Math.round(((montocalcular - subtotalCalc) + Number.EPSILON) * 100) / 100;
        //             montoigvref.current.value = totalCalc;
        //         } else {
        //             let montocalcular = parseFloat(subtotalRef.current.value);
        //             if (isNaN(montocalcular)) {
        //                 montocalcular = 0;
        //                 subtotalRef.current.value = 0;
        //             }
        //             subtotalCalc = Math.round(((montocalcular * valorinpuesto) + Number.EPSILON) * 100) / 100;
        //             montoigvref.current.value = subtotalCalc;
        //             totalCalc = Math.round(((montocalcular + subtotalCalc) + Number.EPSILON) * 100) / 100;
        //             montoregistrobancoRef.current.value = totalCalc;
        //         }
        //     } else {


        //     }
        // } catch (error) {
        //     subtotalRef.current.value = 0;
        //     montoigvref.current.value = 0;
        //     montoregistrobancoRef.current.value = 0;
        // }

    }
    //Procedimientos Agregar 
    const ValidarAgregarTrabajador = () => {
        let error = 0;
        if (nombretrabajadorRef.current.value.trim() === "") {
            error++;
            nombretrabajadorRef.current.classList.add("is-invalid");
        } else {
            nombretrabajadorRef.current.classList.remove("is-invalid");
        }

        if (areatrabajadorRef.current.value.trim() === "") {
            error++;
            areatrabajadorRef.current.classList.add('is-invalid');
        }
        else {
            areatrabajadorRef.current.classList.remove('is-invalid');
        }
        if (error > 0) {
            return false;
        } else {
            return true;
        }

    }
    // Procedimientos Proveedor
    const ValidarAgregarProveedor = () => {
        let error = 0;
        if (rucproveedorRef.current.value.trim() === "") {
            error++;
            rucproveedorRef.current.classList.add("is-invalid");
        } else {
            rucproveedorRef.current.classList.remove("is-invalid");
        }

        if (nombreproveedorRef.current.value.trim() === "") {
            error++;
            nombreproveedorRef.current.classList.add('is-invalid');
        }
        else {
            nombreproveedorRef.current.classList.remove('is-invalid');
        }
        if (error > 0) {
            return false;
        } else {
            return true;
        }


    }
    const AgregarNuevoProveedor = () => {
        if (ValidarAgregarProveedor()) {
            let url = `${config.endPoint}api/proveedor/agregar`;
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);

            let nuevoproveedor = {
                numerodocumento: rucproveedorRef.current.value.toUpperCase(),
                nombreproveedor: nombreproveedorRef.current.value.toUpperCase(),
                estado: "ACTIVO",
                usuariocrea: UsuarioStorage.id
            }
            // console.log(nuevoproveedor);

            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify(nuevoproveedor)
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json()
            }).then((data) => {

                if (data.content) {
                    //Buscar Lista proveedors de Costo                    
                    settitulotoast("Correcto");
                    settipotoast("success");
                    setmensajetoast(data.message);
                    setabrirToast(true);
                    setmostrarModalAgregarProveedor(false);
                }
                else {
                    settitulotoast("Error");
                    settipotoast("warning");
                    setmensajetoast(data.message);
                    setabrirToast(true);
                }

            }).catch((error) => {
                settitulotoast("Error");
                settipotoast("error");
                setmensajetoast(config.MessageErrorServer);
                setabrirToast(true);
            });

        } else {
            settitulotoast("Alerta");
            settipotoast("warning");
            setmensajetoast("Se detectaron alertas al realizar esta acción. Corrija las alertas y vuelva a interntarlo.");
            setabrirToast(true);
        }
    }
    const MostrarModalBuscarProveedor = () => {
        BuscarListaProveedoresActivos();
        setmostrarModalProveedor(true);
    }
    const ElegirProveedorFiltrado = (row) => {
        // console.log(row);
        setidproveedor(row.id);
        razonsocialRef.current.value = row.nombreproveedor;
        nrorucRef.current.value = row.numerodocumento;
        setmostrarModalProveedor(false);
    }
    //Procedimientos Detalle Gasto
    const ValidarDetalleGasto = () => {
        let error = 0;
        if (nombredetallegastoRef.current.value.trim() === "") {
            error++;
            nombredetallegastoRef.current.classList.add('is-invalid')
        } else {
            nombredetallegastoRef.current.classList.remove('is-invalid')
        }

        if (descripciondetallegastoRef.current.value.trim() === "") {
            error++;
            descripciondetallegastoRef.current.classList.add('is-invalid')
        } else {
            descripciondetallegastoRef.current.classList.remove('is-invalid')
        }

        if (codigodetallegastoRef.current.value.trim() === "") {
            error++;
            codigodetallegastoRef.current.classList.add('is-invalid')
        } else {
            codigodetallegastoRef.current.classList.remove('is-invalid')
        }

        if (error > 0) {
            return false;
        } else {
            return true;
        }

    }
    const AgregarNuevoDetalleGasto = () => {
        if (ValidarDetalleGasto()) {
            let url = `${config.endPoint}api/detallegasto/agregar`;
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);

            let nuevocentro = {
                detallegasto: nombredetallegastoRef.current.value.toUpperCase(),
                descripcion: descripciondetallegastoRef.current.value.toUpperCase(),
                codigo: codigodetallegastoRef.current.value.toUpperCase(),
                estado: "ACTIVO",
                usuariocrea: UsuarioStorage.id
            }

            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify(nuevocentro)
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json()
            }).then((data) => {
                // console.log(data);
                if (data.content) {
                    //Buscar Lista Centros de Costo
                    BuscarListaDetalleGastosActivos();
                    settitulotoast("Correcto");
                    settipotoast("success");
                    setmensajetoast(data.message);
                    setabrirToast(true);
                    setmostrarModalAgregarDetalleGasto(false);
                }
                else {
                    settitulotoast("Alerta");
                    settipotoast("warning");
                    setmensajetoast(data.message);
                    setabrirToast(true);
                }

            }).catch((error) => {
                settitulotoast("Error");
                settipotoast("error");
                setmensajetoast(config.MessageErrorServer);
                setabrirToast(true);
            });

        } else {
            settitulotoast("Alerta");
            settipotoast("warning");
            setmensajetoast("Se detectaron alertas al realizar esta acción. Corrija las alertas y vuelva a interntarlo.");
            setabrirToast(true);
        }
    }
    //Procedimiento Area
    const ValidarArea = () => {
        let error = 0;
        if (nombreareaRef.current.value.trim() === "") {
            error++;
            nombreareaRef.current.classList.add('is-invalid')
        } else {
            nombreareaRef.current.classList.remove('is-invalid')
        }

        if (descripcionareaRef.current.value.trim() === "") {
            error++;
            descripcionareaRef.current.classList.add('is-invalid')
        } else {
            descripcionareaRef.current.classList.remove('is-invalid')
        }

        if (codigoareaRef.current.value.trim() === "") {
            error++;
            codigoareaRef.current.classList.add('is-invalid')
        } else {
            codigoareaRef.current.classList.remove('is-invalid')
        }

        if (error > 0) {
            return false;
        }
        else {
            return true;
        }
    }
    const AgregarNuevoArea = () => {
        if (ValidarArea()) {
            let url = `${config.endPoint}api/area/agregar`;
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);

            let nuevocentro = {
                nombrearea: nombreareaRef.current.value.toUpperCase(),
                descripcion: descripcionareaRef.current.value.toUpperCase(),
                codigo: codigoareaRef.current.value.toUpperCase(),
                estado: "ACTIVO",
                usuariocrea: UsuarioStorage.id
            }

            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify(nuevocentro)
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json()
            }).then((data) => {
                // console.log(data);
                if (data.content) {
                    //Buscar Lista Centros de Costo
                    BuscarListaAreasActivos();
                    settitulotoast("Correcto");
                    settipotoast("success");
                    setmensajetoast(data.message);
                    setabrirToast(true);
                    setmostrarModalAgregarArea(false);
                }
                else {
                    settitulotoast("Error");
                    settipotoast("warning");
                    setmensajetoast(data.message);
                    setabrirToast(true);
                }

            }).catch((error) => {
                settitulotoast("Error");
                settipotoast("error");
                setmensajetoast(config.MessageErrorServer);
                setabrirToast(true);
            });

        } else {
            settitulotoast("Alerta");
            settipotoast("warning");
            setmensajetoast("Se detectaron alertas al realizar esta acción. Corrija las alertas y vuelva a interntarlo.");
            setabrirToast(true);
        }
    }
    //Procedunuento Centro Costo
    const ValidarCentroCosto = () => {
        let error = 0;
        if (nombrecentrocostoRef.current.value.trim() === "") {
            error++;
            nombrecentrocostoRef.current.classList.add('is-invalid')
        } else {
            nombrecentrocostoRef.current.classList.remove('is-invalid')
        }

        if (descripcioncentrocostoRef.current.value.trim() === "") {
            error++;
            descripcioncentrocostoRef.current.classList.add('is-invalid')
        } else {
            descripcioncentrocostoRef.current.classList.remove('is-invalid')
        }

        if (codigocentrocostoRef.current.value.trim() === "") {
            error++;
            codigocentrocostoRef.current.classList.add('is-invalid')
        } else {
            codigocentrocostoRef.current.classList.remove('is-invalid')
        }
        if (error > 0) {
            return false;
        } else {
            return true;
        }

    }
    const AgregarNuevoCentroCosto = () => {
        if (ValidarCentroCosto()) {
            let url = `${config.endPoint}api/centrocosto/agregar`;
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);

            let nuevocentro = {
                nombrecentrocosto: nombrecentrocostoRef.current.value.toUpperCase(),
                descripcion: descripcioncentrocostoRef.current.value.toUpperCase(),
                codigo: codigocentrocostoRef.current.value.toUpperCase(),
                estado: "ACTIVO",
                usuariocrea: UsuarioStorage.id
            }

            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify(nuevocentro)
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json()
            }).then((data) => {
                // console.log(data);
                if (data.content) {
                    //Buscar Lista Centros de Costo
                    BuscarListaCentrosGastoActivos();
                    settitulotoast("Correcto");
                    settipotoast("success");
                    setmensajetoast(data.message);
                    setabrirToast(true);
                    setmostrarModalAgregarCentroCosto(false);
                }
                else {
                    settitulotoast("Error");
                    settipotoast("warning");
                    setmensajetoast(data.message);
                    setabrirToast(true);
                }

            }).catch((error) => {
                settitulotoast("Error");
                settipotoast("error");
                setmensajetoast(config.MessageErrorServer);
                setabrirToast(true);
            });
        } else {
            settitulotoast("Alerta");
            settipotoast("warning");
            setmensajetoast("Se detectaron alertas al realizar esta acción. Corrija las alertas y vuelva a interntarlo.");
            setabrirToast(true);
        }
    }
    //Procedimiento Codigo Gasto
    const ValidarCodigoGasto = () => {
        let error = 0;

        if (codigocodigogastoref.current.value.trim() === "") {
            error++;
            codigocodigogastoref.current.classList.add('is-invalid')
        } else {
            codigocodigogastoref.current.classList.remove('is-invalid')
        }
        if (error > 0) {
            return false;
        } else {
            return true;
        }

    }
    const AgregarNuevoCodigoGasto = () => {
        if (ValidarCodigoGasto()) {
            let url = `${config.endPoint}api/codigogasto/agregar`;
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);

            let nuevocentro = {
                codigo: codigocodigogastoref.current.value.toUpperCase(),
                descripcion: descripcioncodigogastoRef.current.value.toUpperCase(),
                estado: "ACTIVO",
                usuariocrea: UsuarioStorage.id
            }

            let headers = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
                body: JSON.stringify(nuevocentro)
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json()
            }).then((data) => {
                // console.log(data);
                if (data.content) {
                    //Buscar Lista Cosigo Gasto
                    BuscarListaCodigosGastoActivos();
                    settitulotoast("Correcto");
                    settipotoast("success");
                    setmensajetoast(data.message);
                    setabrirToast(true);
                    setmostrarModalAgregarCodigoGasto(false);
                }
                else {
                    settitulotoast("Alerta");
                    settipotoast("warning");
                    setmensajetoast(data.error);
                    setabrirToast(true);
                }

            }).catch((error) => {
                settitulotoast("Error");
                settipotoast("error");
                setmensajetoast(config.MessageErrorServer);
                setabrirToast(true);
            });
        } else {
            settitulotoast("Alerta");
            settipotoast("warning");
            setmensajetoast("Se detectaron alertas al realizar esta acción. Corrija las alertas y vuelva a interntarlo.");
            setabrirToast(true);
        }
    }
    //Modal Fotografias
    const MostrarModalFotografias = async (row) => {
        if (row.fotografia !== "") {
            await setfotografiaActual(row.fotografia);
            setmostarModalFotografia(true);
        } else {
            settitulotoast("Alerta");
            settipotoast("warning");
            setmensajetoast("No se encontro imagen para mostrar.");
            setabrirToast(true);
        }
    }
    //Reporte
    const ReporteCompleto = () => {
        let url = `${config.endPoint}api/registrobanco/reporteCompleto/${idproyecto}`;
        let UsuarioStorage = ObtenerUsuario();
        UsuarioStorage = JSON.parse(UsuarioStorage);
        let headers = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + UsuarioStorage.token,
            },
        };

        fetch(url, headers).then((respuesta) => {
            return respuesta.json();
        }).then(async (data) => {
            if (data.content) {
                await CreateJsonToExcel(data.content, 'Reporte Completo_');
            } else {
                settitulotoast("Alerta");
                settipotoast("warning");
                setmensajetoast(data.message);
                setabrirToast(true);
            }
        }).catch((error) => {
            setresumenregistrobanco(null);
            settitulotoast("Error");
            settipotoast("error");
            setmensajetoast(config.MessageErrorServer);
            setabrirToast(true);
        });
    }
    const ReporteRangoFechas = () => {
        let error = 0;
        if (fechaInicioReporteRef.current.value.trim() === "") {
            error++;
            fechaInicioReporteRef.current.classList.add("is-invalid");
        }
        else {
            fechaInicioReporteRef.current.classList.remove("is-invalid");
        }
        if (fechaFinReporteRef.current.value.trim() === "") {
            error++;
            fechaFinReporteRef.current.classList.add("is-invalid");
        } else {
            fechaFinReporteRef.current.classList.remove("is-invalid");
        }
        if (error === 0) {
            let url = `${config.endPoint}api/registrobanco/reporteFechas/${idproyecto}/${fechaInicioReporteRef.current.value}/${fechaFinReporteRef.current.value}`;
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);
            let headers = {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                }
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data.content.length > 0) {
                    // console.log(data.content);
                    await CreateJsonToExcel(data.content, `Reporte_${fechaInicioReporteRef.current.value}_${fechaFinReporteRef.current.value}`);
                    setmostrarModalReporteRangoFecha(false);
                } else {
                    settitulotoast("Alerta");
                    settipotoast("warning");
                    setmensajetoast("No se encontraron registros para exportar.");
                    setabrirToast(true);
                }
            }).catch((error) => {
                setresumenregistrobanco(null);
                settitulotoast("Error");
                settipotoast("error");
                setmensajetoast(config.MessageErrorServer);
                setabrirToast(true);
            });
        }
    }

    return (
        <Fragment>
            <PaginaCarga loadcarga={loadcarga} />

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={abrirToast} autoHideDuration={4000} onClose={() => { setabrirToast(false) }}>
                <Alert onClose={() => { setabrirToast(false) }} severity={tipotoast}>
                    <AlertTitle>{titulotoast}</AlertTitle>
                    {mensajetoast}
                </Alert>
            </Snackbar>

            <Header />
            {/* titulo */}
            <div className="container mt-1" style={{ backgroundColor: 'rgb(25,118,210)' }}>
                <div className="container  ">
                    <div className="row py-1">
                        <StackMU direction='row'>
                            <div className="col-6 col-sm-6 col-md-8 ">
                                <i title="Regresar" className="fas fa-tag  iconotitulo " style={{ color: 'aliceblue' }}></i>
                                <span className="mx-1" style={{ color: 'aliceblue' }}>BANCO</span>
                            </div>
                            <div className='text-end ms-auto'>
                                <IconButton onClick={() => { navigate(`/dashboard/${idproyecto}`) }} style={{ color: 'white' }} size='small'>
                                    <ArrowBackIcon fontSize='inherit' />
                                </IconButton>
                            </div>
                        </StackMU>
                    </div>
                </div>
            </div>

            <div className='container border rounded mt-1'>
                <Container fluid className='mt-1'>
                    <Row>
                        <Col sx={12} md={3} >
                            <Form.Control onChange={() => { OnChangeFiltroregistrobanco() }} ref={filtroregistrobancoRef} type="text" placeholder="Valor Buscar" size="sm" />

                        </Col>
                        <Col sx={12} md={3} className="mt-md-0 mt-1">
                            <Button onClick={() => { BuscarListasregistrobancos(filasporpagina, pagina) }} fullWidth size='small' variant='outlined'>Buscar</Button>
                        </Col>
                        <Col sx={12} md={3} className="mt-md-0 mt-1 ms-auto">
                            <Button onClick={() => {
                                AbrirModalregistrobanco("NUEVO", null);
                            }} variant="outlined" size='small'>
                                <AddIcon></AddIcon>
                                Agregar
                            </Button>
                        </Col>
                        <Col sx={12} md={3} className='text-end'>
                            <div className="dropdown">
                                <button className="btn btn-sm dropdown-toggle" type="button" id="ListaMenuAcciones" data-bs-toggle="dropdown" aria-expanded="false">
                                    Opciones
                                </button>
                                <ul className="dropdown-menu dropdown-menu-light" aria-labelledby="ListaMenuAcciones">
                                    <li onClick={() => { ReporteCompleto() }}><small className="dropdown-item" ><ContentPasteGoIcon /> Reporte Completo</small></li>
                                    <li onClick={() => { setmostrarModalReporteRangoFecha(true) }}><small className="dropdown-item" ><InsertInvitationIcon /> Reporte por Fecha</small></li>
                                    <li><hr className="dropdown-divider" /></li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                    {
                        resumenregistrobanco ?
                            <Row className=''>
                                <Col sm={12} md={4} className='mt-1'>
                                    <div className='text-center border rounded border-success'>
                                        <small>{`Total Ingresos: S/. ${resumenregistrobanco.ingreso ? Math.round((resumenregistrobanco.ingreso+Number.EPSILON)*100)/100 : 0}`}</small>
                                    </div>
                                </Col>
                                <Col sm={12} md={4} className='mt-1'>
                                    <div className='text-center border rounded border-danger'>
                                        <small>{`Total Egresos: S/. ${resumenregistrobanco.egreso ? Math.round(( resumenregistrobanco.egreso+Number.EPSILON)*100)/100 : 0}`}</small>
                                    </div>
                                </Col>
                                <Col sm={12} md={4} className='mt-1'>
                                    <div className='text-center border rounded '>
                                        <small>{`Efectivo: S/. ${resumenregistrobanco.resumen ? Math.round(( resumenregistrobanco.resumen+Number.EPSILON)*100)/100 : 0}`}</small>
                                    </div>
                                </Col>
                            </Row>
                            : <></>
                    }
                </Container>

                {/* Tabla de datos */}
                <div className="row p-1">

                    <TableContainer style={{ maxHeight: window.outerHeight < 760 ? 430 : 650 }}>
                        <Table size='small' stickyHeader>
                            <TableHead>
                                <TableRow >
                                    <TableCell>Acciones</TableCell>
                                    <TableCell>Fecha</TableCell>
                                    <TableCell>Tipo</TableCell>
                                    <TableCell>Sub Tipo</TableCell>
                                    <TableCell>Monto</TableCell>
                                    {/* <TableCell>Responsable</TableCell> */}
                                    <TableCell>Proveedor</TableCell>
                                    <TableCell>Fotografia</TableCell>
                                    <TableCell>Activo/Pasivo</TableCell>
                                    <TableCell>Fecha Registro</TableCell>
                                    <TableCell>Usuario crea</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    load ? listaregistrosbanco.map((row, index) => {
                                        return (
                                            <TableRow key={index} >
                                                <TableCell>
                                                    <IconButton onClick={() => { AbrirModalregistrobanco('VER', row) }} size="small">
                                                        <EditIcon fontSize="inherit" />
                                                    </IconButton>
                                                    <IconButton onClick={() => { Eliminarregistrobanco(row) }} size="small">
                                                        <DeleteIcon fontSize="inherit" />
                                                    </IconButton>
                                                </TableCell>

                                                <TableCell sx={{ fontSize: '0.8em' }}>{row.fecha}</TableCell>
                                                {
                                                    row.tipo === 'EGRESO' ?
                                                        <TableCell sx={{ fontSize: '0.8em' }} className='text-danger'>{row.tipo}</TableCell>
                                                        :
                                                        <TableCell sx={{ fontSize: '0.8em' }} className='text-primary'>{row.tipo}</TableCell>
                                                }
                                                <TableCell sx={{ fontSize: '0.8em' }} className='text-primary'>{row.subtipo}</TableCell>
                                                {
                                                    row.tipo === 'EGRESO' ?
                                                        <TableCell sx={{ fontSize: '0.8em' }} className='text-danger'>{`S/. ${row.monto}`}</TableCell>
                                                        :
                                                        <TableCell sx={{ fontSize: '0.8em' }} className='text-primary'>{`S/. ${row.monto}`}</TableCell>
                                                }
                                                {/* <TableCell sx={{ fontSize: '0.8em' }} >{row.responsable}</TableCell> */}
                                                <TableCell sx={{ fontSize: '0.8em' }} >{row.proveedor}</TableCell>
                                                <TableCell sx={{ fontSize: '0.8em' }}>
                                                    <IconButton onClick={() => { MostrarModalFotografias(row) }} size="small" color='primary'>
                                                        {row.fotografia !== "" ?
                                                            <Inventory2SharpIcon fontSize="inherit" />
                                                            :

                                                            <HideImageIcon fontSize="inherit" color='error' />
                                                        }
                                                    </IconButton>
                                                    {row.fotografia !== "" ? 1 : 0}
                                                </TableCell>
                                                <TableCell sx={{ fontSize: '0.8em' }}>{row.activo}</TableCell>
                                                <TableCell sx={{ fontSize: '0.8em' }}>{moment(row.fechacreacion).format('yyy-MM-DD hh:mm:ss a')}</TableCell>
                                                <TableCell sx={{ fontSize: '0.8em' }}>{row.usuariocrea}</TableCell>
                                                {/* <TableCell >{row.fechamodifica?moment(row.fechamodifica).format('yyy-MM-DD hh:mm:ss'):''}</TableCell>
                                    <TableCell >{row.usuariomodifica}</TableCell> */}
                                            </TableRow>
                                        );
                                    })
                                        : <></>
                                }

                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[50, 100, 150, 200]}
                        component="div"
                        count={cantidadElementos}
                        rowsPerPage={filasporpagina}
                        page={pagina - 1}
                        onPageChange={(ev, npagina) => { cambiarPagina(ev, npagina) }}
                        onRowsPerPageChange={(ev) => { cambiarfilasporpagina(ev) }}
                        labelRowsPerPage={"Filas Por Pagina"}
                    />
                </div>
            </div>

            {/* Modales  */}
            <Modal
                show={mostrarModalDetalla}
                onHide={() => { setmostrarModalDetalla(false) }}
                keyboard={false}
                size="lg"
                centered >
                <Modal.Header>
                    <Modal.Title>
                        {`Registro ${accionactual}`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Container>
                        <Row>
                            <Col sm={12} md={4}>
                                <Form.Group>
                                    <small className="text-primary">Periodo Mes</small>
                                    <Form.Control ref={periodoregistrobancoRef} disabled={controlesreadonly} type="text" size='sm' placeholder='Ingrese Periodo'></Form.Control>
                                </Form.Group>
                            </Col>
                            <Col sm={12} md={4}>
                                <small className="text-primary">Codigo</small>
                                <Stack direction='horizontal' gap={2}>
                                    <input ref={codigogastoRef} disabled={controlesreadonly} className="form-control form-control-sm" list="listacodigosregistrobanco" placeholder="Ingrese el Codigo" />
                                    <datalist id="listacodigosregistrobanco">
                                        {
                                            listacodigogastoactivo.map((row, index) => {
                                                return (
                                                    <option key={index} value={row.codigo} />
                                                )
                                            })
                                        }
                                    </datalist>
                                    <IconButton disabled={controlesreadonly} onClick={() => { setmostrarModalAgregarCodigoGasto(true) }}>
                                        <AddIcon />
                                    </IconButton>
                                </Stack>
                            </Col>
                            <Col sm={12} md={4}>
                                <Form.Group>
                                    <small className="text-primary">Fecha</small>
                                    <Form.Control ref={fecharegistrobancoRef} disabled={controlesreadonly} type="date" size='sm' placeholder='Ingrese Fecha'></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={6}>
                                <Form.Group>
                                    <small className="text-primary">Detalle Operación</small>
                                    <Form.Control ref={detalleoperacionRef} disabled={controlesreadonly} type="text" size='sm' placeholder='Ingrese Detalle Operación'></Form.Control>
                                </Form.Group>
                            </Col>
                            <Col sm={12} md={6}>
                                <Form.Group>
                                    <small className="text-primary">Descripción Operación</small>
                                    <Form.Control ref={descripcionoperacionRef} disabled={controlesreadonly} type="text" size='sm' placeholder='Descripcion Operación'></Form.Control>
                                </Form.Group>
                            </Col>

                        </Row>
                        <Row>
                            <Col sm={12} md={6}>
                                <Form.Group>
                                    <small className="text-primary">Tipo</small>
                                    <select disabled={controlesreadonly} ref={tiporegistrobancoRef} onChange={(ev) => { CambiarTipoIngreso(ev) }} className="form-select form-select-sm" defaultValue="">
                                        <option value="" disabled>Seleccione el Tipo</option>
                                        <option value="INGRESO">INGRESO</option>
                                        <option value="EGRESO">EGRESO</option>
                                    </select>
                                </Form.Group>
                            </Col>
                            <Col sm={12} md={6}>
                                <Form.Group>
                                    <small className="text-primary">Subtipo</small>
                                    <select disabled={controlesreadonly} ref={subtiporegistrobancoRef} className="form-select form-select-sm" defaultValue="">
                                        <option value="" disabled>Seleccione el Sub Tipo</option>
                                        <option value="TRANSFERENCIA">TRANSFERENCIA</option>
                                        {
                                            tiporegistroactual === "EGRESO" ?
                                                <>
                                                    <option value="RETIRO EFECTIVO">RETIRO EFECTIVO</option>
                                                    <option value="COMPRAS DEBITO">COMPRAS DEBITO</option>
                                                    <option value="IMPUESTOS Y COMISIONES">IMPUESTOS Y COMISIONES</option>
                                                </>
                                                :
                                                <></>
                                        }
                                    </select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={3}>
                                <Form.Group>
                                    <small className="text-primary">Monto </small>
                                    <Form.Control onChange={(ev) => { ActualizarMontoRegistro(ev) }} ref={montoregistrobancoRef} disabled={controlesreadonly} size='sm' type="number" placeholder='Ingrese el Monto'></Form.Control>
                                </Form.Group>
                            </Col>
                            <Col sm={12} md={6}>
                                <Form.Group>
                                    <small className="text-primary">Observaciones</small>
                                    <Form.Control ref={observacionesregistrogastoRef} disabled={controlesreadonly} type="text" size='sm' placeholder='Ingrese Observaciones'></Form.Control>
                                </Form.Group>
                            </Col>
                            {
                                activarcontrolesEgreso ?
                                    <Col sm={12} md={3}>
                                        <Form.Group>
                                            <small className="text-primary">*</small>
                                            <Form.Check ref={registrofacturaRef} onChange={() => { activarRegistroFactura() }} disabled={controlesreadonly} size='sm' type='checkbox' label='Registrar Factura' />
                                        </Form.Group>
                                    </Col>
                                    : <></>
                            }
                        </Row>

                        {
                            activarregistrofactura ?
                                <>
                                    <Row>
                                        <Col sm={12} md={3}>
                                            <Form.Group>
                                                <small className="text-primary">Fecha de Factura</small>
                                                <Form.Control ref={fechafacturaRef} disabled={controlesreadonly} type="date" size='sm' placeholder='Ingrese Fecha Factura/Doc.'></Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <small className="text-primary">RUC / Nro</small>
                                            <Stack direction='horizontal' gap={1}>
                                                <Form.Control ref={nrorucRef} disabled={controlesreadonly} onChange={() => { ChangeInputRuc() }} onKeyPress={(ev) => { KeyEnterInputRuc(ev) }} type="text" size='sm' placeholder='Ingrese el RUC'></Form.Control>
                                                <IconButton disabled={controlesreadonly} onClick={() => { MostrarModalBuscarProveedor() }} title='Buscar'>
                                                    <ManageSearchIcon />
                                                </IconButton>
                                                <IconButton disabled={controlesreadonly} onClick={() => { setmostrarModalAgregarProveedor(true) }}>
                                                    <AddIcon />
                                                </IconButton>
                                            </Stack>
                                        </Col>
                                        <Col md={5}>
                                            <Form.Group>
                                                <small className="text-primary">Razon Social / Nombre</small>
                                                <Form.Control ref={razonsocialRef} disabled type="text" size='sm' placeholder='Razon Social / Nombre no encontrado'></Form.Control>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Col>
                                                <Form.Group>
                                                    <small className="text-primary">Descripción </small>
                                                    <Form.Control ref={descripciongastoRef} disabled={controlesreadonly} type="text" size='sm' placeholder='Ingrese la descripcion'></Form.Control>
                                                </Form.Group>
                                            </Col>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12} md={4}>
                                            <small className="text-primary">Descripcion Gasto</small>
                                            <Stack direction='horizontal' gap={2}>
                                                <input ref={detallegastoRef} disabled={controlesreadonly} className="form-control form-control-sm" list="listadetallegasto" placeholder="Elija la Descripcion Gasto" />
                                                <datalist id="listadetallegasto">
                                                    {
                                                        listadetallegastosactivos.map((row, index) => {
                                                            return (
                                                                <option key={index} value={row.detallegasto} />

                                                            )
                                                        })
                                                    }
                                                </datalist>
                                                <IconButton disabled={controlesreadonly} onClick={() => { setmostrarModalAgregarDetalleGasto(true) }}>
                                                    <AddIcon />
                                                </IconButton>
                                            </Stack>
                                        </Col>
                                        <Col sm={12} md={4}>
                                            <Form.Group>
                                                <small className="text-primary">Tipo Gasto</small>
                                                <select ref={tipogastoRef} disabled={controlesreadonly} className="form-select form-select-sm" defaultValue="">
                                                    <option value="" disabled>Seleccione el Tipo Gasto</option>
                                                    <option value="FIJO">FIJO</option>
                                                    <option value="VARIABLE">VARIABLE</option>
                                                </select>
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} md={4}>
                                            <small className="text-primary">Area</small>
                                            <Stack direction='horizontal' gap={2}>
                                                <input ref={arearegistrobancoRef} disabled={controlesreadonly} className="form-control form-control-sm" list="listaarearesponsable" placeholder="Elija el Area" />
                                                <datalist id="listaarearesponsable">
                                                    {
                                                        listareaeasactivas.map((row, index) => {
                                                            return (
                                                                <option key={index} value={row.nombrearea} />
                                                            )
                                                        })
                                                    }
                                                </datalist>
                                                <IconButton disabled={controlesreadonly} onClick={() => { setmostrarModalAgregarArea(true) }}>
                                                    <AddIcon />
                                                </IconButton>
                                            </Stack>

                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12} md={4}>
                                            <small className="text-primary">Centro Gasto</small>
                                            <Stack direction='horizontal' gap={2}>
                                                <input ref={centrocostoRef} disabled={controlesreadonly} className="form-control form-control-sm" list="datasetcentrocosto" placeholder="Elija el Centro Gasto" />
                                                <datalist id="datasetcentrocosto">
                                                    {
                                                        listacentrosgastoactivo.map((row, index) => {
                                                            return (
                                                                <option key={index} value={row.nombrecentrocosto} />
                                                            )
                                                        })
                                                    }
                                                </datalist>
                                                <IconButton disabled={controlesreadonly} onClick={() => { setmostrarModalAgregarCentroCosto(true) }}>
                                                    <AddIcon />
                                                </IconButton>
                                            </Stack>
                                        </Col>
                                        <Col sm={12} md={4}>
                                            <Form.Group>
                                                <small className="text-primary">{'(Activo / Corriente)'}</small>
                                                <select ref={activoaregistrobancoRef} disabled={controlesreadonly} className="form-select form-select-sm" defaultValue="">
                                                    <option value="" disabled>Seleccione el Tipo</option>
                                                    <option value="ACTIVO">ACTIVO</option>
                                                    <option value="CORRIENTE">CORRIENTE</option>
                                                </select>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={12} md={4}>
                                            <Form.Group>
                                                <small className="text-primary">Tipo Documento</small>
                                                {/* <select disabled={controlesreadonly} ref={tipodocumentoRef} className="form-select form-select-sm" defaultValue="">
                                                    <option value="" disabled>Seleccione el Tipo Doc.</option>
                                                    <option value="FACTURA">Factura</option>
                                                    <option value="BOLETA">Boleta</option>
                                                </select> */}
                                                <input ref={tipodocumentoRef} disabled={controlesreadonly} className="form-control form-control-sm" list="listatiposdocumento" placeholder="Elija el Tipo Documento" />
                                                <datalist id="listatiposdocumento">
                                                    {/* <option value="" disabled>Seleccione el Tipo Doc.</option> */}
                                                    {
                                                        listatiposdocumento.length > 1 ?
                                                            listatiposdocumento.map((row, index) => {
                                                                return (
                                                                    <option key={index} value={row.tipodocumento} />
                                                                )
                                                            })
                                                            :
                                                            ['FACTURA'].map((value, index) => {
                                                                return (
                                                                    <option key={index} value={value} />
                                                                )
                                                            })
                                                    }
                                                </datalist>
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} md={4}>
                                            <Form.Group>
                                                <small className="text-primary">Nro Documento</small>
                                                <Form.Control ref={nrodocumentoRef} disabled={controlesreadonly} type="text" size='sm' placeholder='Ingrese el Nro. Documento'></Form.Control>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </>

                                : <></>
                        }

                        <Row>
                            <Col sm={12} md={6}>
                                <Form.Group>
                                    <small className="text-primary">Tipo Servicio</small>
                                    <Form.Control ref={tiposervicioRef} disabled={controlesreadonly} type="text" size='sm' placeholder='Ingrese Tipo Servicio'></Form.Control>
                                </Form.Group>
                            </Col>
                            <Col sm={12} md={6}>
                                <Form.Group>
                                    <small className="text-primary">SISCONT</small>
                                    <Form.Control ref={siscontRef} disabled={controlesreadonly} type="text" size='sm' placeholder='Ingrese SISCONT'></Form.Control>
                                </Form.Group>
                            </Col>
                            {/* <Col>
                                <Form.Group>
                                    <small className="text-primary">Comentario (opcional)</small>
                                    <Form.Control ref={comentarioRef } disabled={controlesreadonly} type="text" size='sm' placeholder='Ingrese el Comentario'></Form.Control>
                                </Form.Group>
                            </Col> */}
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Fotografia</small>
                                    {/* <Form.Control  accept='image/*' type="file" size="sm" /> */}
                                    <input ref={fotografiaRef} accept="image/*" disabled={controlesreadonly} className="form-control form-control-sm" type="file"></input>
                                </Form.Group>
                            </Col>
                        </Row>
                        <hr></hr>
                        <StackMU spacing={'auto'} direction='row' justifyContent={'end'} sx={{ mt: 2 }}>
                            <Button onClick={() => { setmostrarModalDetalla(false) }} variant="outlined" size='small'>Cancelar</Button>
                            {/* <Button onClick={() => { SubirFoto() }} variant="outlined" size='small'>Guardar Foto</Button> */}
                            {
                                controlesreadonly ?
                                    <Button onClick={() => { HabilitarModificarRegistroregistrobanco() }} variant="outlined" size='small'>Modificar</Button>
                                    : <></>
                            }
                            {
                                !controlesreadonly ?
                                    <Button onClick={() => { Guardarregistrobanco() }} size='small' variant="outlined">Guardar</Button>
                                    : <></>
                            }
                        </StackMU>
                    </Container>
                </Modal.Body>
            </Modal>

            {/* Modal Fotos*/}
            <Modal
                show={mostarModalFotografia}
                onHide={() => { setmostarModalFotografia(false) }}
                keyboard={false}
                size="lg"
                centered >
                {/* <Modal.Header>
                        <Modal.Title>Fotos</Modal.Title>
                    </Modal.Header> */}

                <Modal.Body style={{ backgroundColor: 'rgb(33,37,41)' }}>
                    <div className="container" >
                        <div className="row" style={{ maxHeight: '600px' }}>
                            <Carousel wrap={false} >
                                <Carousel.Item >
                                    <div >
                                        <img
                                            className="d-block m-auto"
                                            src={`${config.endPoint}api/foto/DownloadFileServer/${fotografiaActual}`}
                                            alt={`Imagen `}
                                            height='550'
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => { window.open(`${config.endPoint}api/foto/DownloadFileServer/${fotografiaActual}`, '_blank'); }}
                                        />
                                    </div>
                                </Carousel.Item>
                            </Carousel>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* modal Agregar Trabajador Responsable */}
            {/* <Modal
                show={mostrarModalagregarTrabajador}
                onHide={() => { setmostrarModalagregarTrabajador(false) }}
                keyboard={false}
                backdrop="static"
                centered
                fullscreen='sm-down'
            >
                <Modal.Body style={{ border: 'solid 2px rgb(48,132,215)', borderRadius: '10px' }}>
                    <h5>Agregar Trabajador</h5>
                    <hr />
                    <Container>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Nombres Trabajador</small>
                                    <Form.Control ref={nombretrabajadorRef} type="text" placeholder='Ingrese Nombre'></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Area</small>
                                    <select ref={areatrabajadorRef} className="form-select" defaultValue="">
                                        <option value="" disabled>Seleccione el Area</option>
                                        {
                                            listareaeasactivas.map((row, index) => {
                                                return (
                                                    <option key={index} value={row.id}>{row.nombrearea}</option>
                                                );
                                            })
                                        }
                                    </select>
                                </Form.Group>
                            </Col>
                        </Row>

                        <hr></hr>
                        <StackMU spacing={'auto'} direction='row' justifyContent={'end'} sx={{ mt: 2 }}>
                            <Button onClick={() => { setmostrarModalagregarTrabajador(false) }} variant="outlined">Cerrar</Button>
                            <Button onClick={() => { AgregarNuevoTrabajador() }} variant="outlined">Guardar</Button>
                        </StackMU>
                    </Container>
                </Modal.Body>
            </Modal> */}

            {/* Modal Buscar Proveedor */}
            <Modal
                show={mostrarModalProveedor}
                onHide={() => { setmostrarModalProveedor(false) }}
                keyboard={false}
                backdrop="static"
                centered
                fullscreen='sm-down'
            >
                <Modal.Body style={{ border: 'solid 2px rgb(48,132,215)', borderRadius: '10px' }}>
                    <h5>Buscar Proveedor</h5>
                    <hr />
                    <Container>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary" >Buscar</small>
                                    <Form.Control onChange={() => { BuscarListaProveedoresActivos() }} ref={filtrobuscarproveedorRef} type="text" placeholder='Ingrese Valor a Buscar' ></Form.Control>
                                </Form.Group>
                                <small className='text-secondary'>Ingrese el valor a buscar, en el cuadro inferior se mostrara los elementos encontrados. De clic sobre el nombre del elemento para elegirlo.</small>
                            </Col>
                        </Row>
                        {/* Tabla de Datos */}
                        <TableContainer style={{ maxHeight: '300px' }}>
                            <Table size='small' stickyHeader>
                                <TableHead>
                                    <TableRow >
                                        <TableCell>RUC</TableCell>
                                        <TableCell>Proveedor</TableCell>
                                        {/* <TableCell>Permiso</TableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        listaproveedoresactivos.map((row, index) => {
                                            return (
                                                <TableRow key={index} onClick={() => { ElegirProveedorFiltrado(row) }} style={{ cursor: 'pointer' }}>
                                                    <TableCell className='selectElemento'>{row.numerodocumento}</TableCell>
                                                    <TableCell className='selectElemento'>{row.nombreproveedor}</TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }

                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Row>
                            <Col>
                                <Form.Text>{`${listaproveedoresactivos.length} Elemento(s)`}</Form.Text>
                            </Col>
                        </Row>
                        <hr></hr>
                        <StackMU spacing={'auto'} direction='row' justifyContent={'end'} sx={{ mt: 2 }}>
                            <Button onClick={() => { setmostrarModalProveedor(false) }} variant="outlined">Cerrar</Button>
                        </StackMU>
                    </Container>
                </Modal.Body>
            </Modal>

            {/* Modal Agregar Proveedor */}
            <Modal
                show={mostrarModalAgregarProveedor}
                onHide={() => { setmostrarModalAgregarProveedor(false) }}
                keyboard={false}
                backdrop="static"
                centered
                fullscreen='sm-down'
            >
                <Modal.Body style={{ border: 'solid 2px rgb(48,132,215)', borderRadius: '10px' }}>
                    <h5>Agregar Proveedor</h5>
                    <hr />
                    <Container>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Nombre Numero Documento (RUC / ...)</small>
                                    <Form.Control ref={rucproveedorRef} type="number" placeholder='Ingrese Nombre'></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Nombre Proveedor</small>
                                    <Form.Control ref={nombreproveedorRef} type="text" placeholder='Ingrese Descripción'></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>

                        <hr></hr>
                        <StackMU spacing={'auto'} direction='row' justifyContent={'end'} sx={{ mt: 2 }}>
                            <Button onClick={() => { setmostrarModalAgregarProveedor(false) }} variant="outlined">Cerrar</Button>
                            <Button onClick={() => { AgregarNuevoProveedor() }} variant="outlined">Guardar</Button>
                        </StackMU>
                    </Container>
                </Modal.Body>
            </Modal>

            {/* Modal Agregar Detalle Gasto */}
            <Modal
                show={mostrarModalAgregarDetalleGasto}
                onHide={() => { setmostrarModalAgregarDetalleGasto(false) }}
                keyboard={false}
                backdrop="static"
                centered
                fullscreen='sm-down'
            >
                <Modal.Body style={{ border: 'solid 2px rgb(48,132,215)', borderRadius: '10px' }}>
                    <h5>Agregar Detalle Gasto</h5>
                    <hr />
                    <Container>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Nombre Descripción Gasto</small>
                                    <Form.Control ref={nombredetallegastoRef} type="text" placeholder='Ingrese Nombre'></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Detalle</small>
                                    <Form.Control ref={descripciondetallegastoRef} type="text" placeholder='Ingrese Detalle'></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Codigo</small>
                                    <Form.Control ref={codigodetallegastoRef} type="text" placeholder='Ingrese Codigo'></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>

                        <hr></hr>
                        <StackMU spacing={'auto'} direction='row' justifyContent={'end'} sx={{ mt: 2 }}>
                            <Button onClick={() => { setmostrarModalAgregarDetalleGasto(false) }} variant="outlined">Cerrar</Button>
                            <Button onClick={() => { AgregarNuevoDetalleGasto() }} variant="outlined">Guardar</Button>
                        </StackMU>
                    </Container>
                </Modal.Body>
            </Modal>

            {/* Modal Agregar Area */}
            <Modal
                show={mostrarModalAgregarArea}
                onHide={() => { setmostrarModalAgregarArea(false) }}
                keyboard={false}
                backdrop="static"
                centered
                fullscreen='sm-down'
            >
                <Modal.Body style={{ border: 'solid 2px rgb(48,132,215)', borderRadius: '10px' }}>
                    <h5>Agregar Area</h5>
                    <hr />
                    <Container>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Nombre Area</small>
                                    <Form.Control ref={nombreareaRef} type="text" placeholder='Ingrese Nombre'></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Descripción</small>
                                    <Form.Control ref={descripcionareaRef} type="text" placeholder='Ingrese Descripción'></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Codigo</small>
                                    <Form.Control ref={codigoareaRef} type="text" placeholder='Ingrese Codigo'></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>

                        <hr></hr>
                        <StackMU spacing={'auto'} direction='row' justifyContent={'end'} sx={{ mt: 2 }}>
                            <Button onClick={() => { setmostrarModalAgregarArea(false) }} variant="outlined">Cerrar</Button>
                            <Button onClick={() => { AgregarNuevoArea() }} variant="outlined">Guardar</Button>
                        </StackMU>
                    </Container>
                </Modal.Body>
            </Modal>

            {/* Modal Agregar Centro Costo */}
            <Modal
                show={mostrarModalAgregarCentroCosto}
                onHide={() => { setmostrarModalAgregarCentroCosto(false) }}
                keyboard={false}
                backdrop="static"
                centered
                fullscreen='sm-down'
            >
                <Modal.Body style={{ border: 'solid 2px rgb(48,132,215)', borderRadius: '10px' }}>
                    <h5>Agregar Area</h5>
                    <hr />
                    <Container>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Nombre Centro de Costo</small>
                                    <Form.Control ref={nombrecentrocostoRef} type="text" placeholder='Ingrese Nombre'></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Descripción</small>
                                    <Form.Control ref={descripcioncentrocostoRef} type="text" placeholder='Ingrese Descripción'></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Codigo</small>
                                    <Form.Control ref={codigocentrocostoRef} type="text" placeholder='Ingrese Codigo'></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>

                        <hr></hr>
                        <StackMU spacing={'auto'} direction='row' justifyContent={'end'} sx={{ mt: 2 }}>
                            <Button onClick={() => { setmostrarModalAgregarCentroCosto(false) }} variant="outlined">Cerrar</Button>
                            <Button onClick={() => { AgregarNuevoCentroCosto() }} variant="outlined">Guardar</Button>
                        </StackMU>
                    </Container>
                </Modal.Body>
            </Modal>
            {/* Modal Agregar Codigo Gasto*/}
            <Modal
                show={mostrarModalAgregarCodigoGasto}
                onHide={() => { setmostrarModalAgregarCodigoGasto(false) }}
                keyboard={false}
                backdrop="static"
                centered
                fullscreen='sm-down'
            >
                <Modal.Body style={{ border: 'solid 2px rgb(48,132,215)', borderRadius: '10px' }}>
                    <h5>Agregar Codigo Gasto</h5>
                    <hr />
                    <Container>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Codigo</small>
                                    <Form.Control ref={codigocodigogastoref} type="text" placeholder='Ingrese Codigo'></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <small className="text-primary">Descripción</small>
                                    <Form.Control ref={descripcioncodigogastoRef} type="text" placeholder='Ingrese Descripción'></Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>

                        <hr></hr>
                        <StackMU spacing={'auto'} direction='row' justifyContent={'end'} sx={{ mt: 2 }}>
                            <Button onClick={() => { setmostrarModalAgregarCodigoGasto(false) }} variant="outlined">Cerrar</Button>
                            <Button onClick={() => { AgregarNuevoCodigoGasto() }} variant="outlined">Guardar</Button>
                        </StackMU>
                    </Container>
                </Modal.Body>
            </Modal>

            {/* Modal Reporte Rango Fecha */}
            <Modal
                show={mostrarModalReporteRangoFecha}
                onHide={() => { setmostrarModalReporteRangoFecha(false) }}
                keyboard={false}
                centered >
                <Modal.Header>
                    <Modal.Title>{`Elegir Fechas`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col>
                                <small className="text-primary">Fecha Inicio</small>
                                <Form.Control ref={fechaInicioReporteRef} size='sm' type="date"></Form.Control>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <small className="text-primary">Fecha Fin</small>
                                <Form.Control ref={fechaFinReporteRef} size='sm' type="date"></Form.Control>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => { ReporteRangoFechas() }} variant="outlined">Descargar</Button>
                </Modal.Footer>

            </Modal>

        </Fragment>
    );
}