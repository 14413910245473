import React, { Fragment, useEffect, useRef, useState, } from 'react'
import { useNavigate } from 'react-router-dom';

import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
// Material UI
import { IconButton, Button, Snackbar, Alert, AlertTitle } from '@mui/material';


import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import Stack from '@mui/material/Stack';

import Header from './header';
import { ValidarUsuario, ObtenerUsuario, config, listarPermisos, ValidarPermisos, LIST_SCREENS } from './../utils/utils'
import PaginaCarga from '../utils/cargapagina'

function Proyecto() {
    const navigate = useNavigate();
    const [listaproyectosactivos, setlistaproyectosactivos] = useState([]);

    const [mostrarModal, setmostrarModal] = useState(0);

    const [abrirToast, setabrirToast] = useState(false);
    const [titulotoast, settitulotoast] = useState("");
    const [mensajetoast, setmensajetoast] = useState("");
    const [tipotoast, settipotoast] = useState("error");

    const [loadcarga, setloadcarga] = useState(false);

    // Ciclo de vida
    useEffect(() => {
        // BuscarListasproyectos();
        ValidarAccesos();
    }, []);

    const ValidarAccesos = () => {
        if (ValidarUsuario()) {
            listarPermisos().then((permisos) => {                
                if (ValidarPermisos(LIST_SCREENS.ACCESS_SCREEN_HOME, permisos)) {
                    BuscarListasproyectos();
                } else {                    
                    settitulotoast("Alerta");
                    settipotoast("warning");
                    setmensajetoast("No tiene permisos para acceder a este Recurso.");
                    setabrirToast(true);
                    setTimeout(() => {
                        navigate('/login');
                    }, 1000);
                }
            });
        } else {
            navigate('/login');
        }

    }

    const BuscarListasproyectos = () => {
        if (ValidarUsuario()) {
            let UsuarioStorage = ObtenerUsuario();
            UsuarioStorage = JSON.parse(UsuarioStorage);

            let url = `${config.endPoint}api/permisoproyecto/buscarproyectos/${UsuarioStorage.id}`;

            let headers = {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + UsuarioStorage.token,
                },
            };

            fetch(url, headers).then((respuesta) => {
                return respuesta.json();
            }).then(async (data) => {
                if (data.content) {
                    setlistaproyectosactivos(data.content);
                }
            }).catch((error) => {
                settitulotoast("Alerta");
                settipotoast("warning");
                setmensajetoast(config.MessageErrorServer);
                setabrirToast(true);
            });
        } else {
            navigate("/login");
        }
    }
    return (
        <Fragment>
            <PaginaCarga loadcarga={loadcarga} />

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={abrirToast} autoHideDuration={4000} onClose={() => { setabrirToast(false) }}>
                <Alert onClose={() => { setabrirToast(false) }} severity={tipotoast}>
                    <AlertTitle>{titulotoast}</AlertTitle>
                    {mensajetoast}
                </Alert>
            </Snackbar>

            <Header></Header>
            <Container >
                <Row className='justify-content-center mt-5'>
                    <Col md="10" lg="8" className=''>
                        {/* Material UI componentes */}
                        <h1>PROYECTOS</h1>
                        <Card >
                            <ListGroup variant="flush">
                                {
                                    listaproyectosactivos.map((row, index) => {
                                        return (
                                            <ListGroup.Item key={index}>
                                                <IconButton disabled >
                                                    <EditIcon />
                                                </IconButton>
                                                <Button variant="text" onClick={() => { navigate(`/dashboard/${row.ta_proyecto.id}`) }}>{row.ta_proyecto.nombreproyecto}</Button>
                                            </ListGroup.Item>
                                        )
                                    })
                                }
                                <ListGroup.Item >
                                    <Stack justifyContent="end" direction='row'>
                                        <IconButton onClick={() => { navigate('/configurar/proyectos') }}>
                                            <AddIcon />
                                        </IconButton>
                                    </Stack>
                                </ListGroup.Item>
                            </ListGroup>
                        </Card>
                        <small className='text-secondary'>
                            Elija un proyecto para iniciar.
                        </small>
                    </Col>
                </Row>
            </Container>


            {/* <Modal
                show={mostrarModal}
                onHide={CerrarModal}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        PROYECTO
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Stack spacing={2}>
                        <TextField fullWidth variant="outlined" label="Nombre Proyecto" />
                        <TextField multiline rows={2} fullWidth variant="outlined" label="Descripción el Proyecto" />
                        <TextField fullWidth variant="outlined" label="Encargado / Responsable" />

                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Estado</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                label="Estado"
                                onChange={() => { }}
                            >
                                <MenuItem value={10}>ACTIVO</MenuItem>
                                <MenuItem value={20}>INACTIVO</MenuItem>
                            </Select>
                        </FormControl>
                    </Stack>

                </Modal.Body>
                <Modal.Footer className='text-end'>
                    <Stack spacing={1} direction="row" >
                        <Button variant='outlined' onClick={CerrarModal}>Modificar</Button>
                        <Button variant='outlined' onClick={CerrarModal}>Cancelar</Button>
                        <Button variant='outlined' onClick={CerrarModal}>Guardar</Button>
                    </Stack>
                </Modal.Footer>
            </Modal> */}

        </Fragment>
    );
}
export default Proyecto;